import Cron from '@serverfarm/react-cron-generator';
import { DataSourceParameterType } from '@serverfarm/rs-commons';
import { Maybe } from 'monet';
import 'prismjs/components/prism-clike';
// @ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-javascript';
import React from 'react';
// @ts-ignore
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// @ts-ignore
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import SimpleCodeEditor from 'react-simple-code-editor';
import {
  Button,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';
// import 'prismjs/themes/prism.css';

import { UserRole } from '../../auth/entities';
import { visibleOnlyTo } from '../../auth/helpers';
import { State } from '../../reducers';
import { fetchCompanies, makeServiceTimezoneKeyName, ServicesAvailable } from '../user';
import { DateTimeRender, SIMPLE_CODE_EDITOR_STYLE_DEFAULT } from '../utils';

import {
  completeReportEdit,
  editReportCompany,
  editReportDataSource,
  editReportName,
  editReportParameterAdd,
  editReportParameterDelete,
  editReportParameterFunc,
  editReportParameterValue,
  editReportScheduleSettings,
  editReportTemplate,
  editReportTransformation,
  fetchReportDataSources,
  fetchReportTemplates,
  renderReport,
  saveReport,
  startReportEdit,
  toggleReportScheduleSettings,
  toggleReportTransformationCollapse,
} from './data/actions';
import './ReportEditor.css';
import { ReportDeliverViaEmail, ReportDeliverType } from './entities';
import ReportParametersEditor from './ReportParametersEditor';

const ReportParametersList = (props: Props) => (
  <Table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Function</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      {props.reportEditing.report
        .flatMap((r) => r.parameters)
        .map((parameters) =>
          parameters.map((p) => (
            <tr data-testid={`report-parameter-item-${p.name}`} className={`report-parameter-item`} key={p.name}>
              <th>{p.name}</th>
              <td>{p.initFn.map((initFn) => `${initFn.fn}(params = ${JSON.stringify(initFn.parameters)})`).orUndefined()}</td>
              <td>
                {p.initValue
                  .map((initValue) =>
                    initValue.type === DataSourceParameterType.DATE_TIME ? (
                      <span key={initValue.type}>
                        {initValue.type}:{' '}
                        <DateTimeRender date={initValue.value.toString()} timeZoneRef={initValue.extra || props.user.profile.timeZoneRef} /> (
                        {initValue.extra})
                      </span>
                    ) : (
                      `${initValue.type}: ${initValue.value}`
                    ),
                  )
                  .orUndefined()}
              </td>
            </tr>
          )),
        )
        .orUndefined()}
    </tbody>
  </Table>
);

const ReportParametersListFormGroup = (props: Props) => (
  <FormGroup>
    <Label for="query">Parameters</Label>
    <div>
      {props.reportEditing.report.flatMap((r) => r.parameters.orNoneIf(r.parameters.exists((p) => !p.length))).isNone() ? (
        <p>No parameters available</p>
      ) : (
        <div>
          <ReportParametersList data-testid="report-editor-parameters-inits-table" {...props} />
        </div>
      )}
      <ReportParametersEditor />
    </div>
  </FormGroup>
);

const ProtectedReportParametersListFormGroup = visibleOnlyTo(UserRole.ADMIN, ReportParametersListFormGroup);

class Component extends React.Component<Props> {
  serverTimezone = 'Etc/UTC';
  componentDidMount() {
    const serverTimezoneStored = localStorage.getItem(makeServiceTimezoneKeyName(ServicesAvailable.POINTER_COD));
    if (serverTimezoneStored) {
      this.serverTimezone = serverTimezoneStored;
    }
  }
  public render() {
    return (
      <Modal
        id="report-editor-modal"
        data-testid="report-editor-modal"
        size="xl"
        isOpen={this.props.reportEditing.isInProgress}
        toggle={this.cancel.bind(this)}
      >
        <ModalHeader>
          <EllipsisText
            data-testid="report-editor-header"
            id="report-editor-header"
            text={`Edit '${this.props.reportEditing.report.map((r) => r.name || '...').getOrElse('...')}'`}
            length={45}
          />
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={this.submit.bind(this)}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                data-testid="report-editor-name-input"
                id="name"
                type="text"
                // valid={this.props.reportEditing.report.map(r => !!r.name).getOrElse(false)}
                invalid={this.props.reportEditing.report.map((r) => !r.name).getOrElse(true)}
                value={this.props.reportEditing.report.map((t) => t.name).getOrElse('')}
                onChange={(e) => this.props.editReportName(e.target.value)}
              />
              <FormText>Type name of the Report for future reference</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="report-editor-company">Company</Label>
              <Typeahead
                data-testid="report-editor-company"
                id="report-editor-company"
                isLoading={this.props.companiesListing.isInProgress}
                onChange={(selected) => this.props.editReportCompany(Maybe.fromUndefined(selected[0]))}
                options={this.props.companiesListing.companies.getOrElse([])}
                emptyLabel="No Companies found"
                placeholder="Start typing to find a Company by name"
                defaultSelected={this.props.reportEditing.report
                  .flatMap((report) => report.company)
                  .map((t) => [t])
                  .getOrElse([])}
                labelKey="name"
                // isValid={this.props.reportEditing.report.flatMap(report => report.company).isSome()}
                isInvalid={this.props.reportEditing.report.flatMap((report) => report.company).isNone()}
                disabled={this.props.reportEditing.report.map((report) => !!report.id).contains(true)}
              />
              <FormText>
                Choose a Company to bind the Report to
                <span hidden={this.props.reportEditing.report.map((report) => !!report.id).contains(true)}>
                  or{' '}
                  <a href="#" onClick={this.setMyCompany.bind(this)}>
                    use your company
                  </a>
                </span>
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="report-editor-data-source">Data Source</Label>
              <AsyncTypeahead
                data-testid="report-editor-data-source"
                id="report-editor-data-source"
                isLoading={this.props.reportDataSourcesListing.isInProgress}
                onSearch={() =>
                  this.props.fetchReportDataSources({
                    companyId: this.props.reportEditing.report.flatMap((r) => r.company.map((c) => c.id)).orUndefined(),
                  })
                }
                onChange={(selected) => this.props.editReportDataSource(Maybe.fromUndefined(selected[0]))}
                options={this.props.reportDataSourcesListing.dataSources.getOrElse([])}
                emptyLabel="No Data Sources found"
                searchText="Looking for Data Source..."
                placeholder="Start typing to find a Data Source by name"
                selected={this.props.reportEditing.report
                  .flatMap((report) => report.dataSource)
                  .map((ds) => [ds])
                  .getOrElse([])}
                labelKey="name"
                // isValid={this.props.reportEditing.report.flatMap(report => report.dataSource).isSome()}
                isInvalid={this.props.reportEditing.report.flatMap((report) => report.dataSource).isNone()}
                disabled={!this.props.user.profile.role.contains(UserRole.ADMIN)}
                // labelKey={option => `${option.login}`}
              />
              <FormFeedback invalid="true">You will not be able to see this</FormFeedback>
              <FormText>Use the field to lookup a Data Source for the Report</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="report-editor-template">Template</Label>
              <AsyncTypeahead
                data-testid="report-editor-template"
                id="report-editor-template"
                isLoading={this.props.reportTemplatesListing.isInProgress}
                onSearch={() =>
                  this.props.fetchReportTemplates({
                    companyId: this.props.reportEditing.report.flatMap((r) => r.company.map((c) => c.id)).orUndefined(),
                  })
                }
                onChange={(selected) => this.props.editReportTemplate(Maybe.fromUndefined(selected[0]))}
                options={this.props.reportTemplatesListing.templates.getOrElse([])}
                emptyLabel="No Templates found"
                searchText="Looking for Template..."
                placeholder="Start typing to find a Template by name"
                selected={this.props.reportEditing.report
                  .flatMap((report) => report.template)
                  .map((t) => [t])
                  .getOrElse([])}
                labelKey="name"
                // isValid={this.props.reportEditing.report.flatMap(report => report.template).isSome()}
                isInvalid={this.props.reportEditing.report.flatMap((report) => report.template).isNone()}
                disabled={!this.props.user.profile.role.contains(UserRole.ADMIN)}
              />
              <FormFeedback invalid="true">You will not be able to see this</FormFeedback>
              <FormText>Use the field to lookup a Template for the Report</FormText>
            </FormGroup>
            <FormGroup hidden={!this.props.user.profile.role.contains(UserRole.ADMIN)}>
              <Label for="report-editor-transformation" className="w-100">
                Transformation{' '}
              </Label>
              <SimpleCodeEditor
                data-testid="report-editor-transformation"
                id="report-editor-transformation"
                value={this.props.reportEditing.report.flatMap((t) => t.transformation).getOrElse('')}
                onValueChange={(code) => this.props.editReportTransformation(Maybe.Some(code))}
                highlight={(code) => highlight(code, languages.javascript)}
                padding={10}
                style={{
                  ...SIMPLE_CODE_EDITOR_STYLE_DEFAULT,
                }}
              />
              <FormText>The template content will be later used to render a data to it</FormText>
            </FormGroup>
            <ProtectedReportParametersListFormGroup {...this.props} />
            <FormGroup check inline>
              <CustomInput
                type="checkbox"
                id="report-editor-schedule-settings-enabled"
                checked={this.props.reportEditing.isEnableScheduling}
                onChange={() => this.props.toggleReportScheduleSettings()}
                label="Enable report render scheduling"
              />
            </FormGroup>
            <FormGroup hidden={!this.props.reportEditing.isEnableScheduling}>
              <Label for="scheduler-cron">Scheduler settings</Label>
              <Cron
                serverTimezone={this.serverTimezone}
                timezone={this.props.reportEditing.report
                  .flatMap((r) => r.scheduleSettings)
                  .map((settings) => {
                    return settings.schedule.recurring.timezone || '';
                  })
                  .orUndefined()}
                onChange={(event: any) => {
                  if (this.props.reportEditing.isEnableScheduling) {
                    this.props.editReportScheduleSettings(
                      this.props.reportEditing.report
                        .flatMap((r) => r.scheduleSettings)
                        .orElse(Maybe.Some({ schedule: { recurring: { cron: '' } }, deliver: [] }))
                        .map((scheduleSettings) => ({
                          ...scheduleSettings,
                          schedule: {
                            recurring: {
                              cron: event.serverCronString, // '*/10 * * * *', // value,
                              timezone: event.timezone,
                            },
                          },
                        })),
                    );
                  }
                }}
                value={this.props.reportEditing.report
                  .flatMap((r) => r.scheduleSettings)
                  .map((s) => s.schedule.recurring.cron)
                  .orUndefined()}
                // showResultText={true}
                // showResultCron={true}
              />
            </FormGroup>
            <FormGroup hidden={!this.props.reportEditing.isEnableScheduling}>
              <Label for="delivery-settings">Delivery email</Label>
              <Input
                data-testid="report-editor-email-input"
                id="delivery-name"
                type="text"
                // valid={this.props.reportEditing.report.flatMap(r => r.scheduleSettings).map(s => (s.deliver[0] as ReportDeliverViaEmail).email).getOrElse('')}
                // invalid={this.props.reportEditing.report.map(r => !r.name).getOrElse(true)}
                value={this.props.reportEditing.report
                  .flatMap((r) => r.scheduleSettings)
                  .flatMap((s) => Maybe.fromUndefined(s.deliver[0] as ReportDeliverViaEmail))
                  .map((d) => d.email)
                  .getOrElse('')}
                onChange={(e) => {
                  this.props.editReportScheduleSettings(
                    this.props.reportEditing.report
                      .flatMap((r) => r.scheduleSettings)
                      .orElse(Maybe.Some({ schedule: { recurring: { cron: '' } }, deliver: [] }))
                      .map((scheduleSettings) => ({
                        ...scheduleSettings,
                        deliver: [
                          {
                            deliverType: ReportDeliverType.EMAIL,
                            email: e.target.value,
                          },
                        ],
                      })),
                  );
                }}
              />
              <FormFeedback invalid="true">Can not be empty</FormFeedback>
              <FormText>Type name of the Report for future reference</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="updatedDate">Updated Date</Label>
              <div id="updatedDate">
                <DateTimeRender
                  date={this.props.reportEditing.report.flatMap((t) => t.updated).orUndefined()}
                  timeZoneRef={this.props.user.profile.timeZoneRef}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="createdDate">Created Date</Label>
              <div id="createdDate">
                <DateTimeRender
                  date={this.props.reportEditing.report.map((t) => t.created).orUndefined()}
                  timeZoneRef={this.props.user.profile.timeZoneRef}
                />
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            id="report-editor-render-button"
            data-testid="report-editor-render-button"
            color="danger"
            // disabled={!this.props.reportEditing.report || this.props.reportSaving.isSaving}
            onClick={this.renderReport.bind(this)}
          >
            Render
          </Button>
          <Button
            id="report-editor-save-button"
            data-testid="report-editor-save-button"
            color="primary"
            disabled={!this.props.reportEditing.report || this.props.reportSaving.isInProgress}
            onClick={this.submit.bind(this)}
          >
            {this.props.reportSaving.isInProgress ? <Spinner className="saving" size="sm" /> : 'Save'}
          </Button>
          <Button
            id="report-editor-cancel-button"
            data-testid="report-editor-cancel-button"
            color="secondary"
            disabled={this.props.reportSaving.isInProgress}
            onClick={this.cancel.bind(this)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected submit(e: any) {
    e.preventDefault();
    setTimeout(() => {
      this.props.reportEditing.report.forEach((report) => this.props.saveReport(report, !report.id));
    }, 250);
  }

  protected cancel(e: any) {
    e.preventDefault();
    this.props.completeReportEdit();
  }

  protected renderReport(e: any) {
    e.preventDefault();
    this.props.reportEditing.report.forEach((report) => this.props.renderReport(report));
  }

  protected setMyCompany(e: any) {
    e.preventDefault();
    const companyId = this.props.user.profile.companyId;
    const company = this.props.companiesListing.companies.flatMap((c) => Maybe.fromUndefined(c.find((c) => c.id === companyId)));
    this.props.editReportCompany(company);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      startReportEdit,
      editReportName,
      editReportCompany,
      editReportDataSource,
      editReportTemplate,
      editReportTransformation,
      editReportParameterAdd,
      editReportParameterDelete,
      toggleReportTransformationCollapse,
      toggleReportScheduleSettings,
      editReportScheduleSettings,
      completeReportEdit,
      saveReport,
      fetchReportDataSources,
      fetchReportTemplates,
      fetchCompanies,
      editReportParameterFunc,
      editReportParameterValue,
      renderReport,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  reportEditing: state.reportEditing,
  reportSaving: state.reportSaving,
  reportDataSourcesListing: state.reportDataSourcesListing,
  reportTemplatesListing: state.reportTemplatesListing,
  companiesListing: state.companiesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
