import logdown from 'logdown';
import React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { Button, Spinner } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { UserRole } from '../../auth/entities';
import { State } from '../../reducers';

import {
  deferredRenderReport,
  deleteReport,
  fetchReports,
  renderReport,
  startReportCloneEdit,
  startReportEdit,
  updateReportListingFilter,
  updateReportListingSort,
  openRenderedReportsHistory,
  openRenderReportViewer,
  resetReportsState,
} from './data/actions';
import RenderedReportHistory from './RenderedReportHistory';
import RenderedReportViewer from './RenderedReportViewer';
import ReportEditor from './ReportEditor';
import ReportInteractiveRenderer from './ReportInteractiveRenderer';
import ReportsList from './ReportsList';

const logger = logdown('component:Reports');

class Component extends React.Component<Props, {}> {
  refresh() {
    if (this.props.reportsListing.filter.exists((filter) => filter.id !== undefined)) {
      /**
       * If id was set in filter then reset it which will trigger list refresh
       */
      this.props.updateReportListingFilter(
        this.props.reportsListing.filter.map((filter) => ({ ...filter, id: undefined })),
        this.props.reportsListing.page,
        this.props.reportsListing.sizePerPage,
      );
    } else {
      /**
       * Otherwise force refresh filter
       */
      this.props.fetchReports(
        this.props.reportsListing.filter,
        this.props.reportsListing.page,
        this.props.reportsListing.sizePerPage,
        this.props.reportsListing.sort,
      );
    }
  }

  componentDidUpdate(prevProps: Props) {
    const isEditorClosed = prevProps.reportEditing.report.isSome() && this.props.reportEditing.report.isNone();
    const isDeletionCompleted = prevProps.reportDeleting.isInProgress && !this.props.reportDeleting.isInProgress;

    if (isEditorClosed || isDeletionCompleted) {
      this.props.fetchReports(
        this.props.reportsListing.filter,
        this.props.reportsListing.page,
        this.props.reportsListing.sizePerPage,
        this.props.reportsListing.sort,
      );
    }
  }

  componentWillUnmount() {
    this.props.resetReportsState();
  }

  public render() {
    return (
      <div className="content">
        <Button
          hidden={!this.props.user.profile.role.contains(UserRole.ADMIN)}
          data-testid="reports-list-create-button"
          id="reports-list-create-button"
          color="primary"
          size="sm"
          disabled={this.props.reportsListing.isInProgress}
          onClick={() => this.props.startReportEdit()}
        >
          Create
        </Button>
        <Button
          data-testid="reports-list-reload-button"
          id="reports-list-reload-button"
          color="secondary"
          size="sm"
          disabled={this.props.reportsListing.isInProgress}
          onClick={this.refresh.bind(this)}
        >
          Refresh
        </Button>
        <ReportsList location={this.props.location} history={this.props.history} />
        <ReportEditor />
        <ReportInteractiveRenderer />
        <RenderedReportHistory location={this.props.location} history={this.props.history} />
        <RenderedReportViewer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchReports,
      startReportCloneEdit,
      startReportEdit,
      renderReport,
      deferredRenderReport,
      deleteReport,
      updateReportListingFilter,
      updateReportListingSort,
      openRenderedReportsHistory,
      openRenderReportViewer,
      resetReportsState,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  reportsListing: state.reportsListing,
  reportEditing: state.reportEditing,
  reportDeleting: state.reportDeleting,
  reportRendering: state.reportRendering,
  companiesListing: state.companiesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

export default connect<typeof stateProps, typeof dispatchProps, any>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
