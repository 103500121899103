import logdown, { Logger } from 'logdown';
import { Maybe } from 'monet';
import { Transform, createTransform } from 'redux-persist';

import { RenderedReportsHistoryState } from '../entities';

import {
  reportSerialize,
  renderedReportsHistoryFilterSerialize,
  reportDeserialize,
  renderedReportDeserialize,
  renderedReportSerialize,
  renderedReportsHistoryFilterDeserialize,
} from './serdes';

export const renderedReportsHistoryTransform = (
  logger: Logger = logdown('redux-persist:transform:renderedReportsHistoryTransform'),
): Transform<RenderedReportsHistoryState, any> =>
  createTransform(
    (subState, key, state) => {
      const result = {
        ...subState,
        report: subState.report.map(reportSerialize).orUndefined(),
        renderedReports: subState.renderedReports.map((rrs) => rrs.map(renderedReportSerialize)).orUndefined(),
        filter: subState.filter.map((filter) => renderedReportsHistoryFilterSerialize(filter)).orUndefined(),
        sort: subState.sort.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    (state, key, rawState) => {
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        report: Maybe.fromUndefined(state.report).map(reportDeserialize),
        isInProgress: false,
        renderedReports: Maybe.fromUndefined(state.renderedReports).map((renderedReports) => renderedReports.map(renderedReportDeserialize)),
        filter: Maybe.fromUndefined(state.filter).map((filter) => renderedReportsHistoryFilterDeserialize(filter)),
        sort: Maybe.fromUndefined(state.sort),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
    },
    { whitelist: ['renderedReportsHistory'] },
  );
