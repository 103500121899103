import jwtDecode from 'jwt-decode';
import { IProvider } from 'react-simple-auth';

import config from '../../config';

import { Session, Token } from './session';

export const incommand: IProvider<Session> = {
  buildAuthorizeUrl() {
    return `${config.oauth.client.url}/authorize/incommand`;
  },

  extractError(redirectUrl: string): Error | undefined {
    const errorMatch = decodeURIComponent(redirectUrl).match(/((error|code)=([^&]+))/gi);

    if (!errorMatch) {
      return;
    }

    const parsedParams: { code?: string; error?: string } = errorMatch.reduce((memo, kv) => {
      const [k, v] = kv.split('=');
      return { ...memo, [k]: v };
    }, {});

    return new Error(`Login error. ${parsedParams.error}.`);
  },

  extractSession(redirectUrl: string): Session {
    let accessToken = '';
    const accessTokenMatch = /accessToken=([^&]+)/.exec(redirectUrl);
    if (accessTokenMatch) {
      accessToken = accessTokenMatch[1];
    }

    const decodedToken: Token = jwtDecode(accessToken);

    const expireAt = decodedToken.exp;
    console.log('EXTRACT_SESSION=>', redirectUrl);
    return {
      accessToken,
      expireAt,
      decodedToken,
    };
  },

  validateSession(session: Session): boolean {
    const now = new Date().getTime() / 1000;

    // With normal JWT tokens you can inspect the `exp` Expiration claim; however,
    // AAD V2 tokens are opaque and we must use the token meta about expiration time
    // "When you request an access token from the v2.0 endpoint, the v2.0 endpoint also returns metadata about the access token for your app to use."
    // See: https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-v2-tokens
    // Here we are leveraging the fact that the access token was issued at the same
    // time as the ID token and can use its `iat` Issued At claim + the duration
    // to compute an absolute expiration time

    // 15 minutes minimum duration until token expires
    const minimumDuration = 60 * 15;
    return session.expireAt - now > minimumDuration;
  },

  getAccessToken(session: Session /* , resourceId: string */): string {
    return session.accessToken;
  },

  getSignOutUrl(redirectUrl: string): string {
    return `${config.oauth.client.url}/authorize/incommand/logout?redirect_uri=${encodeURIComponent(redirectUrl)}`;
  },
};
