import { Maybe, Some, None } from 'monet';

import { Action } from '../../../redux/actions';
import { RenderedReport, Report } from '../../entities';

export enum RenderedReportViewerActionType {
  OPEN_RENDERED_REPORT_VIEWER = 'OPEN_RENDERED_REPORT_VIEWER',
  CLOSE_RENDERED_REPORT_VIEWER = 'CLOSE_RENDERED_REPORT_VIEWER',
}

/**
 * Actions
 */

interface RenderReportViewerToggleAction extends Action<RenderedReportViewerActionType> {
  renderedReport: Maybe<RenderedReport>;
  report: Maybe<Report>;
}

export type RenderReportViewerAction = RenderReportViewerToggleAction;

/**
 * Action creators
 */

export const openRenderedReportViewer = (renderedReport: RenderedReport, report: Report): RenderReportViewerToggleAction => ({
  type: RenderedReportViewerActionType.OPEN_RENDERED_REPORT_VIEWER,
  renderedReport: Some(renderedReport),
  report: Some(report),
});

export const closeRenderedReportViewer = (): RenderReportViewerToggleAction => ({
  type: RenderedReportViewerActionType.CLOSE_RENDERED_REPORT_VIEWER,
  renderedReport: None(),
  report: None(),
});
