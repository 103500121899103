import { IRenderedReportsListResponse } from '@serverfarm/pointer-cod-api';
import logdown, { Logger } from 'logdown';
import { Maybe } from 'monet';
import { Dispatch } from 'redux';

import config from '../../../../config';
import { State } from '../../../../reducers';
import axios from '../../../../services/axios';
import { showInfoNotification } from '../../../notification';
import { Report, RenderedReportsHistoryFilter, RenderedReportsHistorySort } from '../../entities';
import {
  renderedReportsHistoryOpen,
  renderedReportsHistoryClose,
  renderedReportsHistoryRequest,
  renderedReportsHistoryReceive,
  renderedReportsHistoryReceiveError,
  renderedReportsHistoryFilterUpdate,
  renderedReportsHistorySortUpdate,
} from '../../redux/actions';
import { renderedReportsHistoryFilterSerialize, renderedReportDeserialize } from '../serdes';

export const openRenderedReportsHistory = (report: Report) => (dispatch: Dispatch<any>) => {
  dispatch(renderedReportsHistoryOpen(report));
};

export const closeRenderedReportsHistory = () => (dispatch: Dispatch<any>) => {
  dispatch(renderedReportsHistoryClose());
};

export const updateRenderedReportsHistoryFilter = (
  filter: Maybe<RenderedReportsHistoryFilter>,
  page: number,
  sizePerPage: number,
  logger: Logger = logdown('reports:data:actions:updateRenderedReportsHistoryFilter'),
) => (dispatch: Dispatch<any>) => {
  logger.log(`filter => ${JSON.stringify(filter.orUndefined())}; page => ${page}; sizePerPage => ${sizePerPage}`);
  dispatch(renderedReportsHistoryFilterUpdate(filter, page, sizePerPage));
};
export const updateRenderedReportsHistoryPages = (
  page: number,
  sizePerPage: number,
  logger: Logger = logdown('reports:data:actions:updateRenderedReportsHistoryPages'),
) => (dispatch: Dispatch<any>, getState: () => State) => {
  const filter = getState().renderedReportsHistory.filter;
  dispatch(renderedReportsHistoryFilterUpdate(filter, page, sizePerPage));
};
export const updateRenderedReportsHistorySort = (
  listingSort: Maybe<RenderedReportsHistorySort>,
  logger: Logger = logdown('reports:data:actions:updateRenderedReportsHistorySort'),
) => (dispatch: Dispatch<any>) => {
  logger.log(`listingSort => ${JSON.stringify(listingSort.orUndefined())}`);
  dispatch(renderedReportsHistorySortUpdate(listingSort));
};

export const fetchRenderedReportsHistory = (
  report: Report,
  filter: Maybe<RenderedReportsHistoryFilter>,
  sort: Maybe<RenderedReportsHistorySort>,
  page: number,
  sizePerPage: number,
  logger: Logger = logdown('reports:data:actions:fetchRenderedReportsHistory'),
) => (dispatch: Dispatch<any>) => {
  dispatch(renderedReportsHistoryRequest(filter, sort, sizePerPage));

  const serializedFilter = renderedReportsHistoryFilterSerialize(filter);

  logger.log(`serializedFilter => ${serializedFilter}`);

  axios({
    method: 'get',
    url: `${config.services.dacqs.api.url}/report/${report.id}/rendered/list`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {
      key: serializedFilter.key,
      from: serializedFilter.from ? serializedFilter.from.toISOString() : new Date(0).toISOString(),
      to: serializedFilter.to ? serializedFilter.to.toISOString() : undefined,
      page: page + 1,
      limit: sizePerPage,
      sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
    },
  })
    .then((response) => response.data as IRenderedReportsListResponse)
    .then((data) => {
      dispatch(renderedReportsHistoryReceive(data.renderedReports.map(renderedReportDeserialize), page, data.total));
    })
    .catch((err) => {
      dispatch(renderedReportsHistoryReceiveError(err));
    });
};

export const copyToClipboard = (imgURL: string, reportName: string, logger: Logger = logdown('reports:data:actions:copyToClipboard')) => async (
  dispatch: Dispatch<any>,
) => {
  logger.log('Start copying');
  try {
    // @ts-ignore
    const permissionStatus = await navigator.permissions.query({ name: 'clipboard-write' });
    logger.log('clipboard-write permission state is ', permissionStatus.state);
    if (permissionStatus.state === 'granted') {
      const data = await fetch(imgURL);
      const blob = await data.blob();
      // @ts-ignore
      await navigator.clipboard.write([
        // @ts-ignore
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);
      dispatch(showInfoNotification(`Report "${reportName}" has been copied to clipboard`, 'Clipboard', 5000));
    }
  } catch (err) {
    console.error(err.name, err.message);
  }
};
