import { DataSourceParameterType } from '@serverfarm/rs-commons';
import { Maybe } from 'monet';

import { Company } from '../user/redux/entities';
import { DateRange } from '../utils/dateRangeFilter';

export enum DataSourceParameterOption {
  NOT_IN_FUTURE = 'NotInFuture',
}

export interface DataSourceParameter {
  name: string;
  type: DataSourceParameterType;
  isOptional: boolean;
  default: Maybe<string | number>;
  options: Maybe<DataSourceParameterOption[]>;
}

export interface DataSource {
  id: string;
  name: string;
  company: Maybe<Company>;
  query: string;
  parameters: Maybe<DataSourceParameter[]>;
  updated: Maybe<Date>;
  created: Date;
}

export interface DataSourcesListFilter {
  name?: string;
  companies?: string[];
  created: Maybe<DateRange>;
  updated: Maybe<DateRange>;
}

export interface DataSourcesListSort {
  field: string;
  order: 'asc' | 'desc';
}

/**
 * Entities SerDes
 */

// export const dataSourceParameterTypeSerialize = (v: DataSourceParameterType): string => v.toString();

// export const dataSourceParameterTypeDeserialize = (v: string): DataSourceParameterType => {
//   switch (v) {
//     case 'String':
//       return DataSourceParameterType.STRING;
//     case 'Int':
//       return DataSourceParameterType.INT;
//     case 'Float':
//       return DataSourceParameterType.FLOAT;
//     case 'DateTime':
//       return DataSourceParameterType.DATE_TIME;
//     case '[String]':
//       return DataSourceParameterType.ARRAY_OF_STRINGS;
//     case '[Int]':
//       return DataSourceParameterType.ARRAY_OF_INTS;
//     case '[Float]':
//       return DataSourceParameterType.ARRAY_OF_FLOATS;
//     default:
//       throw new Error(`Bad Data Source parameter type. Must be one of ${Object.keys(DataSourceParameterType)}`);
//   }
// };

export const dataSourceParameterOptionSerialize = (v: DataSourceParameterOption): string => v.toString();

export const dataSourceParameterOptionDeserialize = (v: string): DataSourceParameterOption => {
  switch (v) {
    case 'NotInFuture':
      return DataSourceParameterOption.NOT_IN_FUTURE;
    default:
      throw new Error(`Bad Data Source parameter option. Must be ${typeof DataSourceParameterOption}`);
  }
};
