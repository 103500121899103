import { IRenderedReportResponse, IReportRequest, IReportResponse } from '@serverfarm/pointer-cod-api';
import { IScheduleSettings } from '@serverfarm/rs-commons';
import logdown from 'logdown';
import moment from 'moment';
import { Maybe } from 'monet';

import { DataSource } from '../../datasources/entities';
import { Template } from '../../templates/entities';
import { Company } from '../../user';
import {
  Report,
  ReportDeliverType,
  ReportDeliverViaEmail,
  reportParameterFuncDeserialize,
  reportParameterValueDeserialize,
  RenderedReport,
  RenderedReportsHistoryFilter,
  ReportsListFilter,
} from '../entities';

import { RenderedReportsHistoryFilterApiEntity, ReportsListFilterApiEntity } from './entities';

const logger = logdown('reports:data:serdes');

export const reportSerialize = (report: Report): IReportRequest => ({
  // id: report.id,
  name: report.name,
  dataSourceId: report.dataSource.map((ds) => ds.id).getOrElse(''),
  templateId: report.template.map((t) => t.id).getOrElse(''),
  // companyId: report.company.map((c) => +c.id).getOrElse(-1),
  transformation: report.transformation.getOrElse(''),
  parametersInit: report.parameters
    .map((parameters: any) =>
      parameters.map((p: any) => ({
        name: p.name,
        initFn: p.initFn.orUndefined(),
        initValue: p.initValue.orUndefined(),
      })),
    )
    .orUndefined(),
  scheduleSettings: report.scheduleSettings
    .map(
      (scheduleSettings) =>
        ({
          schedule: {
            recurring: {
              cron: scheduleSettings.schedule.recurring.cron,
              timezone: scheduleSettings.schedule.recurring.timezone,
            },
          },
          deliver: scheduleSettings.deliver as any,
        } as IScheduleSettings),
    )
    .orUndefined(),
  // updated: report.updated.map((d) => d.toISOString()).orUndefined(),
  // created: report.created.toISOString(),
});

export const reportDeserialize = (raw: IReportResponse, companies: Maybe<Company[]>, dataSources: DataSource[], templates: Template[]): Report => {
  const company = companies.flatMap((companies) => Maybe.fromUndefined(companies.find((c) => c.id === raw.companyId)));
  if (company.isNone()) {
    throw new Error(`Report's company id (${raw.companyId}) is not available to current user`);
  }
  const dataSource = Maybe.fromUndefined(dataSources.find((c) => c.id === raw.dataSourceId));
  if (dataSource.isNone()) {
    throw new Error(`Report's dataSource id (${raw.dataSourceId}) is not available to current user`);
  }
  const template = Maybe.fromUndefined(templates.find((c) => c.id === raw.templateId));
  if (template.isNone()) {
    throw new Error(`Report's template id (${raw.templateId}) is not available to current user`);
  }
  logger.log(`reportDeserialize raw => ${JSON.stringify(raw)}`);
  return {
    id: raw.id,
    name: raw.name,
    dataSource,
    template,
    company,
    transformation: Maybe.fromUndefined(raw.transformation ? raw.transformation : undefined),
    parameters: Maybe.fromUndefined(raw.parametersInit).map((parameterInits) =>
      parameterInits.map((pInit: any) => ({
        name: pInit.name,
        initFn: Maybe.fromUndefined(pInit.initFn).map(reportParameterFuncDeserialize({ dataSources })),
        initValue: Maybe.fromUndefined(pInit.initValue).map(reportParameterValueDeserialize),
      })),
    ),
    scheduleSettings: Maybe.fromUndefined(raw.scheduleSettings).map((scheduleSettings) => ({
      schedule: {
        recurring: {
          cron: scheduleSettings.schedule.recurring.cron,
          timezone: scheduleSettings.schedule.recurring.timezone,
        },
      },
      deliver: scheduleSettings.deliver.map((d) => {
        if (d.deliverType === ReportDeliverType.EMAIL.toString()) {
          return {
            deliverType: ReportDeliverType.EMAIL,
            email: (d as { deliverType: ReportDeliverType; email: string }).email,
          } as ReportDeliverViaEmail;
        }
        throw new Error('Only EMAIL delivery type is supported');
      }),
    })),
    lastRendered: Maybe.fromUndefined(raw.lastRendered).map((d) => moment(d).toDate()),
    updated: Maybe.fromUndefined(raw.updated).map((d) => moment(d).toDate()),
    created: moment(raw.created).toDate(),
  };
};

export const reportListFilterSerialize = (filter: ReportsListFilter): ReportsListFilterApiEntity => {
  const reportFilter: ReportsListFilterApiEntity = {
    id: filter.id,
    name: filter.name,
    templateName: filter.template,
    dataSourceName: filter.dataSource,
    companyIds: filter.companies,
  };
  if (filter.created && filter.created.isSome()) {
    reportFilter.from = filter.created.map((created) => created.from.toISOString()).orUndefined();
    reportFilter.to = filter.created.map((created) => created.to.toISOString()).orUndefined();
  }
  if (filter.updated && filter.updated.isSome()) {
    reportFilter.fromUpdated = filter.updated.map((updated) => updated.from.toISOString()).orUndefined();
    reportFilter.toUpdated = filter.updated.map((updated) => updated.to.toISOString()).orUndefined();
  }
  if (filter.lastRendered && filter.lastRendered.isSome()) {
    reportFilter.fromLastRendered = filter.lastRendered.map((lastRendered) => lastRendered.from.toISOString()).orUndefined();
    reportFilter.toLastRendered = filter.lastRendered.map((lastRendered) => lastRendered.to.toISOString()).orUndefined();
  }
  return reportFilter;
};

export const renderedReportDeserialize = (renderedReport: IRenderedReportResponse): RenderedReport => ({
  created: new Date(renderedReport.created),
  permalinks: renderedReport.permalinks,
  id: renderedReport.id,
});

export const renderedReportsHistoryFilterSerialize = (maybeFilter: Maybe<RenderedReportsHistoryFilter>): RenderedReportsHistoryFilterApiEntity => {
  return maybeFilter
    .map((filter) => {
      const serializedFilter: RenderedReportsHistoryFilterApiEntity = {
        key: filter.key,
      };
      if (filter.created) {
        serializedFilter.from = new Date(filter.created.from);
        serializedFilter.to = new Date(filter.created.to);
      }
      return serializedFilter;
    })
    .getOrElse({});
};
