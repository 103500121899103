import logdown from 'logdown';
import { Maybe } from 'monet';
import RSA from 'react-simple-auth';
import { Dispatch } from 'redux';

import { userRoleFromString, UserRole } from '../../../auth/entities';
import { incommand as incommandProvider } from '../../../auth/providers';
import config from '../../../config';
import axios from '../../../services/axios';
import { dataSourcesReset } from '../../datasources/redux/actions';
import { toggleNavBar } from '../../navigation';
import { showErrorNotification } from '../../notification';
import { reportsReset } from '../../reports/redux/actions';
import { templatesReset } from '../../templates/redux/actions';
import {
  receiveCompanies,
  receiveCompaniesError,
  receiveProfile,
  receiveProfileError,
  requestCompanies,
  requestProfile,
  userLoggedIn,
  userLoggedOut,
  userLoginAttempt,
  userLoginAttemptComplete,
  userReset,
} from '../redux/actions';
import { Company, UserProfile, ServicesAvailable } from '../redux/entities';

import { CompanyApiEntity, ProfileApiEntity, ServiceVersionApiEntity } from './entities';

const logger = logdown('user:actions');

/**
 * Login/logout actions
 */
export const loginUser = () => async (dispatch: Dispatch<any>, getState: any) => {
  dispatch(userLoginAttempt());
  await RSA.acquireTokenAsync(incommandProvider)
    .then(() => fetchServiceVersions()(dispatch))
    .then(() => fetchProfile()(dispatch))
    .then(() => fetchCompanies()(dispatch))
    .then(() => {
      dispatch(userLoggedIn());
    })
    .catch((error: Error) => {
      showErrorNotification(error)(dispatch);
    })
    .finally(() => {
      dispatch(userLoginAttemptComplete());
    });
};

export const logoutUser = () => (dispatch: Dispatch<any>) => {
  RSA.invalidateSession();
  resetData(dispatch);
  dispatch(userLoggedOut());
  toggleNavBar(false)(dispatch);
};

/**
 * User profile fetch functionality
 */
export const fetchProfile = () => (dispatch: Dispatch<any>) => {
  dispatch(requestProfile()); // todo : redundant ?
  return axios({
    method: 'get',
    url: `${config.services.auth.api.url}/profile`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(
      (response) =>
        // if (response.status !== 200) {
        //   throw new Error(response.statusText);
        // }
        response.data as ProfileApiEntity,
    )
    .then((profile) => {
      dispatch(receiveProfile(userProfileFromApiEntity(profile)));
    })
    .catch((error) => {
      dispatch(receiveProfileError(error));
      showErrorNotification(error)(dispatch);
    });
};

/**
 * User companies fetch functionality
 */
export const fetchCompanies = () => (dispatch: Dispatch<any>) => {
  dispatch(requestCompanies());
  return axios({
    method: 'get',
    url: `${config.services.auth.api.url}/profile/companies`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.data as CompanyApiEntity[])
    .then((companies) => {
      dispatch(receiveCompanies(companies.map(companyApiEntityToCompany)));
    })
    .catch((error) => {
      dispatch(receiveCompaniesError(error));
      showErrorNotification(error)(dispatch);
    });
};

function resetData(dispatch: Dispatch<any>) {
  dispatch(dataSourcesReset());
  dispatch(reportsReset());
  dispatch(templatesReset());
  dispatch(userReset());
}

/**
 * Conversions
 */
export const userProfileFromApiEntity = (profile: ProfileApiEntity): UserProfile => ({
  userId: profile.user.userId,
  companyId: profile.user.companyId,
  userName: profile.user.userName,
  firstName: profile.user.firstName,
  lastName: profile.user.lastName,
  fullName: profile.user.fullName,
  timeZoneRef: profile.user.timeZoneRef,
  role: Maybe.fromUndefined(profile.role).map(userRoleFromString),
});

export const companyApiEntityToCompany = (company: CompanyApiEntity): Company => ({
  id: company.id,
  name: company.name,
});

/**
 * Service version fetch functionality
 */

export const makeServiceVersionKeyName = (service: ServicesAvailable) => `${service}:version`;
export const makeServiceTimezoneKeyName = (service: ServicesAvailable) => `${service}:timezone`;

export const fetchServiceVersions = () => async (dispatch: Dispatch<any>) => {
  localStorage.setItem(makeServiceVersionKeyName(ServicesAvailable.CLIENT), config.client.version);
  await fetchServiceVersion(ServicesAvailable.POINTER_COD, config.services.dacqs.api.url)(dispatch);
  await fetchServiceVersion(ServicesAvailable.WHITE_ANT, config.services.auth.api.url)(dispatch);
};

export const fetchServiceVersion = (service: ServicesAvailable, url: string) => async (dispatch: Dispatch<any>) => {
  logger.log(`Getting service version: ${url}`);
  return axios({
    method: 'get',
    url,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.data as ServiceVersionApiEntity)
    .then((res) => {
      logger.log(`VERSION [${service}]:${res.version}`);
      localStorage.setItem(makeServiceVersionKeyName(service), res.version);
      if (res.serverTime) {
        logger.log(`TIMEZONE GMT Server Timezone [${service}]:${res.serverTime.timezone}`);
        localStorage.setItem(makeServiceTimezoneKeyName(service), res.serverTime.timezone);
      }
    })
    .catch((error) => {
      showErrorNotification(error)(dispatch);
    });
};
