import React, { useState } from 'react';
import { AsyncTypeahead, TypeaheadLabelKey } from 'react-bootstrap-typeahead';

export interface DropdownInputItem {
  id: string | number;
  label?: string;
  group?: {
    // id: string | number;
    label: string;
  };
}

export interface DropdownElementElementSelectorProps<T extends DropdownInputItem> {
  onSelect: (element?: T) => void;
  selected?: T;
  updateOptions: (query?: string) => Promise<T[]>;
  labelKeyFn?: (option: T) => string;
  showErrorNotification: any;
}

export const DropdownInput = (props: DropdownElementElementSelectorProps<DropdownInputItem>) => {
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [isDisabled, toggleDisabled] = useState<boolean>(true);
  const [selected, setSelected] = useState<DropdownInputItem | undefined>(props.selected);
  const [options, setOptions] = useState<DropdownInputItem[]>([]);

  const handleChange = (element?: DropdownInputItem) => {
    setSelected(element);
    props.onSelect(element);
  };

  const {
    updateOptions,
    labelKeyFn = ((option: DropdownInputItem) => {
      console.log('option =>', option);
      return `${option && option.label ? (option.group ? `${option.group.label} - ${option.label}` : option.label) : ''}`;
    }) as TypeaheadLabelKey<DropdownInputItem>,
    showErrorNotification,
  } = props;

  const validatedOptions = (options: DropdownInputItem[]): DropdownInputItem[] => {
    console.log('validate options =>', options);
    options.forEach((option) => {
      if (!option.id || !option.label) {
        throw new Error('Dropdown options list not valid. Must be list of { id: string; label: string }');
      }
    });
    return options;
  };

  const updateOptionsFn = (query?: string): Promise<DropdownInputItem[]> => updateOptions(query).then(validatedOptions);

  if (selected && !selected.label) {
    updateOptionsFn()
      .then((options) => {
        const element = options.find((r) => r.id === selected.id);
        if (element) {
          setSelected({ ...selected, label: labelKeyFn ? labelKeyFn(element) : element.label });
        } else {
          handleChange();
        }
        toggleDisabled(false);
      })
      .catch((error) => showErrorNotification(error));
  }

  return (
    <AsyncTypeahead<DropdownInputItem>
      data-testid="report-editor-data-source"
      id="report-editor-data-source"
      isLoading={isLoading}
      disabled={isDisabled}
      onSearch={(query) => {
        toggleLoading(true);
        updateOptionsFn(query)
          .then((results) => {
            setOptions(results);
          })
          .finally(() => {
            toggleLoading(false);
          })
          .catch((error) => showErrorNotification(error));
      }}
      onChange={([selected]) => handleChange(selected)}
      options={options}
      emptyLabel="No Data Sources elements found"
      searchText="Looking for Data Source elements..."
      placeholder="Start typing to find Data Source elements by name"
      selected={(selected && [selected]) || []}
      labelKey={labelKeyFn}
    />
  );
};
