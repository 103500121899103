import { TemplateTypes } from '@serverfarm/rs-commons';
import logdown from 'logdown';
import { Maybe } from 'monet';
// @ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';
import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import SimpleCodeEditor from 'react-simple-code-editor';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { fetchCompanies } from '../user';
import { DateTimeRender, SIMPLE_CODE_EDITOR_STYLE_DEFAULT } from '../utils';

import {
  completeTemplateEdit,
  editTemplate,
  editTemplateCompany,
  editTemplateName,
  editTemplateTemplate,
  editTemplateTemplateType,
  saveTemplate,
} from './data/actions';

const logger = logdown('components:TemplateEditor');

const TemplateTypeInput = (props: Props & { id: string }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown id={props.id} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>{props.templateEditing.template.map((t) => t.templateType).orUndefined()}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Parameter Type</DropdownItem>
        {TemplateTypes.map((t) => (
          <DropdownItem id={`${props.id}-${t}`} key={t} onClick={(e) => props.editTemplateTemplateType(t)}>
            {t}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

class Component extends React.Component<Props, {}> {
  public render() {
    return (
      <Modal
        data-testid="template-editor-modal"
        id="template-editor-modal"
        size="lg"
        isOpen={this.props.templateEditing.isInProgress}
        toggle={this.cancel.bind(this)}
      >
        <ModalHeader data-testid="template-editor-header" id="template-editor-header">
          <EllipsisText text={`Edit '${this.props.templateEditing.template.map((t) => t.name || '...').getOrElse('...')}'`} length={45} />
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={this.submit.bind(this)}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                data-testid="template-editor-name-input"
                id="name"
                type="text"
                value={this.props.templateEditing.template.map((t) => t.name).getOrElse('')}
                onChange={(e) => this.props.editTemplateName(e.target.value)}
              />
              {/* <FormFeedback invalid="true">You will not be able to see this</FormFeedback> */}
              <FormText>Type name of the Template for future reference</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="report-editor-company">Company</Label>
              <Typeahead
                id="report-editor-company"
                isLoading={this.props.companiesListing.isInProgress}
                onChange={(selected) => this.props.editTemplateCompany(Maybe.fromUndefined(selected[0]))}
                options={this.props.companiesListing.companies.getOrElse([])}
                emptyLabel="No Companies found"
                placeholder="Start typing to find a Company by name"
                defaultSelected={this.props.templateEditing.template
                  .flatMap((t) => t.company)
                  .map((t) => [t])
                  .getOrElse([])}
                labelKey="name"
                // isValid={this.props.reportEditing.report.flatMap(report => report.company).isSome()}
                isInvalid={this.props.templateEditing.template.flatMap((t) => t.company).isNone()}
                disabled={this.props.templateEditing.template.map((t) => !!t.id).contains(true)}
              />
              <FormText>
                Choose a Company to bind the Template to
                <span hidden={this.props.templateEditing.template.map((t) => !!t.id).contains(true)}>
                  or{' '}
                  <a href="#" onClick={this.setMyCompany.bind(this)}>
                    use your company
                  </a>
                </span>
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="template">Template</Label>
              <SimpleCodeEditor
                data-testid="template-editor-template-input"
                id="template"
                value={this.props.templateEditing.template.map((t) => t.template).getOrElse('')}
                onValueChange={(code) => this.props.editTemplateTemplate(code)}
                highlight={(code) => highlight(code, languages.markup)}
                padding={10}
                style={SIMPLE_CODE_EDITOR_STYLE_DEFAULT}
              />
              <FormText>The template content will be later used to render a data to it</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="template-type">Template Type</Label>
              <TemplateTypeInput id="template-editor-template-type" {...this.props} />
              {/* <FormText>The template content will later be used to render the data in it</FormText> */}
            </FormGroup>
            <FormGroup>
              <Label for="updatedDate">Updated Date</Label>
              <div id="updatedDate">
                <DateTimeRender
                  date={this.props.templateEditing.template.flatMap((t) => t.updated).orUndefined()}
                  timeZoneRef={this.props.user.profile.timeZoneRef}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="createdDate">Created Date</Label>
              <div id="createdDate">
                <DateTimeRender
                  date={this.props.templateEditing.template.map((t) => t.created).orUndefined()}
                  timeZoneRef={this.props.user.profile.timeZoneRef}
                />
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid="template-editor-save-button"
            id="template-editor-save-button"
            color="primary"
            disabled={!this.props.templateEditing.template || this.props.templateSaving.isInProgress}
            onClick={this.submit.bind(this)}
          >
            {this.props.templateSaving.isInProgress ? <Spinner size="sm" /> : 'Save'}
          </Button>{' '}
          <Button
            data-testid="template-editor-cancel-button"
            id="template-editor-cancel-button"
            color="secondary"
            disabled={this.props.templateSaving.isInProgress}
            onClick={this.cancel.bind(this)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected submit(e: any) {
    e.preventDefault();
    this.props.templateEditing.template.forEach((template) => this.props.saveTemplate(template, !template.id));
  }

  protected cancel(e: any) {
    e.preventDefault();
    this.props.completeTemplateEdit();
  }

  protected setMyCompany(e: any) {
    e.preventDefault();
    const companyId = this.props.user.profile.companyId;
    const company = this.props.companiesListing.companies.flatMap((c) => Maybe.fromUndefined(c.find((c) => c.id === companyId)));
    this.props.editTemplateCompany(company);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      startTemplateEdit: editTemplate,
      editTemplateName,
      editTemplateCompany,
      editTemplateTemplate,
      editTemplateTemplateType,
      completeTemplateEdit,
      saveTemplate,
      fetchCompanies,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  templateEditing: state.templateEditing,
  templateSaving: state.templateSaving,
  companiesListing: state.companiesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
