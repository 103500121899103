import { Maybe } from 'monet';

import { UserRole } from '../../../auth/entities';
import { InProgressState } from '../../redux/actions';

export interface UserProfile {
  userId?: number;
  companyId?: number;
  userName?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  timeZoneRef?: string;
  role: Maybe<UserRole>;
}

export interface UserState {
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  profile: UserProfile;
}

export interface Company {
  id: number;
  name: string;
}

export interface CompaniesListingState extends InProgressState {
  companies: Maybe<Company[]>;
}

export enum ServicesAvailable {
  CLIENT = 'client',
  POINTER_COD = 'pointerCod',
  WHITE_ANT = 'whiteAnt',
}
export interface ServiceVersion {
  service: ServicesAvailable;
  version: string;
}

export interface ServicesVersionState {
  client: string;
  pointerCod: string;
  whiteAnt: string;
}
