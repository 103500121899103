import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';

import useDebounce from './useDebounce';

export type onDynamicInputChange = (value?: string | number) => void;

export interface DynamicInputProps {
  placeholder: string;
  onChange: onDynamicInputChange;
  debounce?: number;
  minLength?: number;
  initialState?: string | number;
  type: 'text' | 'number';
  id: string;
  hidden?: boolean;
  className?: string;
}

export const DynamicInput = (props: DynamicInputProps) => {
  console.log('DYNAMIC_INITIAL=>', props.initialState);
  const [inputValue, setInputValue] = useState(props.initialState);
  const debouncedInputValue = useDebounce(inputValue, props.debounce || 500);
  useEffect(() => {
    props.onChange(debouncedInputValue);
  }, [debouncedInputValue]);

  return (
    <Input
      id={props.id}
      hidden={props.hidden}
      value={inputValue}
      type={props.type}
      onChange={(e) => setInputValue(e.target.value)}
      placeholder={props.placeholder}
      className={props.className}
    />
  );
};
