// @ts-ignore
import { TemplateType } from '@serverfarm/rs-commons';
import logdown from 'logdown';
import { Some, Maybe } from 'monet';
import * as React from 'react';
// @ts-ignore
import * as Icon from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { routesList, RouteName } from '../navigation';
import { UserState } from '../user';
import { DateTimeRender } from '../utils';
import { ServerfarmTable } from '../utils/serverfarmTable';

import {
  fetchRenderedReportsHistory,
  updateRenderedReportsHistoryFilter,
  updateRenderedReportsHistoryPages,
  updateRenderedReportsHistorySort,
  copyToClipboard,
  closeRenderedReportsHistory,
} from './data/actions';
import { openRenderReportViewer } from './data/actions/renderedReportViewer.actions';
import { RenderedReport } from './entities';
import { RenderedReportsHistoryState } from './redux/entities';

const logger = logdown('component:RenderedReportHistoryList');

const actionsFormatter = (props: Props) => (row: any) => {
  const renderedReport = row;
  const ids = {
    default: `default-button-${renderedReport.id}`,
    pdf: `pdf-button-${renderedReport.id}`,
    xls: `xls-button-${renderedReport.id}`,
    imageContainer: `image-container-${renderedReport.id}`,
    image: `image-button-${renderedReport.id}`,
  };
  return (
    <span>
      <img
        id={ids.imageContainer}
        contentEditable={true}
        style={{ marginBottom: 10, width: 215, height: 160 }}
        src={renderedReport.permalinks.image}
      />{' '}
      {props.renderedReportsHistory.report.flatMap((r) => r.template).exists((t) => t.templateType === TemplateType.JSON) ? (
        <span>
          <Button className="json-download" id={ids.default} color="info" size="sm" href={renderedReport.permalinks.default} target="_blank">
            <Icon.Braces className="sm" />
          </Button>{' '}
          <UncontrolledTooltip target={ids.default}>Download as JSON</UncontrolledTooltip>{' '}
        </span>
      ) : (
        <span>
          <Button className="pdf-download" id={ids.pdf} color="info" size="sm" href={renderedReport.permalinks.pdfDownload}>
            <Icon.FileEarmarkArrowDown className="sm" />
          </Button>{' '}
          <Button className="xls-download" id={ids.xls} color="info" size="sm" href={renderedReport.permalinks.xlsDownload}>
            <Icon.FileEarmarkSpreadsheet className="sm" />
          </Button>{' '}
          <Button
            className="copy-image-to-clipboard"
            id={ids.image}
            color="info"
            size="sm"
            onClick={() =>
              props.copyToClipboard(renderedReport.permalinks.image, props.renderedReportsHistory.report.map((r) => r.name).getOrElse(''))
            }
          >
            <Icon.Image className="sm" />
          </Button>{' '}
          <UncontrolledTooltip target={ids.pdf}>Download as PDF</UncontrolledTooltip>{' '}
          <UncontrolledTooltip target={ids.xls}>Download as Excel</UncontrolledTooltip>{' '}
          <UncontrolledTooltip target={ids.image}>Copy to Clipboard</UncontrolledTooltip>{' '}
        </span>
      )}
    </span>
  );
};

const columns = (props: Props) => [
  {
    maxWidth: 300,
    Header: 'Created',
    accessor: (row: RenderedReport) => {
      return (
        <a
          className="rendered-report-history-date"
          href="#"
          onClick={() => {
            props.openRenderReportViewer(row, props.renderedReportsHistory.report.some());
          }}
        >
          <DateTimeRender date={row.created} timeZoneRef={props.user.profile.timeZoneRef} />
        </a>
      );
    },
    disableFilters: true,
  },
  {
    Header: 'Actions',
    accessor: actionsFormatter(props),
    disableFilters: true,
  },
];

class Component extends React.Component<Props, {}> {
  protected parseParamsFromLocationHash() {
    const { reportId, renderedReportId } = (this.props.location?.hash
      .replace(/^#/, '')
      .split('&')
      .map((kv) => {
        const [k, v] = kv.split('=');
        return { [k]: v };
      })
      .reduce((memo, elem) => ({ ...memo, ...elem }), {}) || {}) as { reportId?: string; renderedReportId?: string };

    return {
      reportId,
      renderedReportId,
    };
  }

  protected resetLocationHash() {
    if (this.props.location && this.props.location.hash) {
      routesList
        .filter((r) => r.name === RouteName.REPORTS)
        .forEach((reportsRoute) => {
          this.props.history.push(`${reportsRoute.layout}${reportsRoute.path}`);
        });
    }
  }

  componentDidMount() {
    /**
     * If initial reportId and renderedReportId are set then immediately trigger loading of a report
     * with id => reportId from hash params.
     * This is needed to trigger Rendered Report modal opening.
     */
    const { reportId, renderedReportId } = this.parseParamsFromLocationHash();

    if (reportId && renderedReportId) {
      /**
       * If initial reportId and renderedReportId are set then immediately trigger loading of a report
       * with id => reportId from hash params.
       * This is needed to trigger Rendered Report modal opening.
       */
      this.props.updateRenderedReportsHistoryFilter(
        Some({
          key: renderedReportId,
        }),
        this.props.renderedReportsHistory.page,
        this.props.renderedReportsHistory.sizePerPage,
      );
    } else {
      this.props.updateRenderedReportsHistoryFilter(Some({}), this.props.renderedReportsHistory.page, this.props.renderedReportsHistory.sizePerPage);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const reportIsSet = this.props.renderedReportsHistory.report.isSome();
    const isFilterUpdated = !prevProps.renderedReportsHistory.filter.equals(this.props.renderedReportsHistory.filter);
    const isSortUpdated = !prevProps.renderedReportsHistory.sort.equals(this.props.renderedReportsHistory.sort);
    const isPageSizeUpdated = !(prevProps.renderedReportsHistory.sizePerPage === this.props.renderedReportsHistory.sizePerPage);
    const isPageChanged = !(prevProps.renderedReportsHistory.page === this.props.renderedReportsHistory.page);

    if (reportIsSet && (isFilterUpdated || isSortUpdated || isPageSizeUpdated || isPageChanged)) {
      this.props.fetchRenderedReportsHistory(
        this.props.renderedReportsHistory.report.some(),
        this.props.renderedReportsHistory.filter,
        this.props.renderedReportsHistory.sort,
        this.props.renderedReportsHistory.page,
        this.props.renderedReportsHistory.sizePerPage,
      );
      return;
    }

    const isRenderedReportsUpdated = !prevProps.renderedReportsHistory.renderedReports.equals(this.props.renderedReportsHistory.renderedReports);
    if (isRenderedReportsUpdated) {
      const { reportId, renderedReportId } = this.parseParamsFromLocationHash();
      /**
       * If list of reports updated with reportId and renderedReportId set and reportId is found in list then
       * trigger rendered report history open.
       */
      if (reportId && renderedReportId) {
        // logger.log(`renderedReports => ${JSON.stringify(this.props.renderedReportsHistory.renderedReports.orUndefined())}`);
        this.props.renderedReportsHistory.renderedReports
          .flatMap((renderedReports) => Maybe.fromUndefined(renderedReports.find((r) => r.id === renderedReportId)))
          .forEach((renderedReport) => {
            this.props.openRenderReportViewer(renderedReport, this.props.renderedReportsHistory.report.some());
            this.resetLocationHash();
          });
        return;
      }
    }
  }

  public render() {
    const pageCount = Math.floor(this.props.renderedReportsHistory.total / this.props.renderedReportsHistory.sizePerPage) + 1;
    return (
      <div className="content">
        <ServerfarmTable
          columns={columns(this.props)}
          data={this.props.renderedReportsHistory.renderedReports.orJust([])}
          onPageChange={(pageIndex, pageSize) => {
            if (this.props.renderedReportsHistory.page !== pageIndex || this.props.renderedReportsHistory.sizePerPage !== pageSize) {
              this.props.updateRenderedReportsHistoryPages(pageIndex, pageSize);
            }
          }}
          onSortChange={(sortBy) => {
            // const newSort: Maybe<DataSourcesListSort> = sortBy.length > 0 ? Some({field: sortBy[0].id, order: sortBy[0].desc ? 'desc' : 'asc'}) : None();
            // this.props.dataSourcesListing.sort.equals(newSort);
            // if (!this.props.dataSourcesListing.sort.equals(newSort)) {
            //   // this.props.updateDataSourceListingFilter(this.props.dataSourcesListing.filter, pageIndex, pageSize);
            //   logger.log('SORT CHANGED FROM HOOK');
            // }
          }}
          total={this.props.renderedReportsHistory.total}
          controlledPageCount={pageCount}
          initialPaginationState={{
            pageIndex: this.props.renderedReportsHistory.page,
            pageSize: this.props.renderedReportsHistory.sizePerPage,
            sortBy: [],
          }}
          isLoading={this.props.renderedReportsHistory.isInProgress}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchRenderedReportsHistory,

      updateRenderedReportsHistoryFilter,
      updateRenderedReportsHistoryPages,
      updateRenderedReportsHistorySort,

      openRenderReportViewer,
      copyToClipboard,

      closeRenderedReportsHistory,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

interface RenderedReportsListProps {
  user: UserState;
  renderedReportsHistory: RenderedReportsHistoryState;
}

type Props = typeof stateProps & typeof dispatchProps & RenderedReportsListProps & RouteProps & RouterProps;

export default connect<typeof stateProps, typeof dispatchProps, any>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
