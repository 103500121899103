import { Maybe } from 'monet';
import * as React from 'react';
// @ts-ignore
import * as Icon from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Link } from 'react-router-dom';
import {
  Navbar,
  Container,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { logoutUser } from '../user';

import { toggleNavBar, toggleNavBarDropDown, toggleSideBarIsOpen } from './data/actions';
import { RouteName, routesList } from './routes';

let toggleUserGuideModal: () => void;

const UserGuideModal = () => {
  const [isOpen, setOpen] = React.useState(false);

  toggleUserGuideModal = () => setOpen((prevState: boolean) => !prevState);

  return (
    <Modal className="full-screen-modal" contentClassName="h-100 w-100" isOpen={isOpen} toggle={toggleUserGuideModal}>
      <ModalHeader>User Guide</ModalHeader>
      <ModalBody>
        <iframe src="/user_guide_full.pdf" width="100%" height="100%" frameBorder="0"></iframe>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggleUserGuideModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

class Component extends React.Component<Props, {}> {
  protected userGuideTooltipRef = React.createRef<UncontrolledTooltip>();

  componentDidMount() {
    if (this.userGuideTooltipRef.current) {
      this.userGuideTooltipRef.current.setState({ isOpen: true });
    }
    setTimeout(() => {
      if (this.userGuideTooltipRef.current) {
        this.userGuideTooltipRef.current.setState({ isOpen: false });
      }
    }, 3000);
  }

  protected static getPanelTitle(routeName: RouteName) {
    return Maybe.fromUndefined(routesList.find((r) => r.name === routeName))
      .map((r) => r.name.toString())
      .getOrElse('Reporting System');
  }

  render() {
    return (
      <div>
        <Navbar color={this.props.navBar.color} expand="lg" className={this.props.navBar.color === 'transparent' ? 'navbar-transparent ' : ''}>
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-toggle">
                <button type="button" className="navbar-toggler" onClick={() => this.props.toggleSideBarIsOpen()}>
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand>{Component.getPanelTitle(this.props.routes.activeRouteName)}</NavbarBrand>
            </div>
            <NavbarToggler onClick={() => this.props.toggleNavBar()}>
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </NavbarToggler>
            <Collapse isOpen={this.props.navBar.isOpen} navbar className="justify-content-end">
              <Nav navbar>
                <NavItem>
                  <a id="how-to-use-an-app" href="#" className="nav-link" onClick={toggleUserGuideModal}>
                    <Icon.QuestionCircle className="sm" />{' '}
                    <p>
                      <span className="d-lg-none d-md-block">Help</span>
                    </p>
                  </a>
                  <UncontrolledTooltip ref={this.userGuideTooltipRef} placement="bottom" target={'how-to-use-an-app'}>
                    Click here for a User Guide
                  </UncontrolledTooltip>{' '}
                </NavItem>
                <NavItem>
                  <Link id="logout-button" to="/" className="nav-link btn-rotate" onClick={() => this.props.logoutUser()}>
                    <p>Logout</p>
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <UserGuideModal />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      toggleSideBarIsOpen,
      toggleNavBarDropDown,
      toggleNavBar,
      logoutUser,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  routes: state.routes,
  navBar: state.navBar,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
