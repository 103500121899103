import { DataSourceParameterType } from '@serverfarm/rs-commons';
import logdown from 'logdown';
import { Maybe } from 'monet';
// @ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-graphql';
import 'prismjs/themes/prism.css';
import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import SimpleCodeEditor from 'react-simple-code-editor';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { fetchCompanies } from '../user';
import { DateTimeRender, SIMPLE_CODE_EDITOR_STYLE_DEFAULT, YesNo } from '../utils';

import {
  completeDataSourceEdit,
  deleteDataSourceParameter,
  editDataSourceCompany,
  editDataSourceName,
  editDataSourceParameterIsOptional,
  editDataSourceParameterName,
  editDataSourceParameterType,
  editDataSourceQuery,
  executeDataSource,
  saveDataSource,
  saveDataSourceParameter,
} from './data/actions';
import { DataSource } from './entities';

const logger = logdown('components:DataSourceEditor');

const isDataSourceExist = (dataSource: Maybe<DataSource>): boolean => dataSource.map((ds) => !!ds.id).getOrElse(false);

const ParameterNameInput = (props: Props) => (
  <Input
    data-testid="data-source-parameter-name-input"
    id="parameter-name"
    type="text"
    value={props.dataSourceParameterEditing.parameter.map((p) => p.name).getOrElse('')}
    onChange={(e) => props.editDataSourceParameterName(e.target.value)}
  />
);

const ParameterTypeInput = (props: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>{props.dataSourceParameterEditing.parameter.map((p) => p.type).orUndefined()}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Parameter Type</DropdownItem>
        {[DataSourceParameterType.STRING, DataSourceParameterType.INT, DataSourceParameterType.FLOAT, DataSourceParameterType.DATE_TIME].map((t) => (
          <DropdownItem key={t} onClick={(e) => props.editDataSourceParameterType(t)}>
            {t}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const ParameterIsOptionalInput = (props: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        <YesNo value={props.dataSourceParameterEditing.parameter.map((p) => p.isOptional)} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Is Optional?</DropdownItem>
        {[true, false].map((v) => (
          <DropdownItem key={v.toString()} onClick={() => props.editDataSourceParameterIsOptional(v)}>
            <YesNo value={Maybe.Some(v)} />
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const ParametersEdit = (props: Props) => (
  <Table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
        <th>Is Optional?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {props.dataSourceEditing.dataSource
        .flatMap((ds) => ds.parameters)
        .map((parameters) =>
          parameters.map((p) => (
            <tr data-testid={`data-source-parameter-item-${p.name}`} key={p.name}>
              <th>{p.name}</th>
              <td>{p.type}</td>
              <td>
                <YesNo value={Maybe.Some(p.isOptional)} />
              </td>
              <td>
                <Button data-testid="data-source-parameters-delete-button" color="danger" onClick={(e) => props.deleteDataSourceParameter(p.name)}>
                  Delete
                </Button>
              </td>
            </tr>
          )),
        )
        .orUndefined()}
      <tr key="data-source-parameter-editor">
        <td>
          <ParameterNameInput {...props} />
        </td>
        <td>
          <ParameterTypeInput {...props} />
        </td>
        <td>
          <ParameterIsOptionalInput {...props} />
        </td>
        <td>
          <Button
            data-testid="data-source-parameters-add-button"
            color="primary"
            onClick={(e) => props.dataSourceParameterEditing.parameter.forEach((parameter) => props.saveDataSourceParameter(parameter))}
          >
            Add
          </Button>
          {}
        </td>
      </tr>
    </tbody>
  </Table>
);

class Component extends React.Component<Props, {}> {
  public render() {
    return (
      <Modal
        id="data-source-editor-modal"
        data-testid="data-source-editor-modal"
        size="lg"
        isOpen={this.props.dataSourceEditing.isInProgress}
        toggle={this.cancel.bind(this)}
      >
        <ModalHeader>
          <EllipsisText
            data-testid="data-source-editor-header"
            id="data-source-editor-header"
            text={`Edit '${this.props.dataSourceEditing.dataSource.map((ds) => ds.name || '...').getOrElse('...')}'`}
            length={45}
          />
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={this.submit.bind(this)}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                data-testid="data-source-editor-name-input"
                id="name"
                type="text"
                value={this.props.dataSourceEditing.dataSource.map((ds) => ds.name).getOrElse('')}
                onChange={(e) => this.props.editDataSourceName(e.target.value)}
              />
              <FormFeedback invalid="true">You will not be able to see this</FormFeedback>
              <FormText>Type name of the Data Source for future reference</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="company">Company</Label>
              <Typeahead
                id="company"
                isLoading={this.props.companiesListing.isInProgress}
                onChange={(selected) => this.props.editDataSourceCompany(Maybe.fromUndefined(selected[0]))}
                options={this.props.companiesListing.companies.getOrElse([])}
                emptyLabel="No Companies found"
                placeholder="Start typing to find a Company by name"
                defaultSelected={this.props.dataSourceEditing.dataSource
                  .flatMap((ds) => ds.company)
                  .map((t) => [t])
                  .getOrElse([])}
                labelKey="name"
                isInvalid={this.props.dataSourceEditing.dataSource.flatMap((ds) => ds.company).isNone()}
                disabled={this.props.dataSourceEditing.dataSource.map((ds) => !!ds.id).contains(true)}
              />
              <FormText>
                Choose a Company to bind the Data Source to
                <span hidden={this.props.dataSourceEditing.dataSource.map((ds) => !!ds.id).contains(true)}>
                  or{' '}
                  <a href="#" onClick={this.setMyCompany.bind(this)}>
                    use your company
                  </a>
                </span>
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="query">Query</Label>
              <SimpleCodeEditor
                data-testid="data-source-editor-query-input"
                id="query"
                value={this.props.dataSourceEditing.dataSource.map((ds) => ds.query).getOrElse('')}
                onValueChange={(code) => this.props.editDataSourceQuery(code)}
                highlight={(code) => highlight(code, languages.graphql)}
                padding={10}
                style={SIMPLE_CODE_EDITOR_STYLE_DEFAULT}
              />
            </FormGroup>
            <FormGroup>
              <Label for="query">Parameters</Label>
              <ParametersEdit data-testid="data-source-editor-parameters-table" {...this.props} />
            </FormGroup>
            <FormGroup>
              <Label for="updatedDate">Updated Date</Label>
              <div id="updatedDate">
                <DateTimeRender
                  date={this.props.dataSourceEditing.dataSource.flatMap((dataSource) => dataSource.updated).orUndefined()}
                  timeZoneRef={this.props.user.profile.timeZoneRef}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="createdDate">Created Date</Label>
              <div id="createdDate">
                <DateTimeRender
                  date={this.props.dataSourceEditing.dataSource.map((dataSource) => dataSource.created).orUndefined()}
                  timeZoneRef={this.props.user.profile.timeZoneRef}
                />
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button id="data-source-editor-execute-button" color="info" onClick={this.execDataSource.bind(this)}>
            Execute
          </Button>
          <Button
            data-testid="data-source-editor-save-button"
            id="data-source-editor-save-button"
            color="success"
            disabled={this.props.dataSourceSaving.isInProgress}
            onClick={this.submit.bind(this)}
          >
            {this.props.dataSourceSaving.isInProgress ? (
              <Spinner size="sm" />
            ) : isDataSourceExist(this.props.dataSourceEditing.dataSource) ? (
              'Save'
            ) : (
              'Create'
            )}
          </Button>{' '}
          <Button
            data-testid="data-source-editor-cancel-button"
            id="data-source-editor-cancel-button"
            color="secondary"
            disabled={this.props.dataSourceSaving.isInProgress}
            onClick={this.cancel.bind(this)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected execDataSource(e: any) {
    e.preventDefault();
    this.props.dataSourceEditing.dataSource.forEach((dataSource) => this.props.executeDataSource(dataSource.id));
  }

  protected submit(e: any) {
    e.preventDefault();
    this.props.dataSourceEditing.dataSource.forEach((dataSource) => this.props.saveDataSource(dataSource, !dataSource.id));
  }

  protected cancel(e: any) {
    e.preventDefault();
    this.props.completeDataSourceEdit();
  }

  protected setMyCompany(e: any) {
    e.preventDefault();
    const companyId = this.props.user.profile.companyId;
    const company = this.props.companiesListing.companies.flatMap((c) => Maybe.fromUndefined(c.find((c) => c.id === companyId)));
    this.props.editDataSourceCompany(company);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      editDataSourceName,
      editDataSourceCompany,
      editDataSourceQuery,
      saveDataSourceParameter,
      deleteDataSourceParameter,
      editDataSourceParameterName,
      editDataSourceParameterType,
      editDataSourceParameterIsOptional,
      completeDataSourceEdit,
      saveDataSource,
      executeDataSource,
      fetchCompanies,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  dataSourceEditing: state.dataSourceEditing,
  dataSourceSaving: state.dataSourceSaving,
  dataSourceParameterEditing: state.dataSourceParameterEditing,
  companiesListing: state.companiesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
