import { getClientCronFromServerCron } from '@serverfarm/react-cron-generator';
// @ts-ignore
import { TemplateType } from '@serverfarm/rs-commons';
import parser from 'cron-parser';
import cronstrue from 'cronstrue';
import logdown from 'logdown';
import moment from 'moment';
import { Maybe, Some, None } from 'monet';
import pluralize from 'pluralize';
import * as React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
// @ts-ignore
// @ts-ignore
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { RouterProps, RouteProps } from 'react-router';
import { Button, Spinner, UncontrolledTooltip, Popover, PopoverBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { UserRole } from '../../auth/entities';
import { State } from '../../reducers';
import { makeServiceTimezoneKeyName, ServicesAvailable } from '../user';
import { withClickConfirmation, DateTimeRender } from '../utils';
import { DateRangeFilter, DateRange } from '../utils/dateRangeFilter';
import { ServerfarmTable } from '../utils/serverfarmTable';
import { TextFilter } from '../utils/textFilter';

import {
  startReportCloneEdit,
  deleteReport,
  startReportEdit,
  startReportInteractiveRendering,
  fetchReports,
  updateRenderedReportsHistoryFilter,
  updateRenderedReportsHistorySort,
  updateReportListingFilter,
  updateReportListingSort,
  updateReportListingPages,
  renderReport,
  copyLiveRenderReportLinkToClipboard,
  deferredRenderReport,
  openRenderedReportsHistory,
} from './data/actions';
import { Report, ReportsListSort } from './entities';
import { ReportsListingState } from './redux/entities';

const logger = logdown('component:ReportsList');

const ButtonWithConfirmation = withClickConfirmation(Button);

const RunButton = (props: { report: Report; renderReport: any; reportRendering: any; startReportInteractiveRendering: any; disabled: boolean }) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const toggle = () => setPopoverOpen(!popoverOpen);

  const render = (e: any) => {
    e.preventDefault();
    props.renderReport(props.report);
    toggle();
  };

  const renderInteractive = (e: any) => {
    e.preventDefault();
    props.startReportInteractiveRendering(props.report);
    toggle();
  };

  return (
    <span>
      <Button
        className="render-report-button"
        data-testid="render-report-button"
        id={`render-report-button-${props.report.id}`}
        color="info"
        size="sm"
        disabled={props.reportRendering.isInProgress}
        onClick={toggle}
      >
        {props.reportRendering.isInProgress && props.reportRendering.reportId.contains(props.report.id) ? (
          <Spinner size="sm" />
        ) : (
          <Icon.Play className="sm" />
        )}
      </Button>
      <Popover isOpen={popoverOpen} target={`render-report-button-${props.report.id}`} toggle={toggle}>
        <PopoverBody>
          <Form inline>
            <Button className="render-report-confirm" color="success" size="sm" onClick={render}>
              Run
            </Button>
            <Button className="interactive-render-report-confirm" color="success" size="sm" onClick={renderInteractive}>
              Show options
            </Button>
            <Button className="render-report-cancel" color="default" size="sm" onClick={() => toggle()}>
              Cancel
            </Button>
          </Form>
        </PopoverBody>
      </Popover>
    </span>
  );
};

const SendButton = (props: { reportId: string; deferredRenderReport: any; disabled: boolean }) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const toggle = () => setPopoverOpen(!popoverOpen);

  const confirm = (e: any) => {
    e.preventDefault();
    props.deferredRenderReport(props.reportId, email);
    toggle();
  };

  return (
    <span>
      <Button
        className="send-report-email-button"
        id={`deferred-render-report-${props.reportId}`}
        color="info"
        size="sm"
        onClick={toggle}
        disabled={props.disabled}
      >
        <Icon.Envelope className="sm" />
      </Button>
      <Popover isOpen={popoverOpen} target={`deferred-render-report-${props.reportId}`} toggle={toggle}>
        <PopoverBody>
          <Form inline>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Label for={`deferred-render-report-email-${props.reportId}`} className="mr-sm-2">
                Email
              </Label>
              <Input
                className="send-report-email-input"
                id={`deferred-render-report-email-${props.reportId}`}
                name="deferred-render-report-email"
                type="email"
                // placeholder="something@idk.cool"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            {/* <Button>Submit</Button> */}
            <Button className="send-report-email-ok" color="success" size="sm" onClick={confirm}>
              Ok
            </Button>
            <Button className="send-report-email-cancel" color="default" size="sm" onClick={() => toggle()}>
              Cancel
            </Button>
          </Form>
        </PopoverBody>
      </Popover>
    </span>
  );
};

class NameFilter extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    const isIsInProgressChanged = nextProps.reportsListing.isInProgress !== this.props.reportsListing.isInProgress;
    const isTotalChanged = nextProps.reportsListing.total !== this.props.reportsListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    return (
      <TextFilter
        placeholder={
          this.props.reportsListing.isInProgress
            ? 'Loading...'
            : `Search among ${this.props.reportsListing.total} ${pluralize('record', this.props.reportsListing.total)}...`
        }
        isSearching={this.props.reportsListing.isInProgress}
        onChange={(val) => {
          /**
           * Only set value if its changed. Consider '' === undefined
           */
          const valueToSet = val === '' || val === undefined ? undefined : val;
          if (this.props.reportsListing.filter.exists((filter) => filter.name !== valueToSet)) {
            this.props.updateReportListingFilter(
              this.props.reportsListing.filter.map((filter) => ({ ...filter, name: valueToSet })),
              this.props.reportsListing.page,
              this.props.reportsListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

class ActionsFormatter extends React.Component<Props & { report: Report }> {
  shouldComponentUpdate(nextProps: Props & { report: any }) {
    const isReportUpdated = nextProps.report.id !== this.props.report.id;
    const isReportLiveLinkGenerationInProgressChanged =
      nextProps.reportLiveLinkGeneration.isInProgress !== this.props.reportLiveLinkGeneration.isInProgress;
    const isRenderingInProgressChanged = nextProps.reportRendering.isInProgress !== this.props.reportRendering.isInProgress;
    const isDeletingInProgressChanged = nextProps.reportDeleting.isInProgress !== this.props.reportDeleting.isInProgress;
    const isListInProgressChanged = nextProps.reportsListing.isInProgress !== this.props.reportsListing.isInProgress;

    const shouldChange =
      isReportUpdated ||
      isReportLiveLinkGenerationInProgressChanged ||
      isRenderingInProgressChanged ||
      isDeletingInProgressChanged ||
      isListInProgressChanged;
    return shouldChange;
  }

  render() {
    return (
      <span>
        <RunButton
          report={this.props.report}
          renderReport={this.props.renderReport}
          startReportInteractiveRendering={this.props.startReportInteractiveRendering}
          reportRendering={this.props.reportRendering}
          disabled={this.props.reportRendering.isInProgress}
        />{' '}
        {this.props.report.template.exists((t) => [TemplateType.JSON, TemplateType.CSV].includes(t.templateType)) ? (
          <span>
            <Button
              className="copy-render-link-to-clipboard-button"
              data-testid="copy-render-link-to-clipboard-button"
              id={`copy-render-link-to-clipboard-button-${this.props.report.id}`}
              color="info"
              size="sm"
              disabled={this.props.reportRendering.isInProgress}
              onClick={() => this.props.copyLiveRenderReportLinkToClipboard(this.props.report)}
            >
              {this.props.reportLiveLinkGeneration.isInProgress && this.props.reportLiveLinkGeneration.reportId.contains(this.props.report.id) ? (
                <Spinner size="sm" />
              ) : (
                <Icon.Braces className="sm" />
              )}
            </Button>
            <UncontrolledTooltip target={`copy-render-link-to-clipboard-button-${this.props.report.id}`}>
              Copy Render Link to Clipboard
            </UncontrolledTooltip>{' '}
          </span>
        ) : (
          ''
        )}
        <SendButton
          reportId={this.props.report.id}
          deferredRenderReport={this.props.deferredRenderReport}
          disabled={this.props.reportRendering.isInProgress}
        />{' '}
        <Button
          className="history-button"
          data-testid="history-button"
          id={`history-button-${this.props.report.id}`}
          color="info"
          size="sm"
          onClick={() => this.props.openRenderedReportsHistory(this.props.report)}
          disabled={this.props.reportRendering.isInProgress}
        >
          <Icon.List className="sm" />
        </Button>{' '}
        <Button
          className="edit-button"
          id={`edit-button-${this.props.report.id}`}
          data-testid={`edit-button-${this.props.report.id}`}
          color="success"
          size="sm"
          onClick={() => this.props.startReportEdit(Maybe.Some(this.props.report))}
          disabled={this.props.reportRendering.isInProgress}
        >
          <Icon.PencilSquare className="sm" />
        </Button>{' '}
        <Button
          className="clone-button"
          hidden={!this.props.user.profile.role.contains(UserRole.ADMIN)}
          id={`clone-button-${this.props.report.id}`}
          data-testid={`clone-button-${this.props.report.id}`}
          color="primary"
          size="sm"
          onClick={() => this.props.startReportCloneEdit(Maybe.Some(this.props.report))}
          disabled={this.props.reportRendering.isInProgress}
        >
          <Icon.Files className="sm" />
        </Button>{' '}
        <ButtonWithConfirmation
          className="delete-button"
          hidden={!this.props.user.profile.role.contains(UserRole.ADMIN)}
          id={`delete-button-${this.props.report.id}`}
          data-testid={`delete-button-${this.props.report.id}`}
          color="danger"
          size="sm"
          onClick={() => this.props.deleteReport(this.props.report.id)}
          popoverCaption="Delete DataSource?"
          popoverOkButton="Yes"
          popoverCancelButton="No"
          disabled={this.props.reportRendering.isInProgress}
        >
          {this.props.reportDeleting.isInProgress && this.props.reportDeleting.reportId.contains(this.props.report.id) ? (
            <Spinner size="sm" />
          ) : (
            <Icon.TrashFill className="sm" />
          )}
        </ButtonWithConfirmation>
        <UncontrolledTooltip target={`render-report-button-${this.props.report.id}`}>Run Report</UncontrolledTooltip>
        <UncontrolledTooltip target={`deferred-render-report-${this.props.report.id}`}>Email Report</UncontrolledTooltip>
        <UncontrolledTooltip target={`history-button-${this.props.report.id}`}>Report History</UncontrolledTooltip>
        <UncontrolledTooltip target={`edit-button-${this.props.report.id}`}>Edit Report</UncontrolledTooltip>
        <UncontrolledTooltip target={`clone-button-${this.props.report.id}`}>Clone</UncontrolledTooltip>
        <UncontrolledTooltip target={`delete-button-${this.props.report.id}`}>Delete</UncontrolledTooltip>
      </span>
    );
  }
}

const SelectColumnFilter = (props: Props) => (params: any) => {
  const selectedCompanyIds = props.reportsListing.filter
    .map((dsf) => {
      return dsf.companies || [];
    })
    .orJust([]);
  return (
    <Typeahead
      multiple={true}
      data-testid="data-source-filter-company"
      id="data-source-filter-company"
      isLoading={props.companiesListing.isInProgress}
      onChange={(selected) => {
        const filter = props.reportsListing.filter.map((filter) => ({
          ...filter,
          companies: selected.map((c) => c.id.toString()),
        }));
        props.updateReportListingFilter(filter, props.reportsListing.page, props.reportsListing.sizePerPage);
      }}
      options={props.companiesListing.companies.getOrElse([])}
      emptyLabel="No Companies found"
      placeholder="Select a company"
      defaultSelected={props.companiesListing.companies.map((carr) => carr.filter((c) => selectedCompanyIds.includes(c.id.toString()))).getOrElse([])}
      labelKey="name"
      disabled={props.reportsListing.isInProgress}
    />
  );
};

const CreatedDateRangeFilter = (props: Props & { reportsListing: ReportsListingState }) => (
  <DateRangeFilter
    id="date-range-filter-created"
    value={props.reportsListing.filter.flatMap((filter) => filter.created)}
    onReset={() => {
      props.updateReportListingFilter(
        props.reportsListing.filter.map((filter) => ({
          ...filter,
          created: None(),
        })),
        props.reportsListing.page,
        props.reportsListing.sizePerPage,
      );
    }}
    onSave={(range: DateRange) => {
      props.updateReportListingFilter(
        props.reportsListing.filter.map((filter) => ({
          ...filter,
          created: Some(range),
        })),
        props.reportsListing.page,
        props.reportsListing.sizePerPage,
      );
    }}
  />
);

const UpdatedDateRangeFilter = (props: Props & { reportsListing: ReportsListingState }) => (
  <DateRangeFilter
    id="date-range-filter-updated"
    value={props.reportsListing.filter.flatMap((filter) => filter.updated)}
    onReset={() => {
      props.updateReportListingFilter(
        props.reportsListing.filter.map((filter) => ({
          ...filter,
          updated: None(),
        })),
        props.reportsListing.page,
        props.reportsListing.sizePerPage,
      );
    }}
    onSave={(range: DateRange) => {
      props.updateReportListingFilter(
        props.reportsListing.filter.map((filter) => ({
          ...filter,
          updated: Some(range),
        })),
        props.reportsListing.page,
        props.reportsListing.sizePerPage,
      );
    }}
  />
);

const LastRenderedDateRangeFilter = (props: Props & { reportsListing: ReportsListingState }) => (
  <DateRangeFilter
    id="date-range-filter-last-rendered"
    value={props.reportsListing.filter.flatMap((filter) => filter.lastRendered)}
    onReset={() => {
      props.updateReportListingFilter(
        props.reportsListing.filter.map((filter) => ({
          ...filter,
          lastRendered: None(),
        })),
        props.reportsListing.page,
        props.reportsListing.sizePerPage,
      );
    }}
    onSave={(range: DateRange) => {
      props.updateReportListingFilter(
        props.reportsListing.filter.map((filter) => ({
          ...filter,
          lastRendered: Some(range),
        })),
        props.reportsListing.page,
        props.reportsListing.sizePerPage,
      );
    }}
  />
);
interface NameMaybeFilterProps {
  filterField: 'dataSource' | 'template';
}

class NameMaybeFilter extends React.Component<Props & NameMaybeFilterProps> {
  shouldComponentUpdate(nextProps: Props) {
    const isIsInProgressChanged = nextProps.reportsListing.isInProgress !== this.props.reportsListing.isInProgress;
    const isTotalChanged = nextProps.reportsListing.total !== this.props.reportsListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    return (
      <TextFilter
        placeholder={`Search among ${this.props.reportsListing.total} ${pluralize('record', this.props.reportsListing.total)}...`}
        isSearching={this.props.reportsListing.isInProgress}
        onChange={(val) => {
          /**
           * Only set value if its changed. Consider '' === undefined
           */
          const valueToSet = val === '' || val === undefined ? undefined : val;
          if (this.props.reportsListing.filter.exists((filter) => filter[this.props.filterField] !== valueToSet)) {
            this.props.updateReportListingFilter(
              this.props.reportsListing.filter.map((filter) => ({ ...filter, [this.props.filterField]: valueToSet })),
              this.props.reportsListing.page,
              this.props.reportsListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

const byRoleColumns = (props: Props) => {
  return [
    {
      id: 'dataSource',
      width: 'auto',
      Header: 'DataSource',
      accessor: (row: Report) => {
        const className = 'reports-list-item-datasource';
        return (
          <span id={`${className}-${row.id}`} className={className}>
            {row.dataSource.map((ds) => ds.name).orUndefined()}
          </span>
        );
      },
      Filter: (
        <NameMaybeFilter
          {...{
            ...props,
            filterField: 'dataSource',
          }}
        />
      ),
    },
    {
      id: 'template',
      width: 'auto',
      Header: 'Template',
      accessor: (row: Report) => {
        const className = 'reports-list-item-template';
        return (
          <span id={`${className}-${row.id}`} className={className}>
            {row.template.map((ds) => ds.name).orUndefined()}
          </span>
        );
      },
      Filter: (
        <NameMaybeFilter
          {...{
            ...props,
            filterField: 'template',
          }}
        />
      ),
    },
  ];
};

const columns = (props: Props) => [
  {
    id: 'name',
    width: 'auto',
    Header: 'Name',
    accessor: (row: Report) => {
      const className = 'reports-list-item-name';
      return (
        <span id={`${className}-${row.id}`} className={className}>
          {row.name}
        </span>
      );
    },
    Filter: <NameFilter {...props} />,
  },
  ...props.user.profile.role
    .map((r) => {
      if (r === UserRole.ADMIN) {
        return byRoleColumns(props);
      } else return [];
    })
    .orJust([]),
  {
    width: 200,
    Header: 'Company',
    accessor: (row: Report) => {
      const className = 'reports-list-item-company';
      return (
        <span id={`${className}-${row.id}`} className={className}>
          {row.company.map((c) => c.name).getOrElse('N/A')}
        </span>
      );
    },
    // Cell: (cell: any) => renderCompanyColumn(props, cell),
    Filter: SelectColumnFilter(props),
    disableSortBy: true,
  },
  {
    width: 200,
    Header: 'Scheduled',
    accessor: (row: Report) =>
      row.scheduleSettings
        .map((settings) => {
          try {
            const cronInterval = parser.parseExpression(settings.schedule.recurring.cron);
            let humanizedNextDate = moment(cronInterval.next().toDate()).fromNow();
            let humanizedCronExpression = cronstrue.toString(settings.schedule.recurring.cron);
            if (settings.schedule.recurring.timezone) {
              const serverTimezoneStored = localStorage.getItem(makeServiceTimezoneKeyName(ServicesAvailable.POINTER_COD));
              const clientCron = getClientCronFromServerCron(
                settings.schedule.recurring.cron,
                settings.schedule.recurring.timezone,
                serverTimezoneStored || 'Etc/UTC',
              );
              const clientCronInterval = parser.parseExpression(clientCron);
              // eslint-disable-next-line prettier/prettier
              humanizedNextDate = moment(clientCronInterval.next().toDate()).tz(settings.schedule.recurring.timezone, true).fromNow();
              humanizedCronExpression = cronstrue.toString(clientCron);
            }
            return (
              <span>
                <span id={`humanized-cron-${row.id}`} className="badge badge-success">
                  {humanizedNextDate}
                  {settings.schedule.recurring.timezone ? `(${settings.schedule.recurring.timezone})` : ''}
                </span>
                <UncontrolledTooltip target={`humanized-cron-${row.id}`}>
                  {humanizedCronExpression} {settings.schedule.recurring.timezone ? `(${settings.schedule.recurring.timezone})` : ''}
                </UncontrolledTooltip>
              </span>
            );
          } catch (error) {
            return <span>N/A</span>;
          }
        })
        .getOrElse(<span className="badge badge-danger">not scheduled</span>),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: 'created',
    width: 200,
    Header: 'Created',
    accessor: (row: Report) => {
      const className = 'reports-list-item-created';
      return <DateTimeRender id={`${className}-${row.id}`} className={className} date={row.created} timeZoneRef={props.user.profile.timeZoneRef} />;
    },
    Filter: CreatedDateRangeFilter(props),
  },
  {
    id: 'updated',
    width: 200,
    Header: 'Updated',
    accessor: (row: Report) => {
      const className = 'reports-list-item-updated';
      return <DateTimeRender id={`${className}-${row.id}`} className={className} date={row.updated} timeZoneRef={props.user.profile.timeZoneRef} />;
    },
    Filter: UpdatedDateRangeFilter(props),
  },
  {
    id: 'lastRendered',
    width: 200,
    Header: 'Last Run',
    accessor: (row: Report) => {
      return <DateTimeRender date={row.lastRendered} timeZoneRef={props.user.profile.timeZoneRef} />;
    },
    Filter: LastRenderedDateRangeFilter(props),
  },
  {
    width: 345,
    Header: 'Actions',
    accessor: (row: Report) => <ActionsFormatter {...props} report={row} />,
    disableFilters: true,
    disableSortBy: true,
  },
];

class Component extends React.Component<Props, {}> {
  protected parseParamsFromLocationHash() {
    const { reportId, renderedReportId } = (this.props.location?.hash
      .replace(/^#/, '')
      .split('&')
      .map((kv) => {
        const [k, v] = kv.split('=');
        return { [k]: v };
      })
      .reduce((memo, elem) => ({ ...memo, ...elem }), {}) || {}) as { reportId?: string; renderedReportId?: string };

    return {
      reportId,
      renderedReportId,
    };
  }

  componentDidMount() {
    const { reportId, renderedReportId } = this.parseParamsFromLocationHash();

    /**
     * If initial reportId and renderedReportId are set then immediately trigger loading of a report
     * with id => reportId from hash params.
     * This is needed to trigger Rendered Report modal opening.
     */
    if (reportId && renderedReportId) {
      this.props.updateReportListingFilter(
        Some({
          id: reportId,
          created: None(),
          updated: None(),
          lastRendered: None(),
        }),
        this.props.reportsListing.page,
        this.props.reportsListing.sizePerPage,
      );
    } else {
      this.props.updateReportListingFilter(
        Some({ created: None(), updated: None(), lastRendered: None() }),
        this.props.reportsListing.page,
        this.props.reportsListing.sizePerPage,
      );
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { reportId, renderedReportId } = this.parseParamsFromLocationHash();

    /**
     * If list of reports updated with reportId and renderedReportId set and reportId is found in list then
     * trigger rendered report history open.
     */
    if (reportId && renderedReportId) {
      this.props.reportsListing.reports
        .flatMap((reports) => Maybe.fromUndefined(reports.find((r) => r.id === reportId)))
        .forEach((report) => {
          this.props.openRenderedReportsHistory(report);
        });
    }

    const isFilterUpdated = !prevProps.reportsListing.filter.equals(this.props.reportsListing.filter);
    const isSortingUpdated = !prevProps.reportsListing.sort.equals(this.props.reportsListing.sort);
    const isPageSizeUpdated = !(prevProps.reportsListing.sizePerPage === this.props.reportsListing.sizePerPage);
    const isPageChanged = !(prevProps.reportsListing.page === this.props.reportsListing.page);
    if (isSortingUpdated || isFilterUpdated || isPageSizeUpdated || isPageChanged) {
      this.props.fetchReports(
        this.props.reportsListing.filter,
        this.props.reportsListing.page,
        this.props.reportsListing.sizePerPage,
        this.props.reportsListing.sort,
      );
      return;
    }
  }

  public render() {
    const pageCount = Math.floor(this.props.reportsListing.total / this.props.reportsListing.sizePerPage) + 1;
    return (
      <div className="content">
        <ServerfarmTable
          columns={columns(this.props)}
          data={this.props.reportsListing.reports.orJust([])}
          onPageChange={(pageIndex, pageSize) => {
            if (this.props.reportsListing.page !== pageIndex || this.props.reportsListing.sizePerPage !== pageSize) {
              this.props.updateReportListingPages(pageIndex, pageSize);
            }
          }}
          onSortChange={(sortBy) => {
            const newSort: Maybe<ReportsListSort> =
              sortBy.length > 0 ? Some({ field: sortBy[0].id, order: sortBy[0].desc ? 'desc' : 'asc' }) : None();
            this.props.reportsListing.sort.equals(newSort);
            if (!this.props.reportsListing.sort.equals(newSort)) {
              this.props.updateReportListingSort(newSort);
            }
          }}
          total={this.props.reportsListing.total}
          controlledPageCount={pageCount}
          initialPaginationState={{
            pageIndex: this.props.reportsListing.page,
            pageSize: this.props.reportsListing.sizePerPage,
            sortBy: this.props.reportsListing.sort.map((s) => [{ id: s.field, desc: s.order === 'desc' }]).orJust([]),
          }}
          isLoading={this.props.reportsListing.isInProgress}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchReports,
      startReportCloneEdit,
      startReportEdit,
      startReportInteractiveRendering,
      deleteReport,
      updateRenderedReportsHistoryFilter,
      updateRenderedReportsHistorySort,
      updateReportListingFilter,
      updateReportListingSort,
      updateReportListingPages,
      renderReport,
      copyLiveRenderReportLinkToClipboard,
      deferredRenderReport,
      openRenderedReportsHistory,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  reportsListing: state.reportsListing,
  reportDeleting: state.reportDeleting,
  companiesListing: state.companiesListing,
  reportRendering: state.reportRendering,
  reportLiveLinkGeneration: state.reportLiveLinkGeneration,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
