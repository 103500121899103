import { TemplateType } from '@serverfarm/rs-commons';
import { Maybe } from 'monet';

import { Action, ErrorAction } from '../../redux/actions';
import { Company } from '../../user';
import { Template, TemplatesListSort, TemplatesListFilter } from '../entities';

/**
 * Action types
 */

export enum ActionType {
  REQUEST_TEMPLATES = 'REQUEST_TEMPLATES',
  RECEIVE_TEMPLATES = 'RECEIVE_TEMPLATES',
  RECEIVE_TEMPLATES_ERROR = 'RECEIVE_TEMPLATES_ERROR',

  START_TEMPLATE_EDITING = 'START_TEMPLATE_EDITING',
  EDIT_TEMPLATE_NAME = 'EDIT_TEMPLATE_NAME',
  EDIT_TEMPLATE_COMPANY = 'EDIT_TEMPLATE_COMPANY',
  EDIT_TEMPLATE_TEMPLATE = 'EDIT_TEMPLATE_TEMPLATE',
  EDIT_TEMPLATE_TEMPLATE_TYPE = 'EDIT_TEMPLATE_TEMPLATE_TYPE',
  COMPLETE_TEMPLATE_EDITING = 'COMPLETE_TEMPLATE_EDITING',

  START_TEMPLATE_SAVING = 'START_TEMPLATE_SAVING',
  DONE_TEMPLATE_SAVING = 'DONE_TEMPLATE_SAVING',
  FAIL_TEMPLATE_SAVING = 'FAIL_TEMPLATE_SAVING',

  START_TEMPLATE_DELETING = 'START_TEMPLATE_DELETING',
  DONE_TEMPLATE_DELETING = 'DONE_TEMPLATE_DELETING',
  FAIL_TEMPLATE_DELETING = 'FAIL_TEMPLATE_DELETING',

  UPDATE_TEMPLATE_LISTING_FILTER = 'UPDATE_TEMPLATE_LISTING_FILTER',
  UPDATE_TEMPLATE_SORT_LIST = 'UPDATE_TEMPLATE_SORT_LIST',

  RESET_TEMPLATES = 'RESET_TEMPLATES',
}

type ResetTemplatesAction = Action<ActionType>;

/**
 * Template fetching
 */

interface TemplatesRequestAction extends Action<ActionType> {
  filter: Maybe<TemplatesListFilter>;
  sort: Maybe<TemplatesListSort>;
  sizePerPage: number;
}

interface TemplatesReceiveAction extends Action<ActionType> {
  templates: Template[];
  page: number;
  total: number;
}

type TemplatesReceiveErrorAction = ErrorAction<ActionType>;

export type TemplatesFetchActionObject = TemplatesRequestAction & TemplatesReceiveAction & TemplatesReceiveErrorAction & ResetTemplatesAction;

export const templatesRequest = (
  filter: Maybe<TemplatesListFilter>,
  sizePerPage: number,
  sort: Maybe<TemplatesListSort>,
): TemplatesRequestAction => ({
  type: ActionType.REQUEST_TEMPLATES,
  filter,
  sizePerPage,
  sort,
});

export const templatesReceive = (templates: Template[], page: number, total: number): TemplatesReceiveAction => ({
  type: ActionType.RECEIVE_TEMPLATES,
  templates,
  page,
  total,
});

export const templatesReceiveError = (error: Error): TemplatesReceiveErrorAction => ({
  type: ActionType.RECEIVE_TEMPLATES_ERROR,
  error,
});

/**
 * Template editing
 */

interface TemplateStartEditingAction extends Action<ActionType> {
  template: Maybe<Template>;
}

interface TemplateEditNameAction extends Action<ActionType> {
  name: string;
}

interface TemplateEditCompanyAction extends Action<ActionType> {
  company: Maybe<Company>;
}

interface TemplateEditTemplateAction extends Action<ActionType> {
  template: string;
}

interface TemplateEditTemplateTypeAction extends Action<ActionType> {
  templateType: TemplateType;
}

interface TemplateCompleteEditingAction extends Action<ActionType> {
  template: Maybe<Template>;
}

export type TemplateEditingActionObject = TemplateStartEditingAction &
  TemplateEditNameAction &
  TemplateEditCompanyAction &
  TemplateEditTemplateAction &
  TemplateEditTemplateTypeAction &
  TemplateCompleteEditingAction &
  ResetTemplatesAction;

export const templateStartEditing = (template: Maybe<Template>): TemplateStartEditingAction => ({
  type: ActionType.START_TEMPLATE_EDITING,
  template,
});

export const templateEditName = (name: string): TemplateEditNameAction => ({
  type: ActionType.EDIT_TEMPLATE_NAME,
  name,
});

export const templateEditCompany = (company: Maybe<Company>): TemplateEditCompanyAction => ({
  type: ActionType.EDIT_TEMPLATE_COMPANY,
  company,
});

export const templateEditTemplate = (template: string): TemplateEditTemplateAction => ({
  type: ActionType.EDIT_TEMPLATE_TEMPLATE,
  template,
});

export const templateEditTemplateType = (templateType: TemplateType): TemplateEditTemplateTypeAction => ({
  type: ActionType.EDIT_TEMPLATE_TEMPLATE_TYPE,
  templateType,
});

export const templateCompleteEditing = (template: Maybe<Template>): TemplateCompleteEditingAction => ({
  type: ActionType.COMPLETE_TEMPLATE_EDITING,
  template,
});

/**
 * Template saving
 */

type TemplateStartSavingAction = Action<ActionType>;

interface TemplateDoneSavingAction extends Action<ActionType> {
  template: Template;
}

type TemplateFailSavingAction = ErrorAction<ActionType>;

export type TemplateSavingActionObject = TemplateStartSavingAction & TemplateDoneSavingAction & TemplateFailSavingAction & ResetTemplatesAction;

export const templateStartSaving = (): TemplateStartSavingAction => ({
  type: ActionType.START_TEMPLATE_SAVING,
});

export const templateDoneSaving = (template: Template): TemplateDoneSavingAction => ({
  type: ActionType.DONE_TEMPLATE_SAVING,
  template,
});

export const templateFailSaving = (error: Error): TemplateFailSavingAction => ({
  type: ActionType.FAIL_TEMPLATE_SAVING,
  error,
});

/**
 * Template deleting
 */
interface TemplateStartDeletingAction extends Action<ActionType> {
  templateId: string;
}

interface TemplateDoneDeletingAction extends Action<ActionType> {
  templateId: string;
}

interface TemplateFailDeletingAction extends Action<ActionType> {
  templateId: string;
  error: Maybe<Error>;
}
interface TemplatesListFilterUpdateAction extends Action<ActionType> {
  filter: Maybe<TemplatesListFilter>;
  page: number;
  sizePerPage: number;
}
interface TemplatesListSortUpdateAction extends Action<ActionType> {
  sort: Maybe<TemplatesListSort>;
}
export type TemplateDeletingActionObject = TemplateStartDeletingAction &
  TemplateDoneDeletingAction &
  TemplateFailDeletingAction &
  ResetTemplatesAction;

export const templateStartDeleting = (templateId: string): TemplateStartDeletingAction => ({
  type: ActionType.START_TEMPLATE_DELETING,
  templateId,
});

export const templateDoneDeleting = (templateId: string): TemplateDoneDeletingAction => ({
  type: ActionType.DONE_TEMPLATE_DELETING,
  templateId,
});

export const templateFailDeleting = (templateId: string, error: Maybe<Error>): TemplateFailDeletingAction => ({
  type: ActionType.FAIL_TEMPLATE_DELETING,
  templateId,
  error,
});

export const templateListingUpdateFilter = (
  filter: Maybe<TemplatesListFilter>,
  page: number,
  sizePerPage: number,
): TemplatesListFilterUpdateAction => ({
  type: ActionType.UPDATE_TEMPLATE_LISTING_FILTER,
  filter,
  page,
  sizePerPage,
});

export const templateListingSortUpdate = (sort: Maybe<TemplatesListSort>): TemplatesListSortUpdateAction => ({
  type: ActionType.UPDATE_TEMPLATE_SORT_LIST,
  sort,
});

export const templatesReset = (): ResetTemplatesAction => ({
  type: ActionType.RESET_TEMPLATES,
});
