import { Some, None, Maybe } from 'monet';
import { Reducer } from 'redux';

import { ReportsActionType } from '../actions';
import { RenderedReportsHistoryAction, RenderedReportsHistoryActionType } from '../actions/renderedReportsHistory.actions';
import { INITIAL_REPORTS_STATE } from '../constants';
import { RenderedReportsHistoryState } from '../entities/renderedReportsHistory.entities';

export const renderedReportsHistoryReducer: Reducer<RenderedReportsHistoryState, RenderedReportsHistoryAction> = (
  state = INITIAL_REPORTS_STATE.renderedReportsHistoryState,
  action,
) => {
  switch (action.type) {
    case RenderedReportsHistoryActionType.OPEN_RENDERED_REPORTS_HISTORY:
      return {
        ...INITIAL_REPORTS_STATE.renderedReportsHistoryState,
        report: action.report,
      };
    case RenderedReportsHistoryActionType.CLOSE_RENDERED_REPORTS_HISTORY:
      return {
        ...INITIAL_REPORTS_STATE.renderedReportsHistoryState,
      };
    case RenderedReportsHistoryActionType.REQUEST_RENDERED_REPORTS_HISTORY:
      return {
        ...state,
        isInProgress: true,
        renderedReports: None(),
        total: 0,
        error: None(),
      };
    case RenderedReportsHistoryActionType.RECEIVE_RENDERED_REPORTS_HISTORY:
      return {
        ...state,
        isInProgress: false,
        renderedReports: Some(action.renderedReports),
        page: action.page,
        total: action.total,
        error: None(),
      };
    case RenderedReportsHistoryActionType.RECEIVE_RENDERED_REPORTS_HISTORY_ERROR:
      return {
        ...state,
        isInProgress: false,
        renderedReports: None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Some(action.error),
      };
    case RenderedReportsHistoryActionType.UPDATE_RENDERED_REPORTS_HISTORY_FILTER:
      return {
        ...state,
        filter: action.filter,
        page: action.page,
        sizePerPage: action.sizePerPage,
      };
    case RenderedReportsHistoryActionType.UPDATE_RENDERED_REPORTS_HISTORY_SORT:
      return {
        ...state,
        sort: action.sort,
      };
    case ReportsActionType.RESET_REPORTS:
      return INITIAL_REPORTS_STATE.renderedReportsHistoryState;
    default:
      return state;
  }
};
