import logdown from 'logdown';
import { Maybe } from 'monet';
import * as React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Button } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import {
  fetchTemplates,
  editTemplate,
  cloneTemplate,
  deleteTemplate,
  updateTemplateListingFilter,
  updateTemplateListingSort,
  resetTemplates,
} from './data/actions';
import TemplateEditor from './TemplateEditor';
import TemplatesList from './TemplatesList';

const logger = logdown('component:Templates');

class Component extends React.Component<Props, {}> {
  componentDidUpdate(prevProps: Props) {
    const isEditorClosed = prevProps.templateEditing.template.isSome() && this.props.templateEditing.template.isNone();
    const isDeletionCompleted = prevProps.templateDeleting.isInProgress && !this.props.templateDeleting.isInProgress;

    if (isEditorClosed || isDeletionCompleted) {
      this.props.fetchTemplates(
        this.props.templatesListing.filter,
        this.props.templatesListing.page,
        this.props.templatesListing.sizePerPage,
        this.props.templatesListing.sort,
      );
    }
  }

  componentWillUnmount() {
    this.props.resetTemplates();
  }

  public render() {
    return (
      <div className="content">
        <Button
          id="templates-list-create-button"
          data-testid="templates-list-create-button"
          color="primary"
          size="sm"
          disabled={this.props.templatesListing.isInProgress}
          onClick={() => this.props.editTemplate(Maybe.None())}
        >
          Create
        </Button>
        <Button
          id="templates-list-reload-button"
          data-testid="templates-list-reload-button"
          color="default"
          size="sm"
          disabled={this.props.templatesListing.isInProgress}
          onClick={() =>
            this.props.fetchTemplates(
              this.props.templatesListing.filter,
              this.props.templatesListing.page,
              this.props.templatesListing.sizePerPage,
              this.props.templatesListing.sort,
            )
          }
        >
          Refresh
        </Button>
        <TemplatesList {...this.props} />
        <TemplateEditor />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchTemplates,
      cloneTemplate,
      editTemplate,
      deleteTemplate,
      updateTemplateListingFilter,
      updateTemplateListingSort,
      resetTemplates,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  templateEditing: state.templateEditing,
  templatesListing: state.templatesListing,
  templateDeleting: state.templateDeleting,
  companiesListing: state.companiesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, any>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
