import logdown from 'logdown';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import { Table, Spinner, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const logger = logdown('component:ServerfarmTable');

export interface SortProps {
  id: string;
  desc: boolean;
}

export interface ServerfarmTableProps {
  columns: any;
  data: any;
  onPageChange: (pageIndex: number, pageSize: number) => void;
  onSortChange: (sortBy: SortProps[]) => void;
  total: number;
  initialPaginationState: {
    pageSize: number;
    pageIndex: number;
    sortBy?: SortProps[];
  };
  controlledPageCount: number;
  isLoading: boolean;
}

export function ServerfarmTable({
  columns,
  data,
  onPageChange,
  onSortChange,
  total,
  controlledPageCount,
  initialPaginationState,
  isLoading,
}: ServerfarmTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize, sortBy },
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      manualPagination: true,
      pageCount: controlledPageCount,
      // @ts-ignore
      initialState: initialPaginationState,
      useControlledState: (state: any) => {
        logger.log(`Controlled state`, state);
        return {
          ...state,
        };
      },
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    usePagination,
  ) as any;

  React.useEffect(() => {
    onPageChange(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  React.useEffect(() => {
    onSortChange(sortBy);
  }, [sortBy]);

  const PagerDropdown = (props: { pageSize: number; pageSizeSelectHandler: any; disabled: boolean }) => {
    const [dropdownOpen, setOpen] = useState(false);

    const toggle = () => setOpen(!dropdownOpen);

    const PAGE_SIZES = [10, 25, 50, 100];

    return (
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} disabled={props.disabled}>
        <DropdownToggle caret className="btn-sm" disabled={props.disabled}>
          {props.pageSize}
        </DropdownToggle>
        <DropdownMenu>
          {PAGE_SIZES.map((p) => {
            return (
              <DropdownItem key={p} onClick={(e) => props.pageSizeSelectHandler(p)}>
                {p}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  const Pager = (props: {
    isLoading: boolean;
    canPreviousPage: boolean;
    canNextPage: boolean;
    page: any[];
    pageCount: number;
    pageIndex: number;
    pageOptions: any[];
    pageSize: number;
    total: number;
    setPageSize: any;
  }) => {
    return (
      <div>
        <Button className="btn-sm page-all-left" onClick={() => gotoPage(0)} disabled={!props.canPreviousPage || props.isLoading}>
          {'<<'}
        </Button>{' '}
        <Button className="btn-sm page-left" onClick={() => previousPage()} disabled={!props.canPreviousPage || props.isLoading}>
          {'<'}
        </Button>{' '}
        <Button className="btn-sm page-right" onClick={() => nextPage()} disabled={!props.canNextPage || props.isLoading}>
          {'>'}
        </Button>{' '}
        <Button className="btn-sm page-all-right" onClick={() => gotoPage(props.pageCount - 1)} disabled={!props.canNextPage || props.isLoading}>
          {'>>'}
        </Button>{' '}
        <span className="d-inline-flex align-items-center px-2 page-number">
          Page {props.pageIndex + 1} of {props.pageOptions.length}
        </span>
        <PagerDropdown pageSize={props.pageSize} pageSizeSelectHandler={props.setPageSize} disabled={props.isLoading} />
        <span className="d-inline-flex align-items-center px-2 page-show">
          {props.isLoading ? 'Loading...' : `Showing ${props.page.length} of ${props.total} ${pluralize('item', props.total)}`}
        </span>
      </div>
    );
  };

  // Render the UI for your table
  return (
    <div>
      <Table striped={true} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr key="" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th key="" className="align-top" {...column.getHeaderProps()} style={{ width: column.width }}>
                  <div className="sortable-header" {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i: number) => {
            prepareRow(row);
            return (
              <tr key="" {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td key="" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr key="spinner" hidden={!isLoading}>
            <td colSpan={columns.length} className="text-center">
              <Spinner />
            </td>
          </tr>
        </tbody>
      </Table>
      <Pager
        isLoading={isLoading}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        page={page}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        total={total}
        setPageSize={setPageSize}
      />
    </div>
  );
}
