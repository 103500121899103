import { deserializeDataSourceParameterType } from '@serverfarm/rs-commons';
import logdown, { Logger } from 'logdown';
import moment from 'moment';
import { Maybe } from 'monet';
import { createTransform, Transform } from 'redux-persist';

import { DataSource, DataSourceParameter, dataSourceParameterOptionDeserialize } from '../entities';

import {
  DataSourceDeletingState,
  DataSourceEditingState,
  DataSourceParameterEditingState,
  DataSourceSavingState,
  DataSourcesListingState,
} from './entities';

/**
 * Entities SerDes
 */

const dataSourceParameterSerialize = (p: DataSourceParameter): any => ({
  name: p.name,
  type: p.type.toString(),
  isOptional: p.isOptional,
  default: p.default.orUndefined(),
  options: p.options.map((options) => options.map((option) => option.toString())).orUndefined(),
});

const dataSourceParameterDeserialize = (p: any): DataSourceParameter => ({
  name: p.name,
  type: deserializeDataSourceParameterType(p.type),
  isOptional: p.isOptional,
  default: Maybe.fromUndefined(p.default),
  options: Maybe.fromUndefined(p.options).map((options) => options.map(dataSourceParameterOptionDeserialize)),
});

const dataSourceSerialize = (ds: DataSource) => ({
  id: ds.id,
  name: ds.name,
  company: ds.company.orUndefined(),
  query: ds.query,
  parameters: ds.parameters.map((parameters) => parameters.map(dataSourceParameterSerialize)).orUndefined(),
  updated: ds.updated.map((updated) => updated.toISOString()).orUndefined(),
  created: ds.created.toISOString(),
});

const dataSourceDeserialize = (dss: any): DataSource => ({
  id: dss.id,
  name: dss.name,
  company: Maybe.fromUndefined(dss.company),
  query: dss.query,
  parameters: Maybe.fromUndefined(dss.parameters).map((parameters) => parameters.map(dataSourceParameterDeserialize)),
  updated: Maybe.fromUndefined(dss.updated).map((updated) => moment(updated).toDate()),
  created: moment(dss.created).toDate(),
});

/**
 * Transformations
 */

export const createDataSourcesListingTransform = (
  logger: Logger = logdown('redux-persist:transform:dataSourcesListing'),
): Transform<DataSourcesListingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        dataSources: subState.dataSources.map((dataSources) => dataSources.map(dataSourceSerialize)).orUndefined(),
        sort: subState.sort.orUndefined(),
        filter: subState.filter.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize datasources=> (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize datasources=> (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        dataSources: Maybe.fromUndefined(state.dataSources).map((dataSources) => dataSources.map(dataSourceDeserialize)),
        sort: Maybe.fromUndefined(state.sort),
        filter: Maybe.fromUndefined(state.filter),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['dataSourcesListing'] },
  );

export const createDataSourceEditingTransform = (
  logger: Logger = logdown('redux-persist:transform:dataSourceEditing'),
): Transform<DataSourceEditingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        dataSource: subState.dataSource.map(dataSourceSerialize).orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(subState, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        dataSource: Maybe.fromUndefined(state.dataSource).map(dataSourceDeserialize),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['dataSourceEditing'] },
  );

export const createDataSourceDeletingTransform = (
  logger: Logger = logdown('redux-persist:transform:dataSourceDeleting'),
): Transform<DataSourceDeletingState, any> =>
  createTransform(
    (subState, key, state) => {
      const result = {
        ...subState,
        dataSourceId: subState.dataSourceId.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    (state, key, rawState) => {
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        dataSourceId: Maybe.fromUndefined(state.dataSourceId),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
    },
    { whitelist: ['dataSourceDeleting'] },
  );

export const createDataSourceSavingTransform = (
  logger: Logger = logdown('redux-persist:transform:dataSourceSaving'),
): Transform<DataSourceSavingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['dataSourceSaving'] },
  );

export const createDataSourceParameterEditingTransform = (
  logger: Logger = logdown('redux-persist:transform:dataSourceParameterEditing'),
): Transform<DataSourceParameterEditingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        parameter: subState.parameter.map(dataSourceParameterSerialize).orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        parameter: Maybe.fromUndefined(state.parameter).map(dataSourceParameterDeserialize),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['dataSourceParameterEditing'] },
  );
