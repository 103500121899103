import { Maybe } from 'monet';

import { DataSource } from '../../../datasources';
import { Action, ErrorAction } from '../../../redux/actions';
import { Template } from '../../../templates';
import { Company } from '../../../user';
import { ReportScheduleSettings, ReportParameter, ReportParameterInitFnType, ReportParameterInitValueType, Report } from '../../entities';

import { ResetReportsAction } from './reports.actions';

export enum ReportEditingActionType {
  START_REPORT_EDITING = 'START_REPORT_EDITING',
  EDIT_REPORT_NAME = 'EDIT_REPORT_NAME',
  EDIT_REPORT_COMPANY = 'EDIT_REPORT_COMPANY',
  EDIT_REPORT_DATA_SOURCE = 'EDIT_REPORT_DATA_SOURCE',
  EDIT_REPORT_TEMPLATE = 'EDIT_REPORT_TEMPLATE',
  EDIT_REPORT_TRANSFORMATION = 'EDIT_REPORT_TRANSFORMATION',
  TOGGLE_REPORT_TRANSFORMATION_COLLAPSE = 'TOGGLE_REPORT_TRANSFORMATION_COLLAPSE',
  TOGGLE_REPORT_SCHEDULE_SETTINGS = 'TOGGLE_REPORT_SCHEDULE_SETTINGS',
  EDIT_REPORT_SCHEDULE_SETTINGS = 'EDIT_REPORT_SCHEDULE_SETTINGS',
  ADD_REPORT_PARAMETER = 'ADD_REPORT_PARAMETER',
  DELETE_REPORT_PARAMETER = 'DELETE_REPORT_PARAMETER',
  COMPLETE_REPORT_EDITING = 'COMPLETE_REPORT_EDITING',

  REQUEST_REPORT_DATA_SOURCES = 'REQUEST_REPORT_DATA_SOURCES',
  RECEIVE_REPORT_DATA_SOURCES = 'RECEIVE_REPORT_DATA_SOURCES',
  RECEIVE_REPORT_DATA_SOURCES_ERROR = 'RECEIVE_REPORT_DATA_SOURCES_ERROR',

  REQUEST_REPORT_TEMPLATES = 'REQUEST_REPORT_TEMPLATES',
  RECEIVE_REPORT_TEMPLATES = 'RECEIVE_REPORT_TEMPLATES',
  RECEIVE_REPORT_TEMPLATES_ERROR = 'RECEIVE_REPORT_TEMPLATES_ERROR',

  EDIT_REPORT_PARAMETER_NAME = 'EDIT_REPORT_PARAMETER_NAME',
  EDIT_REPORT_PARAMETER_FUNC = 'EDIT_REPORT_PARAMETER_FUNC',
  EDIT_REPORT_PARAMETER_VALUE = 'EDIT_REPORT_PARAMETER_VALUE',

  START_REPORT_SAVING = 'START_REPORT_SAVING',
  DONE_REPORT_SAVING = 'DONE_REPORT_SAVING',
  FAIL_REPORT_SAVING = 'FAIL_REPORT_SAVING',

  START_REPORT_DELETING = 'START_REPORT_DELETING',
  DONE_REPORT_DELETING = 'DONE_REPORT_DELETING',
  FAIL_REPORT_DELETING = 'FAIL_REPORT_DELETING',
}

/**
 * Actions
 */

/**
 * Report Editing
 */

interface ReportStartEditingAction extends Action<ReportEditingActionType> {
  report: Maybe<Report>;
}

interface ReportEditNameAction extends Action<ReportEditingActionType> {
  name: string;
}

interface ReportEditCompanyAction extends Action<ReportEditingActionType> {
  company: Maybe<Company>;
}

interface ReportEditDataSourceAction extends Action<ReportEditingActionType> {
  reportDataSource: Maybe<DataSource>;
}

interface ReportEditTemplateAction extends Action<ReportEditingActionType> {
  template: Maybe<Template>;
}

interface ReportEditTransformationAction extends Action<ReportEditingActionType> {
  transformation: Maybe<string>;
}

type ReportToggleCollapseTransformationAction = Action<ReportEditingActionType>;

type ReportToggleScheduleSettingsAction = Action<ReportEditingActionType>;

interface ReportEditScheduleSettingsAction extends Action<ReportEditingActionType> {
  scheduleSettings: Maybe<ReportScheduleSettings>;
}

interface ReportParameterAddAction extends Action<ReportEditingActionType> {
  parameter: ReportParameter;
}

interface ReportParameterDeleteAction extends Action<ReportEditingActionType> {
  name: string;
}

interface ReportCompleteEditingAction extends Action<ReportEditingActionType> {
  report: Maybe<Report>;
}

export type ReportEditingAction =
  | (ReportStartEditingAction &
      ReportEditNameAction &
      ReportEditCompanyAction &
      ReportEditDataSourceAction &
      ReportEditTemplateAction &
      ReportEditTransformationAction &
      ReportToggleCollapseTransformationAction &
      ReportToggleScheduleSettingsAction &
      ReportEditScheduleSettingsAction &
      ReportParameterAddAction &
      ReportParameterEditValueAction &
      ReportParameterEditFuncAction &
      ReportParameterDeleteAction &
      ReportCompleteEditingAction)
  | ResetReportsAction;

/**
 * Report Data Sources Fetching
 */

interface ReportDataSourcesRequestAction extends Action<ReportEditingActionType> {
  filter: any;
}

interface ReportDataSourcesReceiveAction extends Action<ReportEditingActionType> {
  dataSources: DataSource[];
}

type ReportDataSourcesReceiveErrorAction = ErrorAction<ReportEditingActionType>;

export type ReportDataSourcesFetchAction =
  | (ReportDataSourcesRequestAction & ReportDataSourcesReceiveAction & ReportDataSourcesReceiveErrorAction)
  | ResetReportsAction;

/**
 * Report Templates Fetching
 */

interface ReportTemplatesRequestAction extends Action<ReportEditingActionType> {
  filter: any;
}

interface ReportTemplatesReceiveAction extends Action<ReportEditingActionType> {
  templates: Template[];
}

type ReportTemplatesReceiveErrorAction = ErrorAction<ReportEditingActionType>;

export type ReportTemplatesFetchActionObject =
  | (ReportTemplatesRequestAction & ReportTemplatesReceiveAction & ReportTemplatesReceiveErrorAction)
  | ResetReportsAction;

/**
 * Report Parameters Editing
 */

interface ReportParameterEditFuncAction extends Action<ReportEditingActionType> {
  name: string;
  initFn: Maybe<ReportParameterInitFnType>;
}

interface ReportParameterEditValueAction extends Action<ReportEditingActionType> {
  name: string;
  initValue: Maybe<ReportParameterInitValueType>;
}

/**
 * Report Saving
 */

type ReportStartSavingAction = Action<ReportEditingActionType>;

interface ReportDoneSavingAction extends Action<ReportEditingActionType> {
  report: Report;
}

type ReportFailSavingAction = ErrorAction<ReportEditingActionType>;

export type ReportSavingAction = (ReportStartSavingAction & ReportDoneSavingAction & ReportFailSavingAction) | ResetReportsAction;

/**
 * Report Deleting
 */

interface ReportStartDeletingAction extends Action<ReportEditingActionType> {
  reportId: string;
}

interface ReportDoneDeletingAction extends Action<ReportEditingActionType> {
  reportId: string;
}

interface ReportFailDeletingAction extends Action<ReportEditingActionType> {
  reportId: string;
  error: Maybe<Error>;
}

export type ReportDeletingAction = (ReportStartDeletingAction & ReportDoneDeletingAction & ReportFailDeletingAction) | ResetReportsAction;

/**
 * Action creators
 */

/**
 * Report editing
 */

export const reportStartEditing = (report: Maybe<Report>): ReportStartEditingAction => ({
  type: ReportEditingActionType.START_REPORT_EDITING,
  report,
});

export const reportEditName = (name: string): ReportEditNameAction => ({
  type: ReportEditingActionType.EDIT_REPORT_NAME,
  name,
});

export const reportEditCompany = (company: Maybe<Company>): ReportEditCompanyAction => ({
  type: ReportEditingActionType.EDIT_REPORT_COMPANY,
  company,
});

export const reportEditDataSource = (reportDataSource: Maybe<DataSource>): ReportEditDataSourceAction => ({
  type: ReportEditingActionType.EDIT_REPORT_DATA_SOURCE,
  reportDataSource,
});

export const reportEditTemplate = (template: Maybe<Template>): ReportEditTemplateAction => ({
  type: ReportEditingActionType.EDIT_REPORT_TEMPLATE,
  template,
});

export const reportEditTransformation = (transformation: Maybe<string>): ReportEditTransformationAction => ({
  type: ReportEditingActionType.EDIT_REPORT_TRANSFORMATION,
  transformation,
});

export const reportToggleTransformationCollapse = (): ReportToggleCollapseTransformationAction => ({
  type: ReportEditingActionType.TOGGLE_REPORT_TRANSFORMATION_COLLAPSE,
});

export const reportToggleScheduleSettings = (): ReportToggleScheduleSettingsAction => ({
  type: ReportEditingActionType.TOGGLE_REPORT_SCHEDULE_SETTINGS,
});

export const reportEditScheduleSettings = (scheduleSettings: Maybe<ReportScheduleSettings>): ReportEditScheduleSettingsAction => ({
  type: ReportEditingActionType.EDIT_REPORT_SCHEDULE_SETTINGS,
  scheduleSettings,
});

export const reportEditParameterAdd = (parameter: ReportParameter): ReportParameterAddAction => ({
  type: ReportEditingActionType.ADD_REPORT_PARAMETER,
  parameter,
});

export const reportEditParameterDelete = (name: string): ReportParameterDeleteAction => ({
  type: ReportEditingActionType.DELETE_REPORT_PARAMETER,
  name,
});

export const reportCompleteEditing = (report: Maybe<Report>): ReportCompleteEditingAction => ({
  type: ReportEditingActionType.COMPLETE_REPORT_EDITING,
  report,
});

/**
 * Report data source editing
 */

export const reportDataSourcesRequest = (filter: any): ReportDataSourcesRequestAction => ({
  type: ReportEditingActionType.REQUEST_REPORT_DATA_SOURCES,
  filter,
});

export const reportDataSourcesReceive = (dataSources: DataSource[]): ReportDataSourcesReceiveAction => ({
  type: ReportEditingActionType.RECEIVE_REPORT_DATA_SOURCES,
  dataSources,
});

export const reportDataSourcesReceiveError = (error: Error): ReportDataSourcesReceiveErrorAction => ({
  type: ReportEditingActionType.RECEIVE_REPORT_DATA_SOURCES_ERROR,
  error,
});

/**
 * Report template editing
 */

export const reportTemplatesRequest = (filter: any): ReportTemplatesRequestAction => ({
  type: ReportEditingActionType.REQUEST_REPORT_TEMPLATES,
  filter,
});

export const reportTemplatesReceive = (templates: Template[]): ReportTemplatesReceiveAction => ({
  type: ReportEditingActionType.RECEIVE_REPORT_TEMPLATES,
  templates,
});

export const reportTemplatesReceiveError = (error: Error): ReportTemplatesReceiveErrorAction => ({
  type: ReportEditingActionType.RECEIVE_REPORT_TEMPLATES_ERROR,
  error,
});

export const reportParameterEditFunc = (name: string, initFn: Maybe<ReportParameterInitFnType>): ReportParameterEditFuncAction => ({
  type: ReportEditingActionType.EDIT_REPORT_PARAMETER_FUNC,
  name,
  initFn,
});

export const reportParameterEditValue = (name: string, initValue: Maybe<ReportParameterInitValueType>): ReportParameterEditValueAction => ({
  type: ReportEditingActionType.EDIT_REPORT_PARAMETER_VALUE,
  name,
  initValue,
});

/**
 * Report saving
 */

export const reportStartSaving = (): ReportStartSavingAction => ({
  type: ReportEditingActionType.START_REPORT_SAVING,
});

export const reportDoneSaving = (report: Report): ReportDoneSavingAction => ({
  type: ReportEditingActionType.DONE_REPORT_SAVING,
  report,
});

export const reportFailSaving = (error: Error): ReportFailSavingAction => ({
  type: ReportEditingActionType.FAIL_REPORT_SAVING,
  error,
});

/**
 * Delete report
 */

export const reportStartDeleting = (reportId: string): ReportStartDeletingAction => ({
  type: ReportEditingActionType.START_REPORT_DELETING,
  reportId,
});

export const reportDoneDeleting = (reportId: string): ReportDoneDeletingAction => ({
  type: ReportEditingActionType.DONE_REPORT_DELETING,
  reportId,
});

export const reportFailDeleting = (reportId: string, error: Maybe<Error>): ReportFailDeletingAction => ({
  type: ReportEditingActionType.FAIL_REPORT_DELETING,
  reportId,
  error,
});
