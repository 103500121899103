import { Maybe } from 'monet';

import { ErrorAction, Action } from '../../../redux/actions';
import { ReportsListSort, Report, ReportsListFilter } from '../../entities';

export enum ReportsActionType {
  REQUEST_REPORTS = 'REQUEST_REPORTS',
  RECEIVE_REPORTS = 'RECEIVE_REPORTS',
  RECEIVE_REPORTS_ERROR = 'RECEIVE_REPORTS_ERROR',

  UPDATE_REPORT_LISTING_FILTER = 'UPDATE_REPORT_LISTING_FILTER',
  UPDATE_REPORT_SORT_LIST = 'UPDATE_REPORT_SORT_LIST',

  RESET_REPORTS = 'RESET_REPORTS',
}

/**
 * Actions
 */

interface ReportsRequestAction extends Action<ReportsActionType> {
  filter: Maybe<ReportsListFilter>;
  sort: Maybe<ReportsListSort>;
  sizePerPage: number;
}

interface ReportsReceiveAction extends Action<ReportsActionType> {
  reports: Report[];
  page: number;
  total: number;
}

type ReportsReceiveErrorAction = ErrorAction<ReportsActionType>;

interface ReportListingFilterUpdateAction extends Action<ReportsActionType> {
  filter: Maybe<ReportsListFilter>;
  page: number;
  sizePerPage: number;
}

interface ReportsListingSortAction extends Action<ReportsActionType> {
  sort: Maybe<ReportsListSort>;
}

export type ResetReportsAction = Action<ReportsActionType>;

export type ReportsAction = ReportsRequestAction &
  ReportsReceiveAction &
  ReportsReceiveErrorAction &
  ReportListingFilterUpdateAction &
  ReportsListingSortAction &
  ResetReportsAction;

/**
 * Action creators
 */

export const reportsRequest = (filter: Maybe<ReportsListFilter>, sizePerPage: number, sort: Maybe<ReportsListSort>): ReportsRequestAction => ({
  type: ReportsActionType.REQUEST_REPORTS,
  filter,
  sizePerPage,
  sort,
});

export const reportsReceive = (reports: Report[], page: number, total: number): ReportsReceiveAction => ({
  type: ReportsActionType.RECEIVE_REPORTS,
  reports,
  page,
  total,
});

export const reportsReceiveError = (error: Error): ReportsReceiveErrorAction => ({
  type: ReportsActionType.RECEIVE_REPORTS_ERROR,
  error,
});

export const reportListingUpdateFilter = (filter: Maybe<ReportsListFilter>, page: number, sizePerPage: number): ReportListingFilterUpdateAction => ({
  type: ReportsActionType.UPDATE_REPORT_LISTING_FILTER,
  filter,
  page,
  sizePerPage,
});

export const reportListingSortUpdate = (sort: Maybe<ReportsListSort>): ReportsListingSortAction => ({
  type: ReportsActionType.UPDATE_REPORT_SORT_LIST,
  sort,
});

export const reportsReset = (): ResetReportsAction => ({
  type: ReportsActionType.RESET_REPORTS,
});
