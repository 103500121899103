import logdown, { Logger } from 'logdown';
import { Maybe } from 'monet';
import { Dispatch } from 'react';

import { DataSource } from '../../../datasources';
import { Template } from '../../../templates';
import { Company } from '../../../user';
import { Report, ReportScheduleSettings, ReportParameter, ReportParameterInitFnType, ReportParameterInitValueType } from '../../entities';
import {
  reportStartEditing,
  reportEditName,
  reportEditCompany,
  reportEditDataSource,
  reportEditTemplate,
  reportEditTransformation,
  reportToggleTransformationCollapse,
  reportToggleScheduleSettings,
  reportEditScheduleSettings,
  reportEditParameterAdd,
  reportEditParameterDelete,
  reportCompleteEditing,
  reportParameterEditFunc,
  reportParameterEditValue,
} from '../../redux/actions';

export const startReportCloneEdit = (report: Maybe<Report> = Maybe.None()) => (dispatch: Dispatch<any>) => {
  dispatch(reportStartEditing(report.map((r) => ({ ...r, name: `clone-${r.name}`, id: '' }))));
};

export const startReportEdit = (report: Maybe<Report> = Maybe.None()) => (dispatch: Dispatch<any>) => {
  // const dataSourceParameters: ReportParameter[] = report
  //   .flatMap((r) => r.dataSource)
  //   .flatMap((ds) => ds.parameters)
  //   .orJust([])
  //   .filter((rp) => !rp.isOptional)
  //   .map((rp) => {
  //     return {
  //       name: rp.name,
  //       initFn: Maybe.None(),
  //       initValue: rp.default.map((dv) => ({
  //         type: typeof dv === 'number' ? DataSourceParameterType.INT : DataSourceParameterType.STRING,
  //         value: dv,
  //       })),
  //     } as ReportParameter;
  //   });
  // aLogger.log(
  //   `PARAMETERS ${JSON.stringify(dataSourceParameters, null, 2)} original:${JSON.stringify(
  //     report
  //       .flatMap((r) => r.dataSource)
  //       .flatMap((ds) => ds.parameters)
  //       .orJust([]),
  //     null,
  //     2,
  //   )}`,
  // );
  // dispatch(
  //   reportStartEditing(
  //     report.map((r) => ({
  //       ...r,
  //       parameters: r.parameters.map((p) => (p.length === 0 ? dataSourceParameters : p)),
  //     })),
  //   ),
  // );
  dispatch(reportStartEditing(report));
};

export const editReportName = (name: string) => (dispatch: Dispatch<any>) => {
  dispatch(reportEditName(name));
};

export const editReportCompany = (company: Maybe<Company>) => (dispatch: Dispatch<any>) => {
  dispatch(reportEditCompany(company));
};

export const editReportDataSource = (reportDataSource: Maybe<DataSource>) => (dispatch: Dispatch<any>) => {
  dispatch(reportEditDataSource(reportDataSource));
};

export const editReportTemplate = (template: Maybe<Template>) => (dispatch: Dispatch<any>) => {
  dispatch(reportEditTemplate(template));
};

export const editReportTransformation = (transformation: Maybe<string>) => (dispatch: Dispatch<any>) => {
  dispatch(reportEditTransformation(transformation));
};

export const toggleReportTransformationCollapse = () => (dispatch: Dispatch<any>) => {
  dispatch(reportToggleTransformationCollapse());
};
export const toggleReportScheduleSettings = () => (dispatch: Dispatch<any>) => {
  dispatch(reportToggleScheduleSettings());
};

export const editReportScheduleSettings = (
  scheduleSettings: Maybe<ReportScheduleSettings>,
  logger: Logger = logdown('reports:data:actions:editReportScheduleSettings'),
) => (dispatch: Dispatch<any>) => {
  logger.debug(`scheduleSettings => ${JSON.stringify(scheduleSettings)}`);
  dispatch(reportEditScheduleSettings(scheduleSettings));
};

export const editReportParameterAdd = (parameter: ReportParameter) => (dispatch: Dispatch<any>) => {
  dispatch(reportEditParameterAdd(parameter));
};

export const editReportParameterDelete = (name: string) => (dispatch: Dispatch<any>) => {
  dispatch(reportEditParameterDelete(name));
};

export const completeReportEdit = (report: Maybe<Report> = Maybe.None()) => (dispatch: Dispatch<any>) => {
  dispatch(reportCompleteEditing(report));
};

/**
 * Report parameter editing
 */

export const editReportParameterFunc = (name: string, initFn: Maybe<ReportParameterInitFnType>) => (dispatch: Dispatch<any>) => {
  dispatch(reportParameterEditFunc(name, initFn));
};

export const editReportParameterValue = (name: string, initValue: Maybe<ReportParameterInitValueType>) => (dispatch: Dispatch<any>) => {
  dispatch(reportParameterEditValue(name, initValue));
};
