import { DataSourceParameterType } from '@serverfarm/rs-commons';
import { Maybe } from 'monet';

import { IN_PROGRESS_INITIAL_STATE, PAGINATION_INITIAL_STATE } from '../../redux/constants';
import { DataSourceParameter } from '../entities';

import {
  DataSourceDeletingState,
  DataSourceEditingState,
  DataSourceParameterEditingState,
  DataSourceSavingState,
  DataSourcesListingState,
} from './entities';

const dataSourcesListingState: DataSourcesListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  dataSources: Maybe.None(),
  filter: Maybe.None(),
  sort: Maybe.None(),
};

const dataSourceEditingState: DataSourceEditingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  dataSource: Maybe.None(),
};

const dataSourceParameterEditingState: DataSourceParameterEditingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  parameter: Maybe.None(),
};
const dataSourceSavingState: DataSourceSavingState = IN_PROGRESS_INITIAL_STATE;

const dataSourceDeletingState: DataSourceDeletingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  dataSourceId: Maybe.None(),
};
export const dataSourceParameter = Maybe.Some<DataSourceParameter>({
  name: '',
  type: DataSourceParameterType.STRING,
  isOptional: false,
  default: Maybe.None(),
  options: Maybe.None(),
});

export const DATASOURCE_INITIAL_STATE = {
  dataSourcesListingState,
  dataSourceEditingState,
  dataSourceParameterEditingState,
  dataSourceSavingState,
  dataSourceDeletingState,
  dataSourceParameter,
};
