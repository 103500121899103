import moment from 'moment-timezone';
import { Maybe } from 'monet';
import React, { HTMLAttributes } from 'react';

export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const TIME_FORMAT_DEFAULT = 'HH:mm';
export const DATE_TIME_FORMAT_DEFAULT = `${DATE_FORMAT_DEFAULT} ${TIME_FORMAT_DEFAULT}`;

export interface DateTimeRenderProps extends HTMLAttributes<any> {
  date?: Maybe<Date> | Date | string;
  format?: string;
  timeZoneRef?: string;
}

export const DateTimeRender = (props: DateTimeRenderProps) => {
  const { date, format = DATE_TIME_FORMAT_DEFAULT, timeZoneRef, id, className } = props;
  const maybeDate = date && Maybe.isInstance(date) ? date : Maybe.fromUndefined(date);

  return (
    <span id={id} className={className}>
      {maybeDate
        .map((date) => {
          const momentDate = moment(date);
          return timeZoneRef ? momentDate.tz(timeZoneRef).format(format) : momentDate.format(format);
        })
        .getOrElse('N/A')}
    </span>
  );
};
