import logdown from 'logdown';
import React from 'react';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { RouteProps, RouterProps } from 'react-router';
import { ModalFooter, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import {
  fetchRenderedReportsHistory,
  updateRenderedReportsHistoryFilter,
  updateRenderedReportsHistorySort,
  copyToClipboard,
  closeRenderedReportsHistory,
} from './data/actions';
import { openRenderReportViewer } from './data/actions/renderedReportViewer.actions';
import RenderedReportHistoryList from './RenderedReportHistoryList';

const logger = logdown('component:RenderedReportHistory');

class Component extends React.Component<Props, {}> {
  public render() {
    logger.log(`Report ${this.props.renderedReportsHistory.report}`);
    return (
      <Modal
        id="rendered-report-history-modal"
        data-testid="rendered-report-history-modal"
        size="lg"
        isOpen={this.props.renderedReportsHistory.report.isSome()}
        toggle={this.cancel.bind(this)}
      >
        <ModalHeader data-testid="rendered-report-history-modal-header">
          <EllipsisText
            className="rendered-report-history-header"
            text={`${this.props.renderedReportsHistory.report.map((r) => r.name).orUndefined()} Rendered Report History`}
            length={45}
          />
        </ModalHeader>
        <ModalBody>
          <RenderedReportHistoryList {...this.props} />
        </ModalBody>
        <ModalFooter>
          <Button
            className="rendered-report-history-cancel-button"
            data-testid="rendered-report-history-modal-cancel-button"
            color="secondary"
            onClick={this.cancel.bind(this)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected cancel(e: any) {
    e.preventDefault();
    this.props.closeRenderedReportsHistory();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchRenderedReportsHistory,

      updateRenderedReportsHistoryFilter,
      updateRenderedReportsHistorySort,

      openRenderReportViewer,
      copyToClipboard,

      closeRenderedReportsHistory,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  renderedReportsHistory: state.renderedReportsHistory,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps & RouteProps & RouterProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
