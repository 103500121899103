import { ITemplatesListRequestQuery, ITemplateResponse, ITemplateRequest } from '@serverfarm/pointer-cod-api';
import { deserializeTemplateType, serializeTemplateType } from '@serverfarm/rs-commons';
import moment from 'moment';
import { Maybe, None } from 'monet';

import { Company } from '../../user';
import { TemplatesListFilter, Template, TemplatesListSort } from '../entities';

export const templatesListFilterSerialize = (
  filter: TemplatesListFilter = { created: None(), updated: None() },
  page: number,
  sizePerPage: number,
  sort: Maybe<TemplatesListSort>,
): ITemplatesListRequestQuery => {
  const templateFilter: ITemplatesListRequestQuery = {
    from: new Date(0).toISOString(),
    name: filter.name,
    companyIds: filter.companies,
    page: page + 1,
    limit: sizePerPage,
    sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
  };
  if (filter.created && filter.created.isSome()) {
    templateFilter.from = filter.created.map((created) => created.from.toISOString()).orUndefined();
    templateFilter.to = filter.created.map((created) => created.to.toISOString()).orUndefined();
  }
  if (filter.updated && filter.updated.isSome()) {
    templateFilter.fromUpdated = filter.updated.map((updated) => updated.from.toISOString()).orUndefined();
    templateFilter.toUpdated = filter.updated.map((updated) => updated.to.toISOString()).orUndefined();
  }
  return templateFilter;
};

export const templateSerialize = (t: Template): ITemplateRequest =>
  ({
    name: t.name,
    template: t.template,
    templateType: serializeTemplateType(t.templateType),
  } as ITemplateRequest);

export const templateDeserialize = (t: ITemplateResponse, companies: Maybe<Company[]>): Template => {
  const company = companies.flatMap((companies) => Maybe.fromUndefined(companies.find((c) => c.id === t.companyId)));
  if (company.isNone()) {
    throw new Error(`DataSource's company id (${t.companyId}) is not available to current user`);
  }
  return {
    id: t.id,
    name: t.name,
    company,
    template: t.template,
    templateType: deserializeTemplateType(t.templateType),
    updated: Maybe.fromUndefined(t.updated).map((d) => moment(d).toDate()),
    created: moment(t.created).toDate(),
  };
};
