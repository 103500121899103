import { Maybe } from 'monet';

import { InProgressState } from './actions';

export const IN_PROGRESS_INITIAL_STATE: InProgressState = {
  isInProgress: false,
  error: Maybe.None(),
};
export const PAGINATION_INITIAL_STATE = {
  page: 0,
  total: 0,
  sizePerPage: 10,
};
