/* eslint-disable no-underscore-dangle */

const env = (typeof window !== 'undefined' && (window as any).__ENV_VARS__) || (process && process.env) || {};

const {
  REACT_APP_NODE_DEBUG = '*',
  REACT_APP_OAUTH_CLIENT_URL = 'http://localhost:3002',
  REACT_APP_SERVICES_AUTH_API_URL = 'http://localhost:3002',
  REACT_APP_SERVICES_DACQS_API_URL = 'http://localhost:4000',
  REACT_APP_VERSION,
} = env;

export default {
  debug: REACT_APP_NODE_DEBUG,
  oauth: {
    client: {
      url: REACT_APP_OAUTH_CLIENT_URL,
    },
  },
  services: {
    auth: {
      api: {
        url: REACT_APP_SERVICES_AUTH_API_URL,
      },
    },
    dacqs: {
      api: {
        url: REACT_APP_SERVICES_DACQS_API_URL,
      },
    },
  },
  client: { version: REACT_APP_VERSION },
};
