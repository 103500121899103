import logdown, { Logger } from 'logdown';
import { Maybe } from 'monet';
import { Transform, createTransform } from 'redux-persist';

import { ReportsListingState } from '../entities';

import { reportSerialize, reportDeserialize } from './serdes';

export const createReportsListTransform = (logger: Logger = logdown('redux-persist:transform:reportsListing')): Transform<ReportsListingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const serialized = {
        ...subState,
        reports: subState.reports.map((reports) => reports.map(reportSerialize)).orUndefined(),
        sort: subState.sort.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(serialized, undefined, 4)}`);
      return serialized;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false, // start fresh
        reports: Maybe.fromUndefined(state.reports).map((reports) => reports.map(reportDeserialize)),
        sort: Maybe.fromUndefined(state.sort),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['reportsListing'] },
  );
