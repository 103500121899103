import { TemplateType } from '@serverfarm/rs-commons';
import { Maybe } from 'monet';

import { PAGINATION_INITIAL_STATE, IN_PROGRESS_INITIAL_STATE } from '../../redux/constants';
import { Template } from '../entities';

import { TemplateDeletingState, TemplateEditingState, TemplateSavingState, TemplatesListingState } from './entities';

const template: Template = {
  id: '',
  name: '',
  company: Maybe.None(),
  template: '',
  templateType: TemplateType.HTML,
  updated: Maybe.None(),
  created: new Date(),
};

const templateDeletingState: TemplateDeletingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  templateId: Maybe.None(),
};

const templateEditingState: TemplateEditingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  template: Maybe.None(),
};

const templateSavingState: TemplateSavingState = {
  ...IN_PROGRESS_INITIAL_STATE,
};

const templatesListingState: TemplatesListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  filter: Maybe.None(),
  templates: Maybe.None(),
  sort: Maybe.None(),
};

export const TEMPLATES_INITIAL_STATE = {
  template,
  templateDeletingState,
  templateEditingState,
  templateSavingState,
  templatesListingState,
};
