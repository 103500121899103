import logdown from 'logdown';
import { Maybe, Some, None } from 'monet';
import pluralize from 'pluralize';
import * as React from 'react';
// @ts-ignore
import * as Icon from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Button, Spinner, UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { UserRole } from '../../auth/entities';
import { State } from '../../reducers';
import { UserState, CompaniesListingState } from '../user';
import { withClickConfirmation, DateTimeRender } from '../utils';
import { DateRangeFilter, DateRange } from '../utils/dateRangeFilter';
import { ServerfarmTable } from '../utils/serverfarmTable';
import { TextFilter } from '../utils/textFilter';

import {
  cloneDataSource,
  deleteDataSource,
  editDataSource,
  executeDataSource,
  fetchDataSources,
  updateDataSourceListingFilter,
  updateDataSourceListingSort,
} from './data/actions';
import { DataSource, DataSourcesListSort } from './entities';
import { DataSourcesListingState, DataSourceDeletingState } from './redux/entities';

const logger = logdown('component:DataSourcesList');

const ButtonWithConfirmation = withClickConfirmation(Button);

class NameFilter extends React.Component<Props & DataSourceListsProps> {
  shouldComponentUpdate(nextProps: Props & DataSourceListsProps) {
    const isIsInProgressChanged = nextProps.dataSourcesListing.isInProgress !== this.props.dataSourcesListing.isInProgress;
    const isTotalChanged = nextProps.dataSourcesListing.total !== this.props.dataSourcesListing.total;
    const shouldChange = isIsInProgressChanged || isTotalChanged;
    return shouldChange;
  }

  render() {
    return (
      <TextFilter
        placeholder={
          this.props.dataSourcesListing.isInProgress
            ? 'Loading...'
            : `Search among ${this.props.dataSourcesListing.total} ${pluralize('record', this.props.dataSourcesListing.total)}...`
        }
        isSearching={this.props.dataSourcesListing.isInProgress}
        onChange={(val) => {
          /**
           * Only set value if its changed. Consider '' === undefined
           */
          const valueToSet = val === '' || val === undefined ? undefined : val;
          if (this.props.dataSourcesListing.filter.exists((filter) => filter.name !== valueToSet)) {
            this.props.updateDataSourceListingFilter(
              this.props.dataSourcesListing.filter.map((filter) => ({ ...filter, name: valueToSet })),
              this.props.dataSourcesListing.page,
              this.props.dataSourcesListing.sizePerPage,
            );
          }
        }}
      />
    );
  }
}

class ActionsFormatter extends React.Component<Props & DataSourceListsProps & { dataSource: any }> {
  shouldComponentUpdate(nextProps: Props & DataSourceListsProps & { dataSource: any }) {
    const isDataSourceUpdated = nextProps.dataSource.id !== this.props.dataSource.id;
    const isDeletingInProgressChanged = nextProps.dataSourceDeleting.isInProgress !== this.props.dataSourceDeleting.isInProgress;

    const shouldChange = isDataSourceUpdated || isDeletingInProgressChanged;
    return shouldChange;
  }

  render() {
    return (
      <span>
        <Button
          data-testid="execute-button"
          id={`execute-button-${this.props.dataSource.id}`}
          color="info"
          size="sm"
          className="execute-button"
          onClick={() => this.props.executeDataSource(this.props.dataSource.id)}
        >
          <Icon.LightningFill className="sm" />
        </Button>{' '}
        <Button
          data-testid="edit-button"
          id={`edit-button-${this.props.dataSource.id}`}
          color="success"
          size="sm"
          className="edit-button"
          onClick={() => this.props.editDataSource(Maybe.Some(this.props.dataSource))}
        >
          <Icon.PencilSquare className="sm" />
        </Button>{' '}
        <Button
          data-testid="clone-button"
          id={`clone-button-${this.props.dataSource.id}`}
          color="primary"
          size="sm"
          className="clone-button"
          onClick={() => this.props.cloneDataSource(Maybe.Some(this.props.dataSource))}
        >
          <Icon.Files className="sm" />
        </Button>{' '}
        <ButtonWithConfirmation
          data-testid="delete-button"
          id={`delete-button-${this.props.dataSource.id}`}
          color="danger"
          size="sm"
          className="delete-button"
          disabled={this.props.dataSourceDeleting.isInProgress && this.props.dataSourceDeleting.dataSourceId.contains(this.props.dataSource.id)}
          onClick={() => this.props.deleteDataSource(this.props.dataSource.id)}
          popoverCaption="Delete DataSource?"
          popoverOkButton="Yes"
          popoverCancelButton="No"
        >
          {this.props.dataSourceDeleting.isInProgress && this.props.dataSourceDeleting.dataSourceId.contains(this.props.dataSource.id) ? (
            <Spinner size="sm" />
          ) : (
            <Icon.TrashFill className="sm" />
          )}
        </ButtonWithConfirmation>
        <UncontrolledTooltip target={`execute-button-${this.props.dataSource.id}`}>Execute</UncontrolledTooltip>{' '}
        <UncontrolledTooltip target={`edit-button-${this.props.dataSource.id}`}>Edit</UncontrolledTooltip>{' '}
        <UncontrolledTooltip target={`clone-button-${this.props.dataSource.id}`}>Clone</UncontrolledTooltip>{' '}
        <UncontrolledTooltip target={`delete-button-${this.props.dataSource.id}`}>Delete</UncontrolledTooltip>{' '}
      </span>
    );
  }
}

const SelectColumnFilter = (props: Props & DataSourceListsProps) => (params: any) => {
  const selectedCompanyIds = props.dataSourcesListing.filter
    .map((dsf) => {
      return dsf.companies || [];
    })
    .orJust([]);
  return (
    <Typeahead
      multiple={true}
      data-testid="data-source-filter-company"
      id="data-source-filter-company"
      isLoading={props.companiesListing.isInProgress}
      onChange={(selected) => {
        const filter = props.dataSourcesListing.filter.map((filter) => ({
          ...filter,
          companies: selected.map((c) => c.id.toString()),
        }));
        props.updateDataSourceListingFilter(filter, props.dataSourcesListing.page, props.dataSourcesListing.sizePerPage);
      }}
      options={props.companiesListing.companies.getOrElse([])}
      emptyLabel="No Companies found"
      placeholder="Select a company"
      defaultSelected={props.companiesListing.companies.map((carr) => carr.filter((c) => selectedCompanyIds.includes(c.id.toString()))).getOrElse([])}
      labelKey="name"
      disabled={props.dataSourcesListing.isInProgress}
    />
  );
};

const CreatedDateRangeFilter = (props: Props & { dataSourcesListing: DataSourcesListingState }) => (
  <DateRangeFilter
    id="date-range-filter-created"
    value={props.dataSourcesListing.filter.flatMap((filter) => filter.created)}
    onReset={() => {
      props.updateDataSourceListingFilter(
        props.dataSourcesListing.filter.map((filter) => ({
          ...filter,
          created: None(),
        })),
        props.dataSourcesListing.page,
        props.dataSourcesListing.sizePerPage,
      );
    }}
    onSave={(range: DateRange) => {
      props.updateDataSourceListingFilter(
        props.dataSourcesListing.filter.map((filter) => ({
          ...filter,
          created: Some(range),
        })),
        props.dataSourcesListing.page,
        props.dataSourcesListing.sizePerPage,
      );
    }}
  />
);

const UpdatedDateRangeFilter = (props: Props & { dataSourcesListing: DataSourcesListingState }) => (
  <DateRangeFilter
    id="date-range-filter-updated"
    value={props.dataSourcesListing.filter.flatMap((filter) => filter.updated)}
    onReset={() => {
      props.updateDataSourceListingFilter(
        props.dataSourcesListing.filter.map((filter) => ({
          ...filter,
          updated: None(),
        })),
        props.dataSourcesListing.page,
        props.dataSourcesListing.sizePerPage,
      );
    }}
    onSave={(range: DateRange) => {
      props.updateDataSourceListingFilter(
        props.dataSourcesListing.filter.map((filter) => ({
          ...filter,
          updated: Some(range),
        })),
        props.dataSourcesListing.page,
        props.dataSourcesListing.sizePerPage,
      );
    }}
  />
);
const columns = (props: Props & DataSourceListsProps) => [
  {
    id: 'name',
    width: 'auto',
    Header: 'Name',
    accessor: (row: DataSource) => {
      const className = 'data-sources-list-item-name';
      return (
        <span id={`${className}-${row.id}`} className={className}>
          {row.name}
        </span>
      );
    },
    Filter: <NameFilter {...props} />,
  },
  {
    width: 200,
    Header: 'Company',
    accessor: (row: DataSource) => {
      const className = 'data-sources-list-item-company';
      return (
        <span id={`${className}-${row.id}`} className={className}>
          {row.company.map((c) => c.name).getOrElse('N/A')}
        </span>
      );
    },
    // Cell: (cell: any) => renderCompanyColumn(props, cell),
    Filter: SelectColumnFilter(props),
    disableFilters: props.user.profile.role.equals(Some(UserRole.COMMON_USER)),
    disableSortBy: true,
  },
  {
    id: 'created',
    width: 200,
    Header: 'Created',
    accessor: (row: DataSource) => {
      const className = 'data-sources-list-item-created';
      return <DateTimeRender id={`${className}-${row.id}`} className={className} date={row.created} timeZoneRef={props.user.profile.timeZoneRef} />;
    },
    Filter: CreatedDateRangeFilter(props),
  },
  {
    id: 'updated',
    width: 200,
    Header: 'Updated',
    accessor: (row: DataSource) => {
      const className = 'data-sources-list-item-updated';
      return <DateTimeRender id={`${className}-${row.id}`} className={className} date={row.updated} timeZoneRef={props.user.profile.timeZoneRef} />;
    },
    Filter: UpdatedDateRangeFilter(props),
  },
  {
    width: 200,
    Header: 'Actions',
    accessor: (row: DataSource) => <ActionsFormatter {...props} dataSource={row} />,
    disableFilters: true,
    disableSortBy: true,
  },
];

interface DataSourceListsProps {
  user: UserState;
  dataSourcesListing: DataSourcesListingState;
  dataSourceDeleting: DataSourceDeletingState;
  companiesListing: CompaniesListingState;
}

class Component extends React.Component<Props & DataSourceListsProps, {}> {
  componentDidMount() {
    const filter = Some({ created: None<DateRange>(), updated: None<DateRange>() });
    this.props.updateDataSourceListingFilter(filter, this.props.dataSourcesListing.page, this.props.dataSourcesListing.sizePerPage);
  }

  componentDidUpdate(prevProps: Props & DataSourceListsProps) {
    const isFilterUpdated = !prevProps.dataSourcesListing.filter.equals(this.props.dataSourcesListing.filter);
    const isSortingUpdated = !prevProps.dataSourcesListing.sort.equals(this.props.dataSourcesListing.sort);
    const isPageSizeUpdated = !(prevProps.dataSourcesListing.sizePerPage === this.props.dataSourcesListing.sizePerPage);
    const isPageChanged = !(prevProps.dataSourcesListing.page === this.props.dataSourcesListing.page);
    if (isSortingUpdated || isFilterUpdated || isPageSizeUpdated || isPageChanged) {
      this.props.fetchDataSources(
        this.props.dataSourcesListing.filter,
        this.props.dataSourcesListing.page,
        this.props.dataSourcesListing.sizePerPage,
        this.props.dataSourcesListing.sort,
      );
      return;
    }
  }

  public render() {
    const pageCount = Math.floor(this.props.dataSourcesListing.total / this.props.dataSourcesListing.sizePerPage) + 1;
    return (
      <div className="content">
        <ServerfarmTable
          columns={columns(this.props)}
          data={this.props.dataSourcesListing.dataSources.orJust([])}
          onPageChange={(pageIndex, pageSize) => {
            if (this.props.dataSourcesListing.page !== pageIndex || this.props.dataSourcesListing.sizePerPage !== pageSize) {
              this.props.updateDataSourceListingFilter(this.props.dataSourcesListing.filter, pageIndex, pageSize);
            }
          }}
          onSortChange={(sortBy) => {
            const newSort: Maybe<DataSourcesListSort> =
              sortBy.length > 0 ? Some({ field: sortBy[0].id, order: sortBy[0].desc ? 'desc' : 'asc' }) : None();
            this.props.dataSourcesListing.sort.equals(newSort);
            if (!this.props.dataSourcesListing.sort.equals(newSort)) {
              this.props.updateDataSourceListingSort(newSort);
            }
          }}
          total={this.props.dataSourcesListing.total}
          controlledPageCount={pageCount}
          initialPaginationState={{
            pageIndex: this.props.dataSourcesListing.page,
            pageSize: this.props.dataSourcesListing.sizePerPage,
            sortBy: this.props.dataSourcesListing.sort.map((s) => [{ id: s.field, desc: s.order === 'desc' }]).orJust([]),
          }}
          isLoading={this.props.dataSourcesListing.isInProgress}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchDataSources,
      cloneDataSource,
      deleteDataSource,
      editDataSource,
      executeDataSource,
      updateDataSourceListingFilter,
      updateDataSourceListingSort,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, any>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
