import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';

import useDebounce from './useDebounce';

export type onTextFilterChange = (value?: string) => void;

export interface TextFilterProps {
  placeholder: string;
  isSearching: boolean;
  onChange: onTextFilterChange;
  debounce?: number;
  minLength?: number;
  initialState?: string;
}

export const TextFilter = (props: TextFilterProps) => {
  const [searchTerm, setSearchTerm] = useState(props.initialState);
  const debouncedSearchTerm = useDebounce(searchTerm, props.debounce || 500);
  useEffect(() => {
    props.onChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <Input
      className="text-filter"
      type="text"
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder={props.placeholder}
      disabled={props.isSearching}
    />
  );
};
