import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router';
import { Spinner } from 'reactstrap';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { userIsAuthenticated, userIsNotAuthenticated } from './auth/helpers';
import { Dashboard } from './components/dashboard';
import { Notifications } from './components/notification';
import { Login } from './components/user';
import config from './config';
import { setupVersionCheckInterceptor, setupTokenProviderInterceptor } from './services/axios';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// eslint-disable-next-line import/no-unresolved
import './assets/scss/paper-dashboard.scss?v=1.1.0';
import './index.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import store from './store';

window.localStorage.debug = config.debug;

setupTokenProviderInterceptor(store);
setupVersionCheckInterceptor(store);

const hist = createBrowserHistory();

const persistor = persistStore(store);

const ProtectedDashboard = userIsAuthenticated(Dashboard);
const RedirectedLogin = userIsNotAuthenticated(Login);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Spinner />} persistor={persistor}>
      <Notifications />
      <Router history={hist}>
        <Switch>
          <Route path="/dashboard" component={ProtectedDashboard} />
          <Route path="/login" component={RedirectedLogin} />
          <Redirect to="/dashboard" />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
