import { ITemplatesListResponse } from '@serverfarm/pointer-cod-api';
import { TemplateType } from '@serverfarm/rs-commons';
import logdown, { Logger } from 'logdown';
import { Maybe, Some, None } from 'monet';
import { Dispatch } from 'redux';

import config from '../../../config';
import { State } from '../../../reducers';
import axios from '../../../services/axios';
import { showErrorNotification } from '../../notification';
import { Company } from '../../user';
import { Template, TemplatesListSort, TemplatesListFilter } from '../entities';
import {
  templateCompleteEditing,
  templateDoneDeleting,
  templateDoneSaving,
  templateEditCompany,
  templateEditName,
  templateEditTemplate,
  templateEditTemplateType,
  templateFailDeleting,
  templateFailSaving,
  templateListingUpdateFilter,
  templatesReceive,
  templatesReceiveError,
  templatesRequest,
  templateStartDeleting,
  templateStartEditing,
  templateStartSaving,
  templateListingSortUpdate,
  templatesReset,
} from '../redux/actions';

import { templatesListFilterSerialize, templateDeserialize, templateSerialize } from './serdes';

export const cloneTemplate = (template: Template) => (dispatch: Dispatch<any>) => {
  dispatch(templateStartEditing(Some({ ...template, name: `clone-${template.name}`, id: '' })));
};

export const editTemplate = (template: Maybe<Template>) => (dispatch: Dispatch<any>) => {
  dispatch(templateStartEditing(template));
};

export const editTemplateName = (name: string) => (dispatch: Dispatch<any>) => {
  dispatch(templateEditName(name));
};

export const editTemplateCompany = (company: Maybe<Company>) => (dispatch: Dispatch<any>) => {
  dispatch(templateEditCompany(company));
};

export const editTemplateTemplate = (template: string) => (dispatch: Dispatch<any>) => {
  dispatch(templateEditTemplate(template));
};

export const editTemplateTemplateType = (templateType: TemplateType) => (dispatch: Dispatch<any>) => {
  dispatch(templateEditTemplateType(templateType));
};

export const completeTemplateEdit = (template: Maybe<Template> = Maybe.None()) => (dispatch: Dispatch<any>) => {
  dispatch(templateCompleteEditing(template));
};

export const fetchTemplates = (
  filter: Maybe<TemplatesListFilter>,
  page: number,
  sizePerPage: number,
  sort: Maybe<TemplatesListSort>,
  logger: Logger = logdown('templates:data:actions:fetchTemplates'),
) => (dispatch: Dispatch<any>, getState: () => State) => {
  dispatch(templatesRequest(filter, sizePerPage, sort));

  const params = templatesListFilterSerialize(filter.getOrElse({ created: None(), updated: None() }), page, sizePerPage, sort);

  logger.log(`Templates lookup filter ${JSON.stringify(params, undefined, 4)}`);

  const companies = getState().companiesListing.companies;

  axios({
    method: 'get',
    url: `${config.services.dacqs.api.url}/template/list`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params,
  })
    .then((result) => result.data as ITemplatesListResponse)
    .then((templatesData) => {
      dispatch(
        templatesReceive(
          templatesData.templates.map((t) => templateDeserialize(t, companies)),
          page,
          templatesData.total,
        ),
      );
    })
    .catch((error) => {
      dispatch(templatesReceiveError(error));
      showErrorNotification(error)(dispatch);
    });
};

// Save Template

export const saveTemplate = (template: Template, createNew = false, logger: Logger = logdown('templates:data:actions:saveTemplate')) => (
  dispatch: Dispatch<any>,
) => {
  const templateApiEntity = templateSerialize(template);
  logger.log(`templateApiEntity => ${JSON.stringify(templateApiEntity)}`);
  dispatch(templateStartSaving());
  const endpointIdSuffix = createNew ? '' : `/${template.id}`;
  axios({
    method: createNew ? 'post' : 'put',
    url: `${config.services.dacqs.api.url}/template${endpointIdSuffix}?companyId=${template.company.map((c) => c.id).orUndefined()}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      ...templateApiEntity,
    },
  })
    .then((response) => response.data)
    .then((savedTemplate) => {
      dispatch(templateDoneSaving(template));
      dispatch(templateCompleteEditing(Maybe.Some(template)));
    })
    .catch((error) => {
      dispatch(templateFailSaving(error));
      showErrorNotification(error)(dispatch);
    });
};

// Delete Template

export const deleteTemplate = (templateId: string, logger: Logger = logdown('templates:data:actions:deleteTemplate')) => (
  dispatch: Dispatch<any>,
) => {
  logger.log(`templateId => ${templateId}`);
  dispatch(templateStartDeleting(templateId));
  axios({
    method: 'delete',
    url: `${config.services.dacqs.api.url}/template/${templateId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(() => {
      dispatch(templateDoneDeleting(templateId));
    })
    .catch((error) => {
      dispatch(templateFailDeleting(templateId, Maybe.Some(error)));
      showErrorNotification(error)(dispatch);
    });
};

// Update Templates List Filter

export const updateTemplateListingFilter = (
  filter: Maybe<TemplatesListFilter>,
  page: number,
  sizePerPage = 10,
  logger: Logger = logdown('templates:data:actions:updateTemplateListingFilter'),
) => (dispatch: Dispatch<any>) => {
  logger.log(`filter => ${JSON.stringify(filter.orUndefined())}`);
  dispatch(templateListingUpdateFilter(filter, page, sizePerPage));
};

export const updateTemplateListingPages = (
  page: number,
  sizePerPage = 10,
  logger: Logger = logdown('templates:data:actions:updateTemplateListingFilter'),
) => (dispatch: Dispatch<any>, getState: any) => {
  const state: State = getState();
  dispatch(
    templateListingUpdateFilter(
      Maybe.fromUndefined(state.templatesListing.filter.getOrElse({ created: None(), updated: None() })),
      page,
      sizePerPage,
    ),
  );
};

export const updateTemplateListingSort = (
  listingSort: Maybe<TemplatesListSort>,
  logger: Logger = logdown('templates:data:actions:updateTemplateListingSort'),
) => (dispatch: Dispatch<any>) => {
  logger.log(`listingSort => ${listingSort.orUndefined()}`);
  dispatch(templateListingSortUpdate(listingSort));
};

export const resetTemplates = (logger: Logger = logdown('templates:data:actions:resetTemplates')) => (dispatch: Dispatch<any>) => {
  dispatch(templatesReset());
};
