import { Maybe } from 'monet';
import { Reducer } from 'redux';

import { RenderReportViewerAction, RenderedReportViewerActionType } from '../actions/renderedReportViewer.actions';
import { RenderedReportViewerState } from '../entities/renderReportViewer.entities';

export const renderedReportViewerReducer: Reducer<RenderedReportViewerState, RenderReportViewerAction> = (
  state = { isInProgress: false, renderedReport: Maybe.None(), report: Maybe.None(), error: Maybe.None() },
  action,
) => {
  switch (action.type) {
    case RenderedReportViewerActionType.OPEN_RENDERED_REPORT_VIEWER:
      return {
        isInProgress: true,
        error: Maybe.None(),
        renderedReport: action.renderedReport,
        report: action.report,
      };
    case RenderedReportViewerActionType.CLOSE_RENDERED_REPORT_VIEWER:
      return {
        isInProgress: false,
        error: Maybe.None(),
        renderedReport: Maybe.None(),
        report: Maybe.None(),
      };
    default:
      return state;
  }
};
