import { Maybe, Some, None } from 'monet';

import { Action } from '../../../redux/actions';
import { Report, RenderedReport, RenderedReportsHistorySort, RenderedReportsHistoryFilter } from '../../entities';

import { ResetReportsAction } from './reports.actions';

export enum RenderedReportsHistoryActionType {
  OPEN_RENDERED_REPORTS_HISTORY = 'OPEN_RENDERED_REPORTS_HISTORY',
  CLOSE_RENDERED_REPORTS_HISTORY = 'CLOSE_RENDERED_REPORTS_HISTORY',
  REQUEST_RENDERED_REPORTS_HISTORY = 'REQUEST_RENDERED_REPORTS_HISTORY',
  RECEIVE_RENDERED_REPORTS_HISTORY = 'RECEIVE_RENDERED_REPORTS_HISTORY',
  RECEIVE_RENDERED_REPORTS_HISTORY_ERROR = 'RECEIVE_RENDERED_REPORTS_HISTORY_ERROR',
  UPDATE_RENDERED_REPORTS_HISTORY_FILTER = 'UPDATE_RENDERED_REPORTS_HISTORY_FILTER',
  UPDATE_RENDERED_REPORTS_HISTORY_SORT = 'UPDATE_RENDERED_REPORTS_HISTORY_SORT',
}

/**
 * Actions
 */

interface RenderedReportsHistoryToggleAction extends Action<RenderedReportsHistoryActionType> {
  report: Maybe<Report>;
}

interface RenderedReportsHistoryRequestAction extends Action<RenderedReportsHistoryActionType> {
  sizePerPage: number;
  filter: Maybe<RenderedReportsHistoryFilter>;
  sort: Maybe<RenderedReportsHistorySort>;
}

interface RenderedReportsHistoryReceiveAction extends Action<RenderedReportsHistoryActionType> {
  renderedReports: RenderedReport[];
  page: number;
  total: number;
}

interface RenderedReportsHistoryReceiveErrorAction extends Action<RenderedReportsHistoryActionType> {
  error: Error;
}

interface RenderedReportsHistoryFilterUpdateAction extends Action<RenderedReportsHistoryActionType> {
  filter: Maybe<RenderedReportsHistoryFilter>;
  page: number;
  sizePerPage: number;
}

interface RenderedReportsHistorySortUpdateAction extends Action<RenderedReportsHistoryActionType> {
  sort: Maybe<RenderedReportsHistorySort>;
}

export type RenderedReportsHistoryAction =
  | (RenderedReportsHistoryToggleAction &
      RenderedReportsHistoryFilterUpdateAction &
      RenderedReportsHistorySortUpdateAction &
      RenderedReportsHistoryRequestAction &
      RenderedReportsHistoryReceiveAction &
      RenderedReportsHistoryReceiveErrorAction)
  | ResetReportsAction;

/**
 * Action creators
 */

export const renderedReportsHistoryOpen = (report: Report): RenderedReportsHistoryToggleAction => ({
  type: RenderedReportsHistoryActionType.OPEN_RENDERED_REPORTS_HISTORY,
  report: Some(report),
});

export const renderedReportsHistoryClose = (): RenderedReportsHistoryToggleAction => ({
  type: RenderedReportsHistoryActionType.CLOSE_RENDERED_REPORTS_HISTORY,
  report: None(),
});

export const renderedReportsHistoryFilterUpdate = (
  filter: Maybe<RenderedReportsHistoryFilter>,
  page: number,
  sizePerPage: number,
): RenderedReportsHistoryFilterUpdateAction => ({
  type: RenderedReportsHistoryActionType.UPDATE_RENDERED_REPORTS_HISTORY_FILTER,
  filter,
  page,
  sizePerPage,
});

export const renderedReportsHistorySortUpdate = (sort: Maybe<RenderedReportsHistorySort>): RenderedReportsHistorySortUpdateAction => ({
  type: RenderedReportsHistoryActionType.UPDATE_RENDERED_REPORTS_HISTORY_SORT,
  sort,
});

export const renderedReportsHistoryRequest = (
  filter: Maybe<RenderedReportsHistoryFilter>,
  sort: Maybe<RenderedReportsHistorySort>,
  sizePerPage: number,
): RenderedReportsHistoryRequestAction => ({
  type: RenderedReportsHistoryActionType.REQUEST_RENDERED_REPORTS_HISTORY,
  filter,
  sort,
  sizePerPage,
});

export const renderedReportsHistoryReceive = (
  renderedReports: RenderedReport[],
  page: number,
  total: number,
): RenderedReportsHistoryReceiveAction => ({
  type: RenderedReportsHistoryActionType.RECEIVE_RENDERED_REPORTS_HISTORY,
  renderedReports,
  page,
  total,
});

export const renderedReportsHistoryReceiveError = (error: Error): RenderedReportsHistoryReceiveErrorAction => ({
  type: RenderedReportsHistoryActionType.RECEIVE_RENDERED_REPORTS_HISTORY_ERROR,
  error,
});
