import { Maybe } from 'monet';
import React from 'react';

const YES = 'Yes';
const NO = 'No';

export interface YesNoProps {
  value: Maybe<any>;
}

export const YesNo = (props: YesNoProps) => {
  const { value } = props;
  return (
    <span>
      {value
        .map((v) => !!v)
        .map((isYes) => (isYes ? YES : NO))
        .getOrElse(NO)}
    </span>
  );
};
