import { Maybe, Some, None } from 'monet';
import { Reducer } from 'redux';

import { ReportDeliverType } from '../../entities';
import {
  ReportEditingAction,
  ReportEditingActionType,
  ReportSavingAction,
  ReportDataSourcesFetchAction,
  ReportTemplatesFetchActionObject,
  ReportDeletingAction,
} from '../actions/reportEditing.actions';
import { ReportsActionType } from '../actions/reports.actions';
import { INITIAL_REPORTS_STATE } from '../constants';
import { ReportEditingState, ReportSavingState, ReportDataSourcesListingState, ReportTemplatesListingState, ReportDeletingState } from '../entities';

export const reportEditingReducer: Reducer<ReportEditingState, ReportEditingAction> = (state = INITIAL_REPORTS_STATE.reportEditingState, action) => {
  switch (action.type) {
    case ReportEditingActionType.START_REPORT_EDITING:
      return {
        ...state,
        isInProgress: true,
        isEnableScheduling: action.report.flatMap((r) => r.scheduleSettings).isSome(),
        report: action.report.orElse(Some(INITIAL_REPORTS_STATE.report)),
        error: None(),
      };
    case ReportEditingActionType.EDIT_REPORT_NAME:
      return {
        ...state,
        report: state.report.map((t) => ({ ...t, name: action.name })),
      };
    case ReportEditingActionType.EDIT_REPORT_COMPANY:
      return {
        ...state,
        report: state.report.map((r) => ({
          ...r,
          company: action.company,
          dataSource: None(),
          template: None(),
        })),
      };
    case ReportEditingActionType.EDIT_REPORT_DATA_SOURCE:
      return {
        ...state,
        report: state.report.map((r) => ({ ...r, dataSource: action.reportDataSource })),
      };
    case ReportEditingActionType.EDIT_REPORT_TEMPLATE:
      return {
        ...state,
        report: state.report.map((r) => ({ ...r, template: action.template })),
      };
    case ReportEditingActionType.EDIT_REPORT_TRANSFORMATION:
      return {
        ...state,
        report: state.report.map((r) => ({ ...r, transformation: action.transformation })),
      };
    case ReportEditingActionType.TOGGLE_REPORT_TRANSFORMATION_COLLAPSE:
      return {
        ...state,
        isTransformationCollapsed: !state.isTransformationCollapsed,
      };
    case ReportEditingActionType.TOGGLE_REPORT_SCHEDULE_SETTINGS:
      const isEnableScheduling = !state.isEnableScheduling;
      return {
        ...state,
        isEnableScheduling,
        report: state.report.map((report) => ({
          ...report,
          scheduleSettings: isEnableScheduling
            ? Some({
                schedule: {
                  recurring: {
                    cron: '',
                  },
                },
                deliver: [
                  {
                    deliverType: ReportDeliverType.EMAIL,
                    email: '',
                  },
                ],
              })
            : None(),
        })),
      };
    case ReportEditingActionType.EDIT_REPORT_SCHEDULE_SETTINGS:
      return {
        ...state,
        report: state.report.map((r) => ({ ...r, scheduleSettings: action.scheduleSettings })),
      };
    case ReportEditingActionType.ADD_REPORT_PARAMETER:
      return {
        ...state,
        report: state.report.map((report) => ({
          ...report,
          parameters: report.parameters
            .orElse(Some([]))
            .map((parameters) => (!parameters.find((p) => p.name === action.parameter.name) ? [...parameters, action.parameter] : parameters)),
        })),
      };
    case ReportEditingActionType.EDIT_REPORT_PARAMETER_VALUE:
      return {
        ...state,
        report: state.report.map((report) => ({
          ...report,
          parameters: report.parameters
            .orElse(Some([]))
            .map((parameters) => ({
              parameters,
              parameter: parameters.find((p) => p.name === action.name),
            }))
            .map(({ parameters, parameter }) => {
              if (parameter !== undefined) {
                parameters.splice(parameters.indexOf(parameter), 1, {
                  ...parameter,
                  initValue: action.initValue,
                  initFn: None(),
                });
              }
              return parameters;
            }),
        })),
      };
    case ReportEditingActionType.EDIT_REPORT_PARAMETER_FUNC:
      return {
        ...state,
        report: state.report.map((report) => ({
          ...report,
          parameters: report.parameters
            .orElse(Some([]))
            .map((parameters) => ({
              parameters,
              parameter: parameters.find((p) => p.name === action.name),
            }))
            .map(({ parameters, parameter }) => {
              if (parameter !== undefined) {
                parameters.splice(parameters.indexOf(parameter), 1, {
                  ...parameter,
                  initFn: action.initFn,
                  initValue: None(),
                });
              }
              return parameters;
            }),
        })),
      };
    case ReportEditingActionType.DELETE_REPORT_PARAMETER:
      return {
        ...state,
        report: state.report.map((report) => ({
          ...report,
          parameters: report.parameters.map((parameters) => parameters.filter((p) => p.name !== action.name)),
        })),
      };
    case ReportEditingActionType.COMPLETE_REPORT_EDITING:
      return {
        isTransformationCollapsed: true,
        isInProgress: false,
        isEnableScheduling: false,
        report: None(),
        error: None(),
      };
    case ReportsActionType.RESET_REPORTS:
      return INITIAL_REPORTS_STATE.reportEditingState;
    default:
      return state;
  }
};

export const reportSavingReducer: Reducer<ReportSavingState, ReportSavingAction> = (state = INITIAL_REPORTS_STATE.reportSavingState, action) => {
  switch (action.type) {
    case ReportEditingActionType.START_REPORT_SAVING:
      return {
        isInProgress: true,
        error: None(),
      };
    case ReportEditingActionType.DONE_REPORT_SAVING:
      return {
        isInProgress: false,
        error: None(),
      };
    case ReportEditingActionType.FAIL_REPORT_SAVING:
      return {
        isInProgress: false,
        error: Maybe.fromUndefined(action.error),
      };
    case ReportsActionType.RESET_REPORTS:
      return INITIAL_REPORTS_STATE.reportSavingState;
    default:
      return state;
  }
};

export const reportDataSourcesListingReducer: Reducer<ReportDataSourcesListingState, ReportDataSourcesFetchAction> = (
  state = INITIAL_REPORTS_STATE.reportDataSourcesListingState,
  action,
) => {
  switch (action.type) {
    case ReportEditingActionType.REQUEST_REPORT_DATA_SOURCES:
      return {
        ...state,
        isInProgress: true,
        dataSources: None(),
        error: None(),
      };
    case ReportEditingActionType.RECEIVE_REPORT_DATA_SOURCES:
      return {
        ...state,
        isInProgress: false,
        dataSources: Maybe.fromUndefined(action.dataSources),
        error: None(),
      };
    case ReportEditingActionType.RECEIVE_REPORT_DATA_SOURCES_ERROR:
      return {
        ...state,
        isInProgress: false,
        dataSources: None(),
        error: Maybe.fromUndefined(action.error),
      };
    case ReportsActionType.RESET_REPORTS:
      return INITIAL_REPORTS_STATE.reportDataSourcesListingState;
    default:
      return state;
  }
};

export const reportTemplatesListingReducer: Reducer<ReportTemplatesListingState, ReportTemplatesFetchActionObject> = (
  state = INITIAL_REPORTS_STATE.reportTemplatesListingState,
  action,
) => {
  switch (action.type) {
    case ReportEditingActionType.REQUEST_REPORT_TEMPLATES:
      return {
        ...state,
        isInProgress: true,
        templates: None(),
        error: None(),
      };
    case ReportEditingActionType.RECEIVE_REPORT_TEMPLATES:
      return {
        ...state,
        isInProgress: false,
        templates: Maybe.fromUndefined(action.templates),
        error: None(),
      };
    case ReportEditingActionType.RECEIVE_REPORT_TEMPLATES_ERROR:
      return {
        ...state,
        isInProgress: false,
        templates: None(),
        error: Maybe.fromUndefined(action.error),
      };
    case ReportsActionType.RESET_REPORTS:
      return INITIAL_REPORTS_STATE.reportTemplatesListingState;
    default:
      return state;
  }
};

export const reportDeletingReducer: Reducer<ReportDeletingState, ReportDeletingAction> = (
  state = INITIAL_REPORTS_STATE.reportDeletingState,
  action,
) => {
  switch (action.type) {
    case ReportEditingActionType.START_REPORT_DELETING:
      return {
        isInProgress: true,
        reportId: Some(action.reportId),
        error: None(),
      };
    case ReportEditingActionType.DONE_REPORT_DELETING:
      return {
        isInProgress: false,
        reportId: None(),
        error: None(),
      };
    case ReportEditingActionType.FAIL_REPORT_DELETING:
      return {
        isInProgress: false,
        reportId: None(),
        error: action.error,
      };
    case ReportsActionType.RESET_REPORTS:
      return INITIAL_REPORTS_STATE.reportDeletingState;
    default:
      return state;
  }
};
