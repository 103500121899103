import { IReportRenderRequestBody, IRenderedReportResponse, ILiveRenderLinkReportResponse } from '@serverfarm/pointer-cod-api';
import { TemplateType } from '@serverfarm/rs-commons';
import logdown, { Logger } from 'logdown';
import { Maybe, None } from 'monet';
import { Dispatch } from 'redux';

import config from '../../../../config';
import axios from '../../../../services/axios';
import { showErrorNotification, showInfoNotification } from '../../../notification';
import { RenderedReport, Report, ReportParameter, ReportParameterInitFnType, ReportParameterInitValueType } from '../../entities';
import {
  reportStartInteractiveRendering,
  reportInteractiveRenderingComplete,
  reportInteractiveRenderingParameterEditFunc,
  reportInteractiveRenderingParameterEditValue,
  reportDoneDeferredRendering,
  reportDoneRendering,
  reportFailDeferredRendering,
  reportFailRendering,
  reportLiveLinkDoneGeneration,
  reportLiveLinkFailGeneration,
  reportLiveLinkStartGeneration,
  reportStartDeferredRendering,
  reportStartRendering,
} from '../../redux/actions';

import { openRenderReportViewerCsv, openRenderReportViewerJson, openRenderReportViewer } from './renderedReportViewer.actions';

export const renderReport = (
  report: Report,
  parameters: Maybe<ReportParameter[]> = None(),
  logger: Logger = logdown('reports:data:actions:renderReport'),
) => (dispatch: Dispatch<any>) => {
  const reportId = report.id;
  logger.log(`reportId => ${reportId}`);
  dispatch(reportStartRendering(reportId));
  axios({
    method: 'post',
    url: `${config.services.dacqs.api.url}/report/${reportId}/render`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      parametersInit: parameters
        .map((parameters: any) =>
          parameters.map((p: any) => ({
            name: p.name,
            initFn: p.initFn.orUndefined(),
            initValue: p.initValue.orUndefined(),
          })),
        )
        .orUndefined(),
    } as IReportRenderRequestBody,
  })
    .then((response) => response.data)
    .then((renderingResult: IRenderedReportResponse) => {
      dispatch(reportDoneRendering(reportId, renderingResult.permalinks));
      const renderedReport: RenderedReport = {
        id: reportId,
        permalinks: renderingResult.permalinks,
        created: new Date(),
      };
      if (report.template.exists((t) => t.templateType === TemplateType.JSON)) {
        dispatch(openRenderReportViewerJson(renderedReport, report));
      } else if (report.template.exists((t) => t.templateType === TemplateType.CSV)) {
        dispatch(openRenderReportViewerCsv(renderedReport, report));
      } else {
        dispatch(openRenderReportViewer(renderedReport, report));
      }
    })
    .catch((error) => {
      dispatch(reportFailRendering(error));
      showErrorNotification(error)(dispatch);
    });
};

export const deferredRenderReport = (reportId: string, email: string, logger: Logger = logdown('reports:deferredRenderReport')) => (
  dispatch: Dispatch<any>,
) => {
  logger.log(`reportId=${reportId}`);
  dispatch(reportStartDeferredRendering(reportId, email));
  axios({
    method: 'post',
    url: `${config.services.dacqs.api.url}/report/${reportId}/render/deferred`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      email,
    },
  })
    .then((response) => response.data)
    .then(() => {
      dispatch(reportDoneDeferredRendering());
    })
    .catch((error) => {
      dispatch(reportFailDeferredRendering(error));
      showErrorNotification(error)(dispatch);
    });
};

export const startReportInteractiveRendering = (report: Report) => (dispatch: Dispatch<any>) => {
  dispatch(reportStartInteractiveRendering(report));
};

export const editReportInteractiveRenderingParameterFunc = (name: string, initFn: Maybe<ReportParameterInitFnType>) => (dispatch: Dispatch<any>) => {
  dispatch(reportInteractiveRenderingParameterEditFunc(name, initFn));
};

export const editReportInteractiveRenderingParameterValue = (name: string, initValue: Maybe<ReportParameterInitValueType>) => (
  dispatch: Dispatch<any>,
) => {
  dispatch(reportInteractiveRenderingParameterEditValue(name, initValue));
};

export const completeReportInteractiveRendering = () => (dispatch: Dispatch<any>) => {
  dispatch(reportInteractiveRenderingComplete());
};

export const copyLiveRenderReportLinkToClipboard = (
  report: Report,
  logger: Logger = logdown('reports:data:actions:copyLiveRenderReportLinkToClipboard'),
) => async (dispatch: Dispatch<any>) => {
  logger.log('Start copying');
  dispatch(reportLiveLinkStartGeneration(report.id));
  try {
    const permissionStatus = await navigator.permissions.query({ name: 'clipboard-write' as PermissionName }); //use either { name: 'clipboard-write' as PermissionName } ?
    logger.log('clipboard-write permission state is ', permissionStatus.state);
    if (permissionStatus.state === 'granted') {
      axios({
        method: 'post',
        url: `${config.services.dacqs.api.url}/report/${report.id}/render/live-link`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.data as ILiveRenderLinkReportResponse)
        .then(async ({ liveLink }) => {
          await navigator.clipboard.writeText(liveLink);
          dispatch(showInfoNotification(`Report "${report.name}" render link has been copied to clipboard`, 'Clipboard', 5000));
        })
        .catch((error) => {
          showErrorNotification(error)(dispatch);
          dispatch(reportLiveLinkFailGeneration(error));
        })
        .finally(() => {
          dispatch(reportLiveLinkDoneGeneration(report.id));
        });
    }
  } catch (err) {
    console.error(err.name, err.message);
  }
};
