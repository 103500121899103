import { UserRole } from '../../auth/entities';

export enum RouteName {
  HOME = 'Home',
  PROFILE = 'Profile',
  DATA_SOURCES = 'DataSources',
  TEMPLATES = 'Templates',
  REPORTS = 'Reports',
}

export interface RouteConfig {
  name: RouteName;
  path: string;
  icon: string;
  componentName: string;
  layout: string;
  allowedForRoles: UserRole[];
}

const DASHBOARD_LAYOUT_PATH = '/dashboard';

/**
 * Take icons here: https://demos.creative-tim.com/light-bootstrap-dashboard-pro/examples/components/icons.html
 */

export const routesList: RouteConfig[] = [
  {
    path: '',
    name: RouteName.HOME,
    icon: 'nc-icon nc-bank',
    componentName: RouteName.HOME.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.COMMON_USER, UserRole.ADMIN],
  },
  {
    path: '/datasources',
    name: RouteName.DATA_SOURCES,
    icon: 'nc-icon nc-cloud-download-93',
    componentName: RouteName.DATA_SOURCES.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.ADMIN],
  },
  {
    path: '/templates',
    name: RouteName.TEMPLATES,
    icon: 'nc-icon nc-single-copy-04',
    componentName: RouteName.TEMPLATES.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.ADMIN],
  },
  {
    path: '/reports',
    name: RouteName.REPORTS,
    icon: 'nc-icon nc-chart-bar-32',
    componentName: RouteName.REPORTS.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.COMMON_USER, UserRole.ADMIN],
  },
  {
    path: '/profile',
    name: RouteName.PROFILE,
    icon: 'nc-icon nc-single-02',
    componentName: RouteName.PROFILE.toString(),
    layout: DASHBOARD_LAYOUT_PATH,
    allowedForRoles: [UserRole.COMMON_USER, UserRole.ADMIN],
  },
];
