import { DataSourceParameterType } from '@serverfarm/rs-commons';
import { Maybe } from 'monet';

import { DataSource } from '../datasources';
import { Template } from '../templates';
import { Company } from '../user';
import { DateRange } from '../utils/dateRangeFilter';

export interface ReportsListFilter {
  id?: string;
  name?: string; // todo : change to maybe and implement persistent store SerDes (see src/components/reports/redux/store.ts)
  template?: string;
  dataSource?: string;
  companies?: string[];
  created: Maybe<DateRange>;
  updated: Maybe<DateRange>;
  lastRendered: Maybe<DateRange>;
}

export interface ReportsListSort {
  field: string;
  order: 'asc' | 'desc';
}

export enum ReportParameterInitFunctionType {
  NONE = 'None',
  NOW = 'Now',
  BEGINNING_OF_HOUR_OF_X_HOURS_AGO = 'BeginningOfHourOfXHoursAgo',
  DATA_SOURCE_ELEMENT_ID = 'DataSourceElementId',
  FIRST_DAY_OF_CURRENT_MONTH = 'FirstDateOfCurrentMonth',
  FIRST_DAY_OF_PAST_MONTH = 'FirstDateOfPastMonth',
  FIRST_DAY_OF_X_MONTH_AGO = 'FirstDayOfXMonthAgo',
  LAST_DAY_OF_PAST_MONTH = 'LastDateOfPastMonth',
  FIRST_DAY_OF_PAST_YEAR = 'FirstDayOfPastYear',
  THREE_MONTHS_FROM_NOW = 'ThreeMonthsFromNow',
  START_OF_X_DAYS_AGO = 'StartOfXDaysAgo',
}

export enum ReportParameterInitFunctionTypeParams {
  // NONE = 'None',
  NOW__TIMEZONE = 'timezone',
  BEGINNING_OF_HOUR_OF_X_HOURS_AGO__HOURS_AGO = 'hoursAgo',
  DATA_SOURCE_ELEMENT_ID__DATA_SOURCE_ID = 'dataSourceId',
  DATA_SOURCE_ELEMENT_ID__DEFAULT_ELEMENT_ID = 'defaultElementId',
  FIRST_DAY_OF_CURRENT_MONTH__TIMEZONE = 'timezone',
  FIRST_DAY_OF_PAST_MONTH__TIMEZONE = 'timezone',
  FIRST_DAY_OF_X_MONTH_AGO__TIMEZONE = 'timezone',
  LAST_DAY_OF_PAST_MONTH__TIMEZONE = 'timezone',
  FIRST_DAY_OF_PAST_YEAR__TIMEZONE = 'timezone',
  THREE_MONTHS_FROM_NOW__TIMEZONE = 'timezone',
  START_OF_X_DAYS_AGO = 'daysAgo',
}

export const InitFnParameters = (parameterInitFunctionType: ReportParameterInitFunctionType) => {
  switch (parameterInitFunctionType) {
    case ReportParameterInitFunctionType.DATA_SOURCE_ELEMENT_ID:
      return [
        {
          name: ReportParameterInitFunctionTypeParams.DATA_SOURCE_ELEMENT_ID__DATA_SOURCE_ID,
          type: DataSourceParameterType.STRING,
        },
        {
          name: ReportParameterInitFunctionTypeParams.DATA_SOURCE_ELEMENT_ID__DEFAULT_ELEMENT_ID,
          type: DataSourceParameterType.INT,
        },
      ];
    case ReportParameterInitFunctionType.FIRST_DAY_OF_CURRENT_MONTH:
    case ReportParameterInitFunctionType.FIRST_DAY_OF_PAST_MONTH:
    case ReportParameterInitFunctionType.FIRST_DAY_OF_PAST_YEAR:
    case ReportParameterInitFunctionType.LAST_DAY_OF_PAST_MONTH:
    case ReportParameterInitFunctionType.NOW:
    case ReportParameterInitFunctionType.THREE_MONTHS_FROM_NOW:
      return [
        {
          name: 'timezone',
          type: DataSourceParameterType.STRING,
        },
      ];
    case ReportParameterInitFunctionType.START_OF_X_DAYS_AGO:
      return [
        {
          name: 'timezone',
          type: DataSourceParameterType.STRING,
        },
        {
          name: 'daysAgo',
          type: DataSourceParameterType.INT,
        },
      ];
    case ReportParameterInitFunctionType.FIRST_DAY_OF_X_MONTH_AGO:
      return [
        {
          name: 'timezone',
          type: DataSourceParameterType.STRING,
        },
        {
          name: 'monthsAgo',
          type: DataSourceParameterType.INT,
        },
      ];
    case ReportParameterInitFunctionType.BEGINNING_OF_HOUR_OF_X_HOURS_AGO:
      return [
        {
          name: ReportParameterInitFunctionTypeParams.BEGINNING_OF_HOUR_OF_X_HOURS_AGO__HOURS_AGO,
          type: DataSourceParameterType.INT,
        },
      ];
    default:
      return [];
  }
};

export const InitFnReturnType = (parameterInitFunctionType: ReportParameterInitFunctionType) => {
  switch (parameterInitFunctionType) {
    case ReportParameterInitFunctionType.DATA_SOURCE_ELEMENT_ID:
      return DataSourceParameterType.INT;
    case ReportParameterInitFunctionType.FIRST_DAY_OF_CURRENT_MONTH:
    case ReportParameterInitFunctionType.FIRST_DAY_OF_PAST_MONTH:
    case ReportParameterInitFunctionType.FIRST_DAY_OF_PAST_YEAR:
    case ReportParameterInitFunctionType.FIRST_DAY_OF_X_MONTH_AGO:
    case ReportParameterInitFunctionType.LAST_DAY_OF_PAST_MONTH:
    case ReportParameterInitFunctionType.NOW:
    case ReportParameterInitFunctionType.THREE_MONTHS_FROM_NOW:
    case ReportParameterInitFunctionType.START_OF_X_DAYS_AGO:
    case ReportParameterInitFunctionType.BEGINNING_OF_HOUR_OF_X_HOURS_AGO:
      return DataSourceParameterType.DATE_TIME;
    default:
      return DataSourceParameterType.STRING;
  }
};

export interface ReportParameterInitFnType {
  fn: ReportParameterInitFunctionType;
  parameters: {
    name: string;
    value: string | number | object;
  }[];
}

export interface ReportParameterInitValueType {
  type: DataSourceParameterType;
  value: string | number | string[] | number[] | Date;
  extra?: string;
}

export interface ReportParameter {
  name: string;
  initFn: Maybe<ReportParameterInitFnType>;
  initValue: Maybe<ReportParameterInitValueType>;
}

export enum ReportDeliverType {
  EMAIL = 'email',
}

export interface ReportDeliveryConfig {
  deliverType: ReportDeliverType;
}

export interface ReportDeliverViaEmail extends ReportDeliveryConfig {
  email: string;
}

export interface ReportDeliverS3Upload extends ReportDeliveryConfig {
  s3Bucket: string;
}

export interface ReportScheduleSettings {
  schedule: {
    recurring: {
      cron: string;
      timezone?: string;
    };
  };
  deliver: (ReportDeliverViaEmail | ReportDeliverS3Upload)[];
}

export interface Report {
  id: string;
  name: string;
  dataSource: Maybe<DataSource>;
  template: Maybe<Template>;
  company: Maybe<Company>;
  transformation: Maybe<string>;
  parameters: Maybe<ReportParameter[]>;
  scheduleSettings: Maybe<ReportScheduleSettings>;
  lastRendered: Maybe<Date>;
  updated: Maybe<Date>;
  created: Date;
}

/**
 * Entities SerDes
 */

export interface ReportParameterFuncDeserializeOptions {
  dataSources: DataSource[];
}

export const reportParameterFuncDeserialize = (options: ReportParameterFuncDeserializeOptions) => ({
  fn,
  parameters,
}: {
  fn: string;
  parameters: { name: string; value: string | number }[];
}): ReportParameterInitFnType => {
  switch (fn) {
    case 'DataSourceElementId':
      return {
        fn: ReportParameterInitFunctionType.DATA_SOURCE_ELEMENT_ID,
        parameters,
      };
    case 'Now':
      return {
        fn: ReportParameterInitFunctionType.NOW,
        parameters,
      };
    case 'FirstDateOfCurrentMonth':
      return {
        fn: ReportParameterInitFunctionType.FIRST_DAY_OF_CURRENT_MONTH,
        parameters,
      };
    case 'BeginningOfHourOfXHoursAgo':
      return {
        fn: ReportParameterInitFunctionType.BEGINNING_OF_HOUR_OF_X_HOURS_AGO,
        parameters,
      };
    case 'FirstDateOfPastMonth':
      return {
        fn: ReportParameterInitFunctionType.FIRST_DAY_OF_PAST_MONTH,
        parameters,
      };
    case 'FirstDayOfXMonthAgo':
      return {
        fn: ReportParameterInitFunctionType.FIRST_DAY_OF_X_MONTH_AGO,
        parameters,
      };
    case 'LastDateOfPastMonth':
      return {
        fn: ReportParameterInitFunctionType.LAST_DAY_OF_PAST_MONTH,
        parameters,
      };
    case 'FirstDayOfPastYear':
      return {
        fn: ReportParameterInitFunctionType.FIRST_DAY_OF_PAST_YEAR,
        parameters,
      };
    case 'ThreeMonthsFromNow':
      return {
        fn: ReportParameterInitFunctionType.THREE_MONTHS_FROM_NOW,
        parameters,
      };
    case 'StartOfXDaysAgo':
      return {
        fn: ReportParameterInitFunctionType.START_OF_X_DAYS_AGO,
        parameters,
      };
    default:
      return {
        fn: ReportParameterInitFunctionType.NONE,
        parameters: [],
      };
    // throw new Error();
  }
};

export const reportParameterValueDeserialize = ({
  type,
  value,
  extra,
}: {
  type: string;
  value: string | number | string[] | number[] | Date;
  extra?: string;
}): ReportParameterInitValueType => {
  switch (type) {
    case 'String':
      return { type: DataSourceParameterType.STRING, value: value as string, extra };
    case 'Int':
      return { type: DataSourceParameterType.INT, value: value as number, extra };
    case 'Float':
      return { type: DataSourceParameterType.FLOAT, value: value as number, extra };
    case 'DateTime':
      return { type: DataSourceParameterType.DATE_TIME, value: value as string, extra };
    case '[String]':
      return { type: DataSourceParameterType.ARRAY_OF_STRINGS, value: value as string[], extra };
    case '[Int]':
      return { type: DataSourceParameterType.ARRAY_OF_INTS, value: value as number[], extra };
    case '[Float]':
      return { type: DataSourceParameterType.ARRAY_OF_FLOATS, value: value as number[], extra };
    default:
      throw new Error(`Not implemented or invalid DataSourceParameterType: type=${type} value = ${value}`);
  }
};
export interface PermaLink {
  default: string;
  pdf: string;
  image: string;
  xls: string;
  pdfDownload: string;
  imageDownload: string;
  xlsDownload: string;
}
export interface RenderedReport {
  id: string;
  permalinks: PermaLink;
  created: Date;
}

export interface RenderedReportsHistoryFilter {
  key?: string;
  created?: {
    from: string;
    to: string;
  };
}

export interface RenderedReportsHistorySort {
  field: string;
  order: 'asc' | 'desc';
}
