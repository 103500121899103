import logdown from 'logdown';
import moment from 'moment';
import { Maybe } from 'monet';
import React, { useState } from 'react';
import { DateRangePicker, Range } from 'react-date-range';
import { Button, Popover, PopoverBody, Form, FormGroup } from 'reactstrap';

import { DATE_TIME_FORMAT_DEFAULT } from './dateTime';

export type DateRange = { from: Date; to: Date };

export type onDateRangeFilterSave = (dateRange: DateRange) => void;
export type onDateRangeFilterReset = () => void;

export interface DateRangeFilterProps {
  id: string;
  value: Maybe<{
    from: Date;
    to: Date;
  }>;
  onSave: onDateRangeFilterSave;
  onReset: onDateRangeFilterReset;
}

const RANGE_KEY = 'selection';
const DEFAULT_BUTTON_TEXT = 'Filter by date range';

const logger = logdown('component:DateRangeFilter');

export const DateRangeFilter = (props: DateRangeFilterProps) => {
  const initialPopoverText = props.value
    .map((dateRange) => {
      const fromStr = moment(dateRange.from).format(DATE_TIME_FORMAT_DEFAULT);
      const toStr = moment(dateRange.to).format(DATE_TIME_FORMAT_DEFAULT);
      return `${fromStr} to ${toStr}`;
    })
    .getOrElse(DEFAULT_BUTTON_TEXT);

  const initialDateRangeState = props.value
    .map((value) => ({
      startDate: value.from,
      endDate: value.to,
      key: RANGE_KEY,
    }))
    .getOrElse({
      // eslint-disable-next-line prettier/prettier
      startDate: moment().weekday(1).hour(0).minute(0).second(0).millisecond(0).toDate(),
      endDate: moment().toDate(),
      key: RANGE_KEY,
    });

  const [popOverOpen, setOpen] = useState(false);
  const [selection, setSelection] = useState(initialDateRangeState);

  const toggle = () => setOpen(!popOverOpen);

  const onSave = () => {
    props.onSave({
      from: selection.startDate,
      to: selection.endDate,
    });
    toggle();
  };

  const onReset = () => {
    setSelection(initialDateRangeState);
    props.onReset();
    toggle();
  };

  const cancel = () => {
    toggle();
  };

  const handleChange = (result: any) => {
    const range = result[RANGE_KEY] as Range;
    setSelection({
      startDate: moment(range.startDate).toDate(),
      endDate: moment(range.endDate).toDate(),
      key: RANGE_KEY,
    });
  };

  return (
    <div id={`container-${props.id}`}>
      <Button className="date-range-filter-toggle" id={props.id} color={props.value.isSome() ? 'success' : 'default'} size="sm" onClick={toggle}>
        {initialPopoverText}
      </Button>
      <Popover placement="left" isOpen={popOverOpen} target={props.id} toggle={toggle}>
        <PopoverBody>
          <Form inline>
            <FormGroup>
              <DateRangePicker ranges={[selection]} onChange={handleChange} />
            </FormGroup>
            <Button className="date-range-filter-save" color="primary" size="sm" onClick={() => onSave()}>
              Save
            </Button>
            <Button className="date-range-filter-clear" color="danger" size="sm" onClick={() => onReset()}>
              Clear
            </Button>
            {/* <Button>Submit</Button> */}
            <Button className="date-range-filter-cancel" color="default" size="sm" onClick={() => cancel()}>
              Cancel
            </Button>
          </Form>
        </PopoverBody>
      </Popover>
    </div>
  );
};
