import logdown from 'logdown';
import * as React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Button, Spinner, UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import {
  cloneDataSource,
  deleteDataSource,
  editDataSource,
  executeDataSource,
  fetchDataSources,
  updateDataSourceListingFilter,
  updateDataSourceListingSort,
  resetDataSourcesState,
} from './data/actions';
import DataSourceEditor from './DataSourceEditor';
import DataSourcesList from './DataSourcesList';

const logger = logdown('component:DataSources');

class Component extends React.Component<Props, {}> {
  componentDidUpdate(prevProps: Props) {
    const isEditorClosed = prevProps.dataSourceEditing.dataSource.isSome() && this.props.dataSourceEditing.dataSource.isNone();
    const isDeletionCompleted = prevProps.dataSourceDeleting.isInProgress && !this.props.dataSourceDeleting.isInProgress;

    if (isEditorClosed || isDeletionCompleted) {
      this.props.fetchDataSources(
        this.props.dataSourcesListing.filter,
        this.props.dataSourcesListing.page,
        this.props.dataSourcesListing.sizePerPage,
        this.props.dataSourcesListing.sort,
      );
    }
  }

  componentWillUnmount() {
    this.props.resetDataSourcesState();
  }

  public render() {
    return (
      <div className="content">
        <Button
          id="data-sources-list-create-button"
          data-testid="data-sources-list-create-button"
          color="primary"
          size="sm"
          disabled={this.props.dataSourcesListing.isInProgress}
          onClick={() => this.props.editDataSource()}
        >
          Create
        </Button>
        <Button
          id="data-sources-list-reload-button"
          data-testid="data-sources-list-reload-button"
          color="secondary"
          size="sm"
          disabled={this.props.dataSourcesListing.isInProgress}
          onClick={() =>
            this.props.fetchDataSources(
              this.props.dataSourcesListing.filter,
              this.props.dataSourcesListing.page,
              this.props.dataSourcesListing.sizePerPage,
              this.props.dataSourcesListing.sort,
            )
          }
        >
          Refresh
        </Button>
        <DataSourcesList {...this.props} />
        <DataSourceEditor />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchDataSources,
      cloneDataSource,
      editDataSource,
      deleteDataSource,
      executeDataSource,
      updateDataSourceListingFilter,
      updateDataSourceListingSort,
      resetDataSourcesState,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  dataSourcesListing: state.dataSourcesListing,
  dataSourceDeleting: state.dataSourceDeleting,
  dataSourceEditing: state.dataSourceEditing,
  companiesListing: state.companiesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);
type Props = typeof stateProps & typeof dispatchProps;

export default connect<typeof stateProps, typeof dispatchProps, any>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Component);
