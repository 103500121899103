import { DataSourceParameterType } from '@serverfarm/rs-commons';
import { Maybe } from 'monet';

import { Action, ErrorAction } from '../../redux/actions';
import { Company } from '../../user';
import { DataSource, DataSourceParameter, DataSourcesListSort, DataSourcesListFilter } from '../entities';

/**
 * Action types
 */

export enum ActionType {
  REQUEST_DATA_SOURCES = 'REQUEST_DATA_SOURCES',
  RECEIVE_DATA_SOURCES = 'RECEIVE_DATA_SOURCES',
  RECEIVE_DATA_SOURCES_ERROR = 'RECEIVE_DATA_SOURCES_ERROR',

  REQUEST_DATA_SOURCE_EXEC = 'REQUEST_DATA_SOURCE_EXEC',
  RECEIVE_DATA_SOURCE_EXEC = 'RECEIVE_DATA_SOURCE_EXEC',
  RECEIVE_DATA_SOURCE_EXEC_ERROR = 'RECEIVE_DATA_SOURCE_EXEC_ERROR',
  UPDATE_DATASOURCE_LISTING_FILTER = 'UPDATE_DATASOURCE_LISTING_FILTER',
  UPDATE_DATASOURCE_SORT_LIST = 'UPDATE_DATASOURCE_SORT_LIST',

  START_DATA_SOURCE_EDITING = 'START_DATA_SOURCE_EDITING',
  EDIT_DATA_SOURCE_NAME = 'EDIT_DATA_SOURCE_NAME',
  EDIT_DATA_SOURCE_COMPANY = 'EDIT_DATA_SOURCE_COMPANY',
  EDIT_DATA_SOURCE_QUERY = 'EDIT_DATA_SOURCE_QUERY',
  ADD_DATA_SOURCE_PARAMETER = 'ADD_DATA_SOURCE_PARAMETER',
  DELETE_DATA_SOURCE_PARAMETER = 'DELETE_DATA_SOURCE_PARAMETER',
  COMPLETE_DATA_SOURCE_EDITING = 'COMPLETE_DATA_SOURCE_EDITING',

  EDIT_DATA_SOURCE_PARAMETER_NAME = 'EDIT_DATA_SOURCE_PARAMETER_NAME',
  EDIT_DATA_SOURCE_PARAMETER_TYPE = 'EDIT_DATA_SOURCE_PARAMETER_TYPE',
  EDIT_DATA_SOURCE_PARAMETER_IS_OPTIONAL = 'EDIT_DATA_SOURCE_PARAMETER_IS_OPTIONAL',

  START_DATA_SOURCE_SAVING = 'START_DATA_SOURCE_SAVING',
  DONE_DATA_SOURCE_SAVING = 'DONE_DATA_SOURCE_SAVING',
  FAIL_DATA_SOURCE_SAVING = 'FAIL_DATA_SOURCE_SAVING',

  START_DATA_SOURCE_DELETING = 'START_DATA_SOURCE_DELETING',
  DONE_DATA_SOURCE_DELETING = 'DONE_DATA_SOURCE_DELETING',
  FAIL_DATA_SOURCE_DELETING = 'FAIL_DATA_SOURCE_DELETING',

  RESET_DATA_SOURCES = 'RESET_DATA_SOURCES',
}

/**
 * Action creators
 */
interface DataSourcesRequestAction extends Action<ActionType> {
  filter: Maybe<DataSourcesListFilter>;
  sizePerPage: number;
  sort: Maybe<DataSourcesListSort>;
}

interface DataSourcesReceiveAction extends Action<ActionType> {
  dataSources: DataSource[];
  page: number;
  total: number;
}

type DataSourcesReceiveErrorAction = ErrorAction<ActionType>;

/**
 * Data Source editing
 */

interface DataSourceStartEditingAction extends Action<ActionType> {
  dataSource: Maybe<DataSource>;
}

interface DataSourceEditNameAction extends Action<ActionType> {
  name: string;
}

interface DataSourceEditCompanyAction extends Action<ActionType> {
  company: Maybe<Company>;
}

interface DataSourceEditQueryAction extends Action<ActionType> {
  query: string;
}

interface DataSourceCompleteEditingAction extends Action<ActionType> {
  dataSource: Maybe<DataSource>;
}

/**
 * Data Source parameter editing
 */

interface DataSourceParameterEditNameAction extends Action<ActionType> {
  name: string;
}

interface DataSourceParameterEditTypeAction extends Action<ActionType> {
  pType: DataSourceParameterType;
}

interface DataSourceParameterEditIsOptionalAction extends Action<ActionType> {
  isOptional: boolean;
}

interface DataSourceParameterAddAction extends Action<ActionType> {
  parameter: DataSourceParameter;
}

interface DataSourceParameterDeleteAction extends Action<ActionType> {
  name: string;
}

/**
 * Data Source saving
 */

type DataSourceStartSavingAction = Action<ActionType>;

interface DataSourceDoneSavingAction extends Action<ActionType> {
  dataSource: DataSource;
}

type DataSourceFailSavingAction = ErrorAction<ActionType>;

/**
 * Data Source deleting
 */

interface DataSourceStartDeletingAction extends Action<ActionType> {
  dataSourceId: string;
}

interface DataSourceDoneDeletingAction extends Action<ActionType> {
  dataSourceId: string;
}

interface DataSourceFailDeletingAction extends ErrorAction<ActionType> {
  dataSourceId: string;
}

/**
 * Data Source execution
 */

type DataSourceExecRequestAction = Action<ActionType>;

interface DataSourceExecReceiveAction extends Action<ActionType> {
  data: any;
}

type DataSourceExecReceiveErrorAction = ErrorAction<ActionType>;

interface DataSourceListingFilterUpdateAction extends Action<ActionType> {
  filter: Maybe<DataSourcesListFilter>;
  page: number;
  sizePerPage: number;
}

interface DataSourceListingSortUpdateAction extends Action<ActionType> {
  sort: Maybe<DataSourcesListSort>;
}

type DataSourceResetAction = Action<ActionType>;

export type DataSourcesFetchAction = DataSourcesRequestAction &
  DataSourcesReceiveAction &
  DataSourcesReceiveErrorAction &
  DataSourceListingFilterUpdateAction &
  DataSourceResetAction;

export type DataSourceEditingAction = DataSourceStartEditingAction &
  DataSourceEditNameAction &
  DataSourceEditCompanyAction &
  DataSourceEditQueryAction &
  DataSourceCompleteEditingAction &
  DataSourceParameterAddAction &
  DataSourceParameterDeleteAction &
  DataSourceResetAction;

export type DataSourceParameterEditingAction = DataSourceParameterEditNameAction &
  DataSourceParameterEditTypeAction &
  DataSourceParameterEditIsOptionalAction &
  DataSourceParameterAddAction &
  DataSourceParameterDeleteAction &
  DataSourceResetAction;

export type DataSourceSavingAction = DataSourceStartSavingAction & DataSourceDoneSavingAction & DataSourceFailSavingAction & DataSourceResetAction;

export type DataSourceDeletingAction = DataSourceStartDeletingAction &
  DataSourceDoneDeletingAction &
  DataSourceFailDeletingAction &
  DataSourceResetAction;

export type DataSourceExecAction = DataSourceExecRequestAction &
  DataSourceExecReceiveAction &
  DataSourceExecReceiveErrorAction &
  DataSourceResetAction;

/**
 * Data Source fetching
 */

export const dataSourcesRequest = (
  filter: Maybe<DataSourcesListFilter>,
  sizePerPage: number,
  sort: Maybe<DataSourcesListSort>,
): DataSourcesRequestAction => ({
  type: ActionType.REQUEST_DATA_SOURCES,
  filter,
  sizePerPage,
  sort,
});

export const dataSourcesReceive = (dataSources: DataSource[], page: number, total: number): DataSourcesReceiveAction => ({
  type: ActionType.RECEIVE_DATA_SOURCES,
  dataSources,
  page,
  total,
});

export const dataSourcesReceiveError = (error: Error): DataSourcesReceiveErrorAction => ({
  type: ActionType.RECEIVE_DATA_SOURCES_ERROR,
  error,
});

export const dataSourceListingFilterUpdate = (
  filter: Maybe<DataSourcesListFilter>,
  page: number,
  sizePerPage: number,
): DataSourceListingFilterUpdateAction => ({
  type: ActionType.UPDATE_DATASOURCE_LISTING_FILTER,
  filter,
  page,
  sizePerPage,
});

export const dataSourceListingSortUpdate = (sort: Maybe<DataSourcesListSort>): DataSourceListingSortUpdateAction => ({
  type: ActionType.UPDATE_DATASOURCE_SORT_LIST,
  sort,
});

/**
 * Data Source editing
 */

export const dataSourceStartEditing = (dataSource: Maybe<DataSource>): DataSourceStartEditingAction => ({
  type: ActionType.START_DATA_SOURCE_EDITING,
  dataSource,
});

export const dataSourceEditName = (name: string): DataSourceEditNameAction => ({
  type: ActionType.EDIT_DATA_SOURCE_NAME,
  name,
});

export const dataSourceEditCompany = (company: Maybe<Company>): DataSourceEditCompanyAction => ({
  type: ActionType.EDIT_DATA_SOURCE_COMPANY,
  company,
});

export const dataSourceEditQuery = (query: string): DataSourceEditQueryAction => ({
  type: ActionType.EDIT_DATA_SOURCE_QUERY,
  query,
});

export const dataSourceCompleteEditing = (dataSource: Maybe<DataSource>): DataSourceCompleteEditingAction => ({
  type: ActionType.COMPLETE_DATA_SOURCE_EDITING,
  dataSource,
});

/**
 * Data Source parameter editing
 */

export const dataSourceParameterEditName = (name: string): DataSourceParameterEditNameAction => ({
  type: ActionType.EDIT_DATA_SOURCE_PARAMETER_NAME,
  name,
});

export const dataSourceParameterEditType = (pType: DataSourceParameterType): DataSourceParameterEditTypeAction => ({
  type: ActionType.EDIT_DATA_SOURCE_PARAMETER_TYPE,
  pType,
});

export const dataSourceParameterEditIsOptional = (isOptional: boolean): DataSourceParameterEditIsOptionalAction => ({
  type: ActionType.EDIT_DATA_SOURCE_PARAMETER_IS_OPTIONAL,
  isOptional,
});

export const dataSourceParameterAdd = (parameter: DataSourceParameter): DataSourceParameterAddAction => ({
  type: ActionType.ADD_DATA_SOURCE_PARAMETER,
  parameter,
});

export const dataSourceParameterDelete = (name: string): DataSourceParameterDeleteAction => ({
  type: ActionType.DELETE_DATA_SOURCE_PARAMETER,
  name,
});

/**
 * Data Source saving
 */

export const dataSourceStartSaving = (): DataSourceStartSavingAction => ({
  type: ActionType.START_DATA_SOURCE_SAVING,
});

export const dataSourceDoneSaving = (dataSource: DataSource): DataSourceDoneSavingAction => ({
  type: ActionType.DONE_DATA_SOURCE_SAVING,
  dataSource,
});

export const dataSourceFailSaving = (error: Error): DataSourceFailSavingAction => ({
  type: ActionType.FAIL_DATA_SOURCE_SAVING,
  error,
});

/**
 * Data Source deleting
 */

export const dataSourceStartDeleting = (dataSourceId: string): DataSourceStartDeletingAction => ({
  type: ActionType.START_DATA_SOURCE_DELETING,
  dataSourceId,
});

export const dataSourceDoneDeleting = (dataSourceId: string): DataSourceDoneDeletingAction => ({
  type: ActionType.DONE_DATA_SOURCE_DELETING,
  dataSourceId,
});

export const dataSourceFailDeleting = (dataSourceId: string, error: Error): DataSourceFailDeletingAction => ({
  type: ActionType.FAIL_DATA_SOURCE_DELETING,
  dataSourceId,
  error,
});

/**
 * Data Source execution
 */

export const dataSourceExecRequest = (): DataSourceExecRequestAction => ({
  type: ActionType.REQUEST_DATA_SOURCE_EXEC,
});

export const dataSourceExecReceive = (data: any): DataSourceExecReceiveAction => ({
  type: ActionType.RECEIVE_DATA_SOURCE_EXEC,
  data,
});

export const dataSourceExecReceiveError = (error: Error): DataSourceExecReceiveErrorAction => ({
  type: ActionType.RECEIVE_DATA_SOURCE_EXEC_ERROR,
  error,
});

/**
 * Data Source reset
 */

export const dataSourcesReset = (): DataSourceResetAction => ({
  type: ActionType.RESET_DATA_SOURCES,
});
