import { Maybe } from 'monet';
import { Reducer } from 'redux';

import {
  ActionType,
  TemplateDeletingActionObject,
  TemplateEditingActionObject,
  TemplateSavingActionObject,
  TemplatesFetchActionObject,
} from './actions';
import { TEMPLATES_INITIAL_STATE } from './constants';
import { TemplateDeletingState, TemplateEditingState, TemplateSavingState, TemplatesListingState } from './entities';

export const templatesListingReducer: Reducer<TemplatesListingState, TemplatesFetchActionObject> = (
  state = TEMPLATES_INITIAL_STATE.templatesListingState,
  action,
) => {
  switch (action.type) {
    case ActionType.REQUEST_TEMPLATES:
      return {
        ...state,
        isInProgress: true,
        templates: Maybe.None(),
        total: 0,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_TEMPLATES:
      return {
        ...state,
        isInProgress: false,
        templates: action.templates.length ? Maybe.Some(action.templates) : Maybe.None(),
        page: action.page,
        total: action.total,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_TEMPLATES_ERROR:
      return {
        ...state,
        isInProgress: false,
        templates: Maybe.None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Maybe.Some(action.error),
      };
    case ActionType.UPDATE_TEMPLATE_LISTING_FILTER:
      return {
        ...state,
        filter: action.filter,
        page: action.page,
        sizePerPage: action.sizePerPage,
      };
    case ActionType.UPDATE_TEMPLATE_SORT_LIST:
      return {
        ...state,
        sort: action.sort,
      };
    case ActionType.RESET_TEMPLATES:
      return TEMPLATES_INITIAL_STATE.templatesListingState;
    default:
      return state;
  }
};

export const templateEditingReducer: Reducer<TemplateEditingState, TemplateEditingActionObject> = (
  state = TEMPLATES_INITIAL_STATE.templateEditingState,
  action,
) => {
  switch (action.type) {
    case ActionType.START_TEMPLATE_EDITING:
      return {
        ...state,
        isInProgress: true,
        template: action.template.orElse(Maybe.Some(TEMPLATES_INITIAL_STATE.template)),
        error: Maybe.None(),
      };
    case ActionType.EDIT_TEMPLATE_NAME:
      return {
        ...state,
        template: state.template.map((t) => ({ ...t, name: action.name })),
      };
    case ActionType.EDIT_TEMPLATE_COMPANY:
      return {
        ...state,
        template: state.template.map((t) => ({ ...t, company: action.company })),
      };
    case ActionType.EDIT_TEMPLATE_TEMPLATE:
      return {
        ...state,
        template: state.template.map((t) => ({ ...t, template: action.template })),
      };
    case ActionType.EDIT_TEMPLATE_TEMPLATE_TYPE:
      return {
        ...state,
        template: state.template.map((t) => ({ ...t, templateType: action.templateType })),
      };
    case ActionType.COMPLETE_TEMPLATE_EDITING:
      return {
        ...state,
        isInProgress: false,
        template: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.RESET_TEMPLATES:
      return TEMPLATES_INITIAL_STATE.templateEditingState;
    default:
      return state;
  }
};

export const templateSavingReducer: Reducer<TemplateSavingState, TemplateSavingActionObject> = (
  state = TEMPLATES_INITIAL_STATE.templateSavingState,
  action,
) => {
  switch (action.type) {
    case ActionType.START_TEMPLATE_SAVING:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.DONE_TEMPLATE_SAVING:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.None(),
      };
    case ActionType.FAIL_TEMPLATE_SAVING:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.Some(action.error),
      };
    default:
      return state;
  }
};

export const templateDeletingReducer: Reducer<TemplateDeletingState, TemplateDeletingActionObject> = (
  state = TEMPLATES_INITIAL_STATE.templateDeletingState,
  action,
) => {
  switch (action.type) {
    case ActionType.START_TEMPLATE_DELETING:
      return {
        ...state,
        isInProgress: true,
        templateId: Maybe.Some(action.templateId),
        error: Maybe.None(),
      };
    case ActionType.DONE_TEMPLATE_DELETING:
      return {
        ...state,
        isInProgress: false,
        templateId: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.FAIL_TEMPLATE_DELETING:
      return {
        ...state,
        isInProgress: false,
        templateId: Maybe.None(),
        error: action.error,
      };
    case ActionType.RESET_TEMPLATES:
      return TEMPLATES_INITIAL_STATE.templateDeletingState;
    default:
      return state;
  }
};
