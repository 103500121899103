import { Maybe } from 'monet';

import { CompaniesListingState, UserProfile, UserState, ServicesVersionState } from './entities';

const userProfile: UserProfile = {
  role: Maybe.None(),
};
const companiesListingState: CompaniesListingState = { isInProgress: false, companies: Maybe.None(), error: Maybe.None() };
const userState: UserState = {
  isLoggingIn: false,
  isLoggedIn: false,
  profile: userProfile,
};

export const INITIAL_USER_STATE = {
  userProfile,
  companiesListingState,
  userState,
};
