import { IDataSourceResponse, IDataSourcesListResponse } from '@serverfarm/pointer-cod-api';
import { DataSourceParameterType } from '@serverfarm/rs-commons';
import logdown, { Logger } from 'logdown';
import { Maybe, None } from 'monet';
import { Dispatch } from 'redux';

import config from '../../../config';
import { State } from '../../../reducers';
import axios from '../../../services/axios';
import { showErrorNotification } from '../../notification';
import { Company } from '../../user';
import { DataSource, DataSourceParameter, DataSourcesListSort, DataSourcesListFilter } from '../entities';
import {
  dataSourceCompleteEditing,
  dataSourceDoneDeleting,
  dataSourceDoneSaving,
  dataSourceEditCompany,
  dataSourceEditName,
  dataSourceEditQuery,
  dataSourceExecReceive,
  dataSourceExecReceiveError,
  dataSourceExecRequest,
  dataSourceFailDeleting,
  dataSourceFailSaving,
  dataSourceListingFilterUpdate,
  dataSourceParameterAdd,
  dataSourceParameterDelete,
  dataSourceParameterEditIsOptional,
  dataSourceParameterEditName,
  dataSourceParameterEditType,
  dataSourcesReceive,
  dataSourcesReceiveError,
  dataSourcesRequest,
  dataSourceStartDeleting,
  dataSourceListingSortUpdate,
  dataSourceStartEditing,
  dataSourceStartSaving,
  dataSourcesReset,
} from '../redux/actions';

import { dataSourceDeserialize, dataSourceListingFilterSerialize, dataSourceSerialize } from './serdes';

export const cloneDataSource = (dataSource: Maybe<DataSource> = Maybe.None()) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceStartEditing(dataSource.map((ds) => ({ ...ds, name: `clone-${ds.name}`, id: '' }))));
};

export const editDataSource = (dataSource: Maybe<DataSource> = Maybe.None()) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceStartEditing(dataSource));
};

export const editDataSourceName = (name: string) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceEditName(name));
};

export const editDataSourceCompany = (company: Maybe<Company>) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceEditCompany(company));
};

export const editDataSourceQuery = (query: string) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceEditQuery(query));
};

export const deleteDataSourceParameter = (name: string) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceParameterDelete(name));
};

export const editDataSourceParameterName = (name: string) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceParameterEditName(name));
};

export const editDataSourceParameterType = (type: DataSourceParameterType) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceParameterEditType(type));
};

export const editDataSourceParameterIsOptional = (isOptional: boolean) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceParameterEditIsOptional(isOptional));
};

export const saveDataSourceParameter = (parameter: DataSourceParameter) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceParameterAdd(parameter));
};

export const completeDataSourceEdit = (dataSource: Maybe<DataSource> = Maybe.None()) => (dispatch: Dispatch<any>) => {
  dispatch(dataSourceCompleteEditing(dataSource));
};

export const fetchDataSources = (
  filter: Maybe<DataSourcesListFilter>,
  page: number,
  sizePerPage: number,
  sort: Maybe<DataSourcesListSort>,
  logger: Logger = logdown('datasources:data:actions:fetchDataSources'),
) => (dispatch: Dispatch<any>, getState: () => State) => {
  // logger.log(`RETRIEVING PAGE ${page + 1} SIZE ${sizePerPage}`);
  dispatch(dataSourcesRequest(filter, sizePerPage, sort));
  const params = dataSourceListingFilterSerialize(filter.getOrElse({ created: None(), updated: None() }), page, sizePerPage, sort);

  logger.log(`DataSource lookup filter ${JSON.stringify(params, undefined, 4)}`);

  const companies = getState().companiesListing.companies;

  axios({
    method: 'get',
    url: `${config.services.dacqs.api.url}/datasource/list`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params,
  })
    .then((result) => result.data as IDataSourcesListResponse)
    .then((dataSourcesData) => {
      logger.log(`NEWPAGE=>${dataSourcesData.page}`);
      dispatch(
        dataSourcesReceive(
          dataSourcesData.dataSources.map((dataSource) => dataSourceDeserialize(dataSource, companies)),
          page,
          dataSourcesData.total,
        ),
      );
    })
    .catch((error) => {
      dispatch(dataSourcesReceiveError(error));
      showErrorNotification(error)(dispatch);
    });
};

export const saveDataSource = (dataSource: DataSource, createNew = false, logger: Logger = logdown('datasources:data:actions:saveDataSource')) => (
  dispatch: Dispatch<any>,
  getState: any,
) => {
  const state: State = getState();
  const dataSourceApiEntity = dataSourceSerialize(dataSource);
  logger.log(`dataSourceApiEntity => ${JSON.stringify(dataSourceApiEntity)}`);
  dispatch(dataSourceStartSaving());
  const endpointIdSuffix = createNew ? '' : `/${dataSource.id}`;
  axios({
    method: createNew ? 'post' : 'put',
    url: `${config.services.dacqs.api.url}/datasource${endpointIdSuffix}?companyId=${dataSource.company.map((c) => c.id).orUndefined()}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: dataSourceApiEntity,
  })
    .then((response) => dataSourceDeserialize(response.data as IDataSourceResponse, state.companiesListing.companies))
    .then((savedDataSource) => {
      dispatch(dataSourceDoneSaving(savedDataSource));
      dispatch(dataSourceCompleteEditing(Maybe.Some(savedDataSource)));
    })
    .catch((error) => {
      dispatch(dataSourceFailSaving(error));
      showErrorNotification(error)(dispatch);
    });
};

export const deleteDataSource = (dataSourceId: string, logger: Logger = logdown('datasources:data:actions:deleteDataSource')) => (
  dispatch: Dispatch<any>,
) => {
  logger.log(`dataSourceId => ${dataSourceId}`);
  dispatch(dataSourceStartDeleting(dataSourceId));
  axios({
    method: 'delete',
    url: `${config.services.dacqs.api.url}/datasource/${dataSourceId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(() => {
      dispatch(dataSourceDoneDeleting(dataSourceId));
    })
    .catch((error) => {
      dispatch(dataSourceFailDeleting(dataSourceId, error));
      showErrorNotification(error)(dispatch);
    });
};

export const executeDataSource = (dataSourceId: string, logger: Logger = logdown('datasources:data:actions:executeDataSource')) => (
  dispatch: Dispatch<any>,
) => {
  logger.log(`dataSourceId => ${JSON.stringify(dataSourceId)}`);
  dispatch(dataSourceExecRequest());
  axios({
    method: 'post',
    url: `${config.services.dacqs.api.url}/datasource/${dataSourceId}/execute`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.data)
    .then((result) => {
      logger.log(`Data Source execution result: ${JSON.stringify(result.data, undefined, 4)}`);
      alert(`Data Source execution result: ${JSON.stringify(result.data, undefined, 4)}`);
      dispatch(dataSourceExecReceive(result.data));
    })
    .catch((error) => {
      dispatch(dataSourceExecReceiveError(error));
      showErrorNotification(error)(dispatch);
    });
};

export const updateDataSourceListingFilter = (
  filter: Maybe<DataSourcesListFilter>,
  page: number,
  sizePerPage: number,
  logger: Logger = logdown('datasources:data:actions:updateDataSourceListingFilter'),
) => (dispatch: Dispatch<any>, getState: any) => {
  logger.log(`filter => ${JSON.stringify(filter.orUndefined())}; page => ${page}; sizePerPage => ${sizePerPage}`);
  dispatch(dataSourceListingFilterUpdate(filter, page, sizePerPage));
};

export const updateDataSourceListingSort = (
  sort: Maybe<DataSourcesListSort>,
  logger: Logger = logdown('datasources:data:actions:updateDataSourceListingSort'),
) => (dispatch: Dispatch<any>) => {
  logger.log(`listingSort => ${JSON.stringify(sort.orUndefined())}`);
  dispatch(dataSourceListingSortUpdate(sort));
};

export const resetDataSourcesState = () => (dispatch: Dispatch<any>) => {
  dispatch(dataSourcesReset());
};

export const dropdownInputOptionsFromDataSourcesList = async (query?: string): Promise<{ id: string | number; label: string }[]> => {
  return axios({
    method: 'get',
    url: `${config.services.dacqs.api.url}/datasource/list`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {
      sort: 'name:asc',
      limit: 10,
      name: query,
      from: new Date(0).toISOString(),
    },
  }).then((result) => (result.data as IDataSourcesListResponse).dataSources.map((ds) => ({ id: ds.id, label: ds.name })));
};

export const dropdownInputOptionsFromDataSourceExecResult = (dataSourceId: string) => async (
  query?: string,
): Promise<{ id: string | number; label: string; group?: { id: string | number; label: string } }[]> => {
  return executeDataSourceApiCall(dataSourceId).then((data) =>
    data.results.map((r: any) => ({ id: parseInt(r.id), label: r.label, group: r.group })),
  );
};

// todo : use this in fetchDataSources
const executeDataSourceApiCall = async (dataSourceId: string): Promise<any> => {
  return axios({
    method: 'post',
    url: `${config.services.dacqs.api.url}/datasource/${dataSourceId}/execute`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {},
  }).then((result) => result.data.data);
};
