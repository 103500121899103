import { Maybe, Some, None } from 'monet';
import { Reducer } from 'redux';
import logger from 'redux-logger';

import {
  ReportInteractiveRenderingAction,
  ReportLiveLinkGenerationAction,
  ReportRenderingAction,
  ReportRenderingActionType,
} from '../actions/reportRenderer.actions';
import { ReportsActionType } from '../actions/reports.actions';
import { INITIAL_REPORTS_STATE } from '../constants';
import { ReportInteractiveRenderingState, ReportLiveLinkGenerationState, ReportRenderingState } from '../entities/reportRendering.entities';

export const reportRenderingReducer: Reducer<ReportRenderingState, ReportRenderingAction> = (
  state = INITIAL_REPORTS_STATE.reportRenderingState,
  action,
) => {
  switch (action.type) {
    case ReportRenderingActionType.START_REPORT_RENDERING:
      return {
        isInProgress: true,
        reportId: Maybe.Some(action.reportId),
        error: Maybe.None(),
      };
    case ReportRenderingActionType.DONE_REPORT_RENDERING:
      return {
        isInProgress: false,
        reportId: Maybe.None(),
        error: Maybe.None(),
      };
    case ReportRenderingActionType.FAIL_REPORT_RENDERING:
      return {
        isInProgress: false,
        reportId: Maybe.None(),
        error: Maybe.fromUndefined(action.error),
      };
    case ReportsActionType.RESET_REPORTS:
      return INITIAL_REPORTS_STATE.reportRenderingState;
    default:
      return state;
  }
};

export const reportInteractiveRenderingReducer: Reducer<ReportInteractiveRenderingState, ReportInteractiveRenderingAction> = (
  state = INITIAL_REPORTS_STATE.reportInteractiveRenderingState,
  action,
) => {
  switch (action.type) {
    case ReportRenderingActionType.START_REPORT_RENDERING_WITH_OPTIONS:
      return {
        isInProgress: true,
        report: Maybe.Some(action.report),
        parameters: action.report.parameters,
        error: Maybe.None(),
      };
    case ReportRenderingActionType.DONE_REPORT_RENDERING_WITH_OPTIONS:
      return {
        isInProgress: false,
        report: Maybe.None(),
        parameters: Maybe.None(),
        error: Maybe.None(),
      };
    case ReportRenderingActionType.FAIL_REPORT_RENDERING_WITH_OPTIONS:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.fromUndefined(action.error),
      };
    case ReportRenderingActionType.REPORT_RENDERING_WITH_OPTIONS_EDIT_REPORT_PARAMETER_VALUE:
      return {
        ...state,
        parameters: state.parameters
          .orElse(Maybe.Some([]))
          .map((parameters) => ({
            parameters,
            parameter: parameters.find((p) => p.name === action.name),
          }))
          .map(({ parameters, parameter }) => {
            if (parameter !== undefined) {
              parameters.splice(parameters.indexOf(parameter), 1, {
                ...parameter,
                initValue: action.initValue,
                initFn: Maybe.None(),
              });
            }
            return parameters;
          }),
      };
    case ReportRenderingActionType.REPORT_RENDERING_WITH_OPTIONS_EDIT_REPORT_PARAMETER_FUNC:
      return {
        ...state,
        parameters: state.parameters
          .orElse(Maybe.Some([]))
          .map((parameters) => ({
            parameters,
            parameter: parameters.find((p) => p.name === action.name),
          }))
          .map(({ parameters, parameter }) => {
            if (parameter !== undefined) {
              parameters.splice(parameters.indexOf(parameter), 1, {
                ...parameter,
                initFn: action.initFn,
                initValue: Maybe.None(),
              });
            }
            return parameters;
          }),
      };
    case ReportRenderingActionType.COMPLETE_REPORT_RENDERING_WITH_OPTIONS:
      return {
        ...state,
        isInProgress: false,
        report: Maybe.None(),
        parameters: Maybe.None(),
        error: Maybe.None(),
      };
    case ReportsActionType.RESET_REPORTS:
      return INITIAL_REPORTS_STATE.reportInteractiveRenderingState;
    default:
      return state;
  }
};

export const reportLiveLinkGenerationReducer: Reducer<ReportLiveLinkGenerationState, ReportLiveLinkGenerationAction> = (
  state = INITIAL_REPORTS_STATE.reportLiveLinkGenerationState,
  action,
) => {
  switch (action.type) {
    case ReportRenderingActionType.START_REPORT_LIVE_LINK_GENERATION:
      return {
        reportId: Some(action.reportId),
        isInProgress: true,
        error: Maybe.None(),
      };
    case ReportRenderingActionType.DONE_REPORT_LIVE_LINK_GENERATION:
      return {
        reportId: None(),
        isInProgress: false,
        error: Maybe.None(),
      };
    case ReportRenderingActionType.FAIL_REPORT_LIVE_LINK_GENERATION:
      return {
        reportId: None(),
        isInProgress: false,
        error: Maybe.fromUndefined(action.error),
      };
    case ReportsActionType.RESET_REPORTS:
      return INITIAL_REPORTS_STATE.reportLiveLinkGenerationState;
    default:
      return state;
  }
};
