import logdown from 'logdown';
import React from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { visibleOnlyTo } from '../../auth/helpers';
import { State } from '../../reducers';

import { routesList } from './routes';

const logo = require('../../assets/img/InCommand_Logo_Primary_Icon.png');

class Component extends React.Component<Props, {}> {
  render() {
    return (
      <div className="sidebar" data-color="black" data-active-color="info">
        <div className="logo">
          <a href="https://new.sfrportal.com" className="simple-text text-center">
            <div className="logo-img">
              <img src={logo.default} alt="react-logo" />
            </div>
            Reporting System
          </a>
        </div>
        <div className="sidebar-wrapper">
          <Nav>
            {routesList.map((route) => {
              const ProtectedNavItem = visibleOnlyTo(route.allowedForRoles, NavItem);
              return (
                <ProtectedNavItem active={route.name === this.props.routes.activeRouteName} key={route.name + '-nav-item'}>
                  <NavLink id={`nav-link-${route.name}`} to={route.layout + route.path} key={route.name + '-nav-link'}>
                    <i className={route.icon} />
                    <p>{route.name}</p>
                  </NavLink>
                  <footer
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      margin: '5px 5px',
                      color: '#ccc',
                      height: '40px',
                      fontSize: 10,
                    }}
                  ></footer>
                </ProtectedNavItem>
              );
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => bindActionCreators({}, dispatch);

const mapStateToProps = (state: State) => ({
  user: state.user,
  routes: state.routes,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
