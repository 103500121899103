import 'prismjs/components/prism-clike';
// @ts-ignore
import 'prismjs/components/prism-javascript';
import React from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';
import { makeServiceTimezoneKeyName, ServicesAvailable } from '../user';

import { renderReport, completeReportInteractiveRendering } from './data/actions';
import ReportRendererParametersEditor from './ReportInteractiveRendererParametersEditor';

const ReportParametersListFormGroup = (props: Props) => (
  <FormGroup>
    <Label for="query">Parameters</Label>
    <div>
      <ReportRendererParametersEditor />
    </div>
  </FormGroup>
);

class Component extends React.Component<Props> {
  serverTimezone = 'Etc/UTC';
  componentDidMount() {
    const serverTimezoneStored = localStorage.getItem(makeServiceTimezoneKeyName(ServicesAvailable.POINTER_COD));
    if (serverTimezoneStored) {
      this.serverTimezone = serverTimezoneStored;
    }
  }
  public render() {
    return (
      <Modal
        id="report-editor-modal"
        data-testid="report-editor-modal"
        size="xl"
        isOpen={this.props.reportInteractiveRendering.isInProgress}
        toggle={this.close.bind(this)}
      >
        <ModalHeader>
          <EllipsisText
            data-testid="report-editor-header"
            id="report-editor-header"
            text={`${this.props.reportInteractiveRendering.report.map((r) => r.name || '...').getOrElse('...')}`}
            length={45}
          />
        </ModalHeader>
        <ModalBody>
          <Form>
            <ReportParametersListFormGroup {...this.props} />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            id="report-editor-render-button"
            data-testid="report-editor-render-button"
            color="success"
            disabled={this.props.reportRendering.isInProgress}
            onClick={this.renderReport.bind(this)}
          >
            Render
          </Button>
          <Button
            id="report-editor-cancel-button"
            data-testid="report-editor-cancel-button"
            color="secondary"
            disabled={this.props.reportRendering.isInProgress}
            onClick={this.close.bind(this)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected renderReport(e: any) {
    e.preventDefault();
    this.props.reportInteractiveRendering.report.forEach((report) =>
      this.props.renderReport(report, this.props.reportInteractiveRendering.parameters),
    );
  }

  protected close(e: any) {
    e.preventDefault();
    this.props.completeReportInteractiveRendering();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      renderReport,
      completeReportInteractiveRendering,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  reportRendering: state.reportRendering,
  reportInteractiveRendering: state.reportInteractiveRendering,
  companiesListing: state.companiesListing,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
