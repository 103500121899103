import logdown from 'logdown';
import { Maybe, Some } from 'monet';
import { Reducer } from 'redux';

import { ReportsActionType, ReportsAction } from '../actions/reports.actions';
import { INITIAL_REPORTS_STATE } from '../constants';
import { ReportsListingState } from '../entities/reports.entities';

const logger = logdown('reports:redux:reducers:reportsListingReducer');

export const reportsListingReducer: Reducer<ReportsListingState, ReportsAction> = (state = INITIAL_REPORTS_STATE.reportsListingState, action) => {
  switch (action.type) {
    case ReportsActionType.REQUEST_REPORTS:
      return {
        ...state,
        isInProgress: true,
        reports: Maybe.None(),
        total: 0,
        error: Maybe.None(),
      };
    case ReportsActionType.RECEIVE_REPORTS:
      return {
        ...state,
        isInProgress: false,
        reports: action.reports.length ? Some(action.reports) : Maybe.None(),
        page: action.page,
        total: action.total,
        error: Maybe.None(),
      };
    case ReportsActionType.RECEIVE_REPORTS_ERROR:
      return {
        ...state,
        isInProgress: false,
        reports: Maybe.None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Maybe.Some(action.error),
      };
    case ReportsActionType.UPDATE_REPORT_LISTING_FILTER:
      return {
        ...state,
        page: action.page,
        filter: action.filter,
        sizePerPage: action.sizePerPage,
      };
    case ReportsActionType.UPDATE_REPORT_SORT_LIST:
      return {
        ...state,
        sort: action.sort,
      };
    case ReportsActionType.RESET_REPORTS:
      return INITIAL_REPORTS_STATE.reportsListingState;
    default:
      return state;
  }
};
