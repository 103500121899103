/* eslint-disable id-blacklist */
import React, { useState } from 'react';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import * as uuid from 'uuid';

interface ConfirmationPopoverOptions {
  popoverCaption?: string;
  popoverText?: string;
  popoverOkButton?: string;
  popoverCancelButton?: string;
}

interface ConfirmationPopoverProps extends ConfirmationPopoverOptions {
  target: string;
  onConfirmClick: React.MouseEventHandler<any>;
}

const ConfirmationPopover = (props: ConfirmationPopoverProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const confirm = (e: any) => {
    props.onConfirmClick(e);
    toggle();
  };

  return (
    <div>
      <Popover isOpen={popoverOpen} target={props.target} toggle={toggle}>
        {props.popoverCaption && <PopoverHeader>{props.popoverCaption}</PopoverHeader>}
        <PopoverBody>
          {props.popoverText}
          <Button className="confirmation-ok" color="success" size="sm" onClick={confirm}>
            {props.popoverOkButton || 'Ok'}
          </Button>
          <Button className="confirmation-cancel" color="default" size="sm" onClick={() => toggle()}>
            {props.popoverCancelButton || 'Cancel'}
          </Button>
        </PopoverBody>
      </Popover>
    </div>
  );
};

export const withClickConfirmation = (WrappedComponent: any) =>
  class extends React.Component<any> {
    protected wrappedElementId: string;

    public constructor(props: any) {
      super(props);
      this.wrappedElementId = props.id;
    }

    public render() {
      // Wraps the input component in a container, without mutating it. Good!
      const wrappedElementTarget = this.wrappedElementId || `confirmation-wrapped-${uuid.v4()}`;
      const props = {
        ...this.props,
      };
      // Erase unknown options to avoid React warning
      delete props.popoverCaption;
      delete props.popoverText;
      delete props.popoverOkButton;
      delete props.popoverCancelButton;
      return (
        <span>
          <WrappedComponent {...props} id={wrappedElementTarget} onClick={() => false} />
          <ConfirmationPopover
            target={wrappedElementTarget}
            onConfirmClick={this.props.onClick}
            popoverCaption={this.props.popoverCaption}
            popoverText={this.props.popoverText}
            popoverOkButton={this.props.popoverOkButton}
            popoverCancelButton={this.props.popoverCancelButton}
          />
        </span>
      );
    }
  };
