import { deserializeTemplateType } from '@serverfarm/rs-commons';
import logdown, { Logger } from 'logdown';
import moment from 'moment';
import { Maybe } from 'monet';
import { createTransform, Transform } from 'redux-persist';

import { Template } from '../entities';

import { TemplatesListingState, TemplateEditingState, TemplateSavingState, TemplateDeletingState } from './entities';

/**
 * Entities SerDes
 */

const templateDeserialize = (ts: any): Template => ({
  id: ts.id,
  name: ts.name,
  company: Maybe.fromUndefined(ts.company),
  template: ts.template,
  templateType: deserializeTemplateType(ts.templateType),
  updated: Maybe.fromUndefined(ts.updated).map((updated) => moment(updated).toDate()),
  created: moment(ts.created).toDate(),
});

const templateSerialize = (t: Template): any => ({
  id: t.id,
  name: t.name,
  company: t.company.orUndefined(),
  template: t.template,
  templateType: t.templateType.toString(),
  updated: t.updated.map((updated) => updated.toISOString()).orUndefined(),
  created: t.created.toISOString(),
});

/**
 * Transformations
 */

export const createTemplatesListTransform = (
  logger: Logger = logdown('redux-persist:transform:templatesListing'),
): Transform<TemplatesListingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const serialized = {
        ...subState,
        templates: subState.templates.map((templates) => templates.map(templateSerialize)).orUndefined(),
        sort: subState.sort.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(subState.sort.orUndefined(), undefined, 4)} ${JSON.stringify(serialized, undefined, 4)}`);
      return serialized;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        templates: Maybe.fromUndefined(state.templates).map((templates) => templates.map(templateDeserialize)),
        sort: Maybe.fromUndefined(state.sort),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['templatesListing'] },
  );

export const createTemplateEditTransform = (
  logger: Logger = logdown('redux-persist:transform:templateEditing'),
): Transform<TemplateEditingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        template: subState.template.map(templateSerialize).orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        template: Maybe.fromUndefined(state.template).map(templateDeserialize),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['templateEditing'] },
  );

export const createTemplateSavingTransform = (
  logger: Logger = logdown('redux-persist:transform:templateSaving'),
): Transform<TemplateSavingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['templateSaving'] },
  );

export const createTemplateDeletingTransform = (
  logger: Logger = logdown('redux-persist:transform:templateDeleting'),
): Transform<TemplateDeletingState, any> =>
  createTransform(
    (subState, key, state) => {
      const result = {
        ...subState,
        templateId: subState.templateId.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    (state, key, rawState) => {
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        templateId: Maybe.fromUndefined(state.templateId),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
    },
    { whitelist: ['templateDeleting'] },
  );
