import logdown from 'logdown';
import moment from 'moment-timezone';
import { Maybe } from 'monet';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import { Button, Popover, PopoverBody, Form, FormGroup } from 'reactstrap';

import { DATE_TIME_FORMAT_DEFAULT } from './dateTime';
import { TzDropdown } from './tzDropdown';

export type onCalendarSave = (dateRange?: Date, timezone?: string) => void;
export type onCalendarReset = () => void;

export interface CalendarProps {
  id: string;
  value: Maybe<Date>;
  showTimezone?: boolean;
  timezone?: string;
  onSave: onCalendarSave;
  onReset: onCalendarReset;
}

const DEFAULT_BUTTON_TEXT = 'Select a date';

const logger = logdown('component:ServerfarmCalendar');

export const ServerfarmCalendar = (props: CalendarProps) => {
  const initialPopoverText = props.value
    .map((value) => {
      return `${moment(value).format(`${DATE_TIME_FORMAT_DEFAULT}`)}  ${props.timezone}`;
    })
    .getOrElse(DEFAULT_BUTTON_TEXT);

  const initialDate: Date | undefined = props.value.map((value) => value).orUndefined();

  const [popOverOpen, setOpen] = useState(false);
  const [date, setDate] = useState(initialDate);
  const [tz, setTz] = useState();

  const toggle = () => setOpen(!popOverOpen);

  const onSave = () => {
    props.onSave(date, tz);
    toggle();
  };

  const onReset = () => {
    setDate(initialDate);
    props.onReset();
    toggle();
  };

  const cancel = () => {
    toggle();
  };

  const handleChange = (result: any) => {
    setDate(result);
  };

  return (
    <div>
      <Button id={props.id} className={`${props.id}-toggle`} color={props.value.isSome() ? 'success' : 'default'} size="sm" onClick={toggle}>
        {initialPopoverText}
      </Button>
      <Popover className="popover-calendar" isOpen={popOverOpen} target={props.id} toggle={toggle}>
        <PopoverBody>
          <Form>
            <FormGroup>
              <Calendar date={date as any} onChange={handleChange} />
              {props.showTimezone ? (
                <TzDropdown
                  id={props.id}
                  value={props.timezone}
                  onTzChange={(r) => {
                    setTz(r as any);
                  }}
                />
              ) : (
                ''
              )}
            </FormGroup>
          </Form>
          <Form inline>
            <Button className={`${props.id}-save`} disabled={!date} color="primary" size="sm" onClick={() => onSave()}>
              Save
            </Button>
            <Button className={`${props.id}-clear`} disabled={!date} color="danger" size="sm" onClick={() => onReset()}>
              Clear
            </Button>
            {/* <Button>Submit</Button> */}
            <Button className={`${props.id}-cancel`} color="default" size="sm" onClick={() => cancel()}>
              Cancel
            </Button>
          </Form>
        </PopoverBody>
      </Popover>
    </div>
  );
};
