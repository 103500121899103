import { combineReducers } from 'redux';

import {
  dataSourceDeletingReducer,
  DataSourceDeletingState,
  dataSourceEditingReducer,
  DataSourceEditingState,
  dataSourceParameterEditingReducer,
  DataSourceParameterEditingState,
  dataSourceSavingReducer,
  DataSourceSavingState,
  dataSourcesListingReducer,
  DataSourcesListingState,
} from './components/datasources';
import { RoutesState, SideBarState, NavBarState, routesReducer, sideBarReducer, navBarReducer } from './components/navigation';
import { notificationsReducer, NotificationState } from './components/notification';
import {
  reportDataSourcesListingReducer,
  ReportDataSourcesListingState,
  reportDeletingReducer,
  ReportDeletingState,
  reportEditingReducer,
  ReportEditingState,
  reportRenderingReducer,
  reportInteractiveRenderingReducer,
  ReportRenderingState,
  ReportInteractiveRenderingState,
  reportSavingReducer,
  reportLiveLinkGenerationReducer,
  ReportSavingState,
  reportsListingReducer,
  ReportsListingState,
  reportTemplatesListingReducer,
  ReportTemplatesListingState,
  RenderedReportsHistoryState,
  renderedReportViewerReducer,
  renderedReportsHistoryReducer,
  RenderedReportViewerState,
  ReportLiveLinkGenerationState,
} from './components/reports';
import {
  TemplatesListingState,
  TemplateEditingState,
  TemplateSavingState,
  TemplateDeletingState,
  templatesListingReducer,
  templateEditingReducer,
  templateDeletingReducer,
  templateSavingReducer,
} from './components/templates';
import { companiesListingReducer, CompaniesListingState, userReducer, UserState } from './components/user';

export interface State {
  user: UserState;
  companiesListing: CompaniesListingState;
  routes: RoutesState;
  sideBar: SideBarState;
  navBar: NavBarState;
  notifications: NotificationState[];
  dataSourcesListing: DataSourcesListingState;
  dataSourceEditing: DataSourceEditingState;
  dataSourceSaving: DataSourceSavingState;
  dataSourceDeleting: DataSourceDeletingState;
  dataSourceParameterEditing: DataSourceParameterEditingState;
  templatesListing: TemplatesListingState;
  templateEditing: TemplateEditingState;
  templateSaving: TemplateSavingState;
  templateDeleting: TemplateDeletingState;
  reportsListing: ReportsListingState;
  reportEditing: ReportEditingState;
  reportSaving: ReportSavingState;
  reportLiveLinkGeneration: ReportLiveLinkGenerationState;
  reportRendering: ReportRenderingState;
  reportInteractiveRendering: ReportInteractiveRenderingState;
  reportDeleting: ReportDeletingState;
  reportDataSourcesListing: ReportDataSourcesListingState;
  reportTemplatesListing: ReportTemplatesListingState;
  renderedReportsHistory: RenderedReportsHistoryState;
  renderedReportViewer: RenderedReportViewerState;
}

export default combineReducers<State>({
  user: userReducer,
  companiesListing: companiesListingReducer,
  routes: routesReducer,
  sideBar: sideBarReducer,
  navBar: navBarReducer,
  notifications: notificationsReducer,
  dataSourcesListing: dataSourcesListingReducer,
  dataSourceEditing: dataSourceEditingReducer,
  dataSourceSaving: dataSourceSavingReducer,
  dataSourceDeleting: dataSourceDeletingReducer,
  dataSourceParameterEditing: dataSourceParameterEditingReducer,
  templatesListing: templatesListingReducer,
  templateEditing: templateEditingReducer,
  templateSaving: templateSavingReducer,
  templateDeleting: templateDeletingReducer,
  reportsListing: reportsListingReducer,
  reportEditing: reportEditingReducer,
  reportSaving: reportSavingReducer,
  reportLiveLinkGeneration: reportLiveLinkGenerationReducer,
  reportRendering: reportRenderingReducer,
  reportInteractiveRendering: reportInteractiveRenderingReducer,
  reportDeleting: reportDeletingReducer,
  reportDataSourcesListing: reportDataSourcesListingReducer,
  reportTemplatesListing: reportTemplatesListingReducer,
  renderedReportsHistory: renderedReportsHistoryReducer,
  renderedReportViewer: renderedReportViewerReducer,
});
