// @ts-ignore
import { TemplateType } from '@serverfarm/rs-commons';
import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
// @ts-ignore
import EllipsisText from 'react-ellipsis-text';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { State } from '../../reducers';

import { closeRenderReportViewer } from './data/actions/renderedReportViewer.actions';

const DownloadAs = (props: Props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown direction="up" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret color="primary">
        Download as
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem href={props.renderedReportViewer.renderedReport.map((r) => r.permalinks.pdfDownload).orUndefined()}>PDF</DropdownItem>
        <DropdownItem href={props.renderedReportViewer.renderedReport.map((r) => r.permalinks.xlsDownload).orUndefined()}>EXCEL</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

class Component extends React.Component<Props, {}> {
  public render() {
    const renderedReportId = this.props.renderedReportViewer.renderedReport.map((r) => r.id).orUndefined();
    const ids = {
      default: `default-button-${renderedReportId}`,
    };
    return (
      <Modal
        id="rendered-report-viewer-modal"
        data-testid="rendered-report-viewer-modal"
        // style={{ maxWidth: 'none', height: '100%', width: '100%', margin: '0' }}
        className="full-screen-modal rendered-report-viewer-modal"
        contentClassName="h-100 w-100"
        isOpen={this.props.renderedReportViewer.isInProgress}
        toggle={this.cancel.bind(this)}
      >
        <ModalHeader data-testid="report-editor-modal-header">
          <EllipsisText
            className="rendered-report-viewer-modal"
            text={`${this.props.renderedReportViewer.report.map((r) => r.name).orJust('Report Name')}`}
            length={80}
          />
        </ModalHeader>
        <ModalBody>
          {this.props.renderedReportViewer.report.flatMap((r) => r.template).exists((t) => t.templateType === TemplateType.JSON) ? (
            <span>
              <Button
                className="json-download"
                id={ids.default}
                color="info"
                size="sm"
                href={this.props.renderedReportViewer.renderedReport.map((r) => r.permalinks.default).orUndefined()}
                target="_blank"
              >
                <Icon.Braces className="sm" />
              </Button>{' '}
              <UncontrolledTooltip target={ids.default}>Download as JSON</UncontrolledTooltip>{' '}
            </span>
          ) : (
            <iframe
              src={this.props.renderedReportViewer.renderedReport.map((r) => r.permalinks.default).orUndefined()}
              className="h-100 w-100 rendered-report-viewer-iframe"
              style={{ border: 'none' }}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <DownloadAs {...this.props} />{' '}
          <Button
            className="report-editor-cancel-button"
            data-testid="report-editor-cancel-button"
            color="secondary"
            onClick={this.cancel.bind(this)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  protected cancel(e: any) {
    e.preventDefault();
    this.props.closeRenderReportViewer();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      closeRenderReportViewer,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
  renderedReportViewer: state.renderedReportViewer,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
