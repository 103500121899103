import { IDataSourcesListResponse, IReportResponse, IReportsListResponse, ITemplatesListResponse } from '@serverfarm/pointer-cod-api';
import logdown, { Logger } from 'logdown';
import { Maybe, None } from 'monet';
import { Dispatch } from 'redux';

import { UserRole } from '../../../../auth/entities';
import config from '../../../../config';
import { State } from '../../../../reducers';
import axios from '../../../../services/axios';
import { dataSourceDeserialize } from '../../../datasources/data/serdes';
import { showErrorNotification } from '../../../notification';
import { templateDeserialize } from '../../../templates/data/serdes';
import { Report, ReportsListFilter, ReportsListSort } from '../../entities';
import {
  reportsRequest,
  reportsReceive,
  reportsReceiveError,
  reportDataSourcesRequest,
  reportDataSourcesReceive,
  reportDataSourcesReceiveError,
  reportTemplatesRequest,
  reportTemplatesReceive,
  reportTemplatesReceiveError,
  reportStartSaving,
  reportDoneSaving,
  reportCompleteEditing,
  reportFailSaving,
  reportStartDeleting,
  reportDoneDeleting,
  reportFailDeleting,
  reportListingUpdateFilter,
  reportListingSortUpdate,
  reportsReset,
} from '../../redux/actions';
import { reportListFilterSerialize, reportDeserialize, reportSerialize } from '../serdes';

import { openRenderReportViewerCsv, openRenderReportViewerJson, openRenderReportViewer } from './renderedReportViewer.actions';

export const fetchReports = (
  filter: Maybe<ReportsListFilter>,
  page: number,
  sizePerPage: number,
  sort: Maybe<ReportsListSort>,
  logger: Logger = logdown('reports:data:actions:fetchReports'),
) => (dispatch: Dispatch<any>, getState: () => State) => {
  dispatch(reportsRequest(filter, sizePerPage, sort));

  const serializedFilter = reportListFilterSerialize(filter.getOrElse({ created: None(), updated: None(), lastRendered: None() }));
  const companies = getState().companiesListing.companies;

  axios({
    method: 'get',
    url: `${config.services.dacqs.api.url}/report/list`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {
      id: serializedFilter.id,
      from: serializedFilter.from ? serializedFilter.from : new Date(0).toISOString(),
      to: serializedFilter.to,
      fromUpdated: serializedFilter.fromUpdated,
      toUpdated: serializedFilter.toUpdated,
      fromLastRendered: serializedFilter.fromLastRendered,
      toLastRendered: serializedFilter.toLastRendered,
      page: page + 1,
      limit: sizePerPage,
      name: serializedFilter.name,
      templateName: serializedFilter.templateName,
      dataSourceName: serializedFilter.dataSourceName,
      companyIds: serializedFilter.companyIds,
      sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
    },
  })
    .then((response) => response.data as IReportsListResponse)
    .then((reportsData) => {
      // const dataSourceIds = reportsData.reports.map((r) => r.dataSourceId);
      // const templatesIds = reportsData.reports.map((r) => r.templateId);

      return Promise.all([
        // todo : parametrize the call by 'dataSourceIds' to only request required
        axios({
          method: 'get',
          url: `${config.services.dacqs.api.url}/datasource/list`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          params: {
            from: new Date(0).toISOString(),
          },
        }),
        // todo : parametrize the call by 'templatesIds' to only request required
        axios({
          method: 'get',
          url: `${config.services.dacqs.api.url}/template/list`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          params: {
            from: new Date(0).toISOString(),
          },
        }),
      ])
        .then((results) => {
          return {
            dataSourcesData: results[0].data as IDataSourcesListResponse,
            templatesData: results[1].data as ITemplatesListResponse,
          };
        })
        .then(({ dataSourcesData, templatesData }) => ({
          ...reportsData,
          reports: reportsData.reports.map((r) => {
            return reportDeserialize(
              r,
              companies,
              dataSourcesData.dataSources.map((ds) => dataSourceDeserialize(ds, companies)),
              templatesData.templates.map((t) => templateDeserialize(t, companies)),
            );
          }),
        }));
    })
    .then((reportsData) => {
      dispatch(reportsReceive(reportsData.reports, page, reportsData.total));
    })
    .catch((error) => {
      dispatch(reportsReceiveError(error));
      showErrorNotification(error)(dispatch);
    });
};

export const fetchReportDataSources = (
  filter: { companyId?: number } = {},
  logger: Logger = logdown('reports:data:actions:fetchReportDataSources'),
) => (dispatch: Dispatch<any>, getState: any) => {
  const { companyId } = filter;

  dispatch(reportDataSourcesRequest(filter));
  const state: State = getState();
  const companies = state.companiesListing.companies;
  logger.log(`fetchReportDataSources companies => ${JSON.stringify(companies)}`);

  axios({
    method: 'get',
    url: `${config.services.dacqs.api.url}/datasource/list`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {
      companyIds: [companyId],
      from: new Date(0).toISOString(),
    },
  })
    .then((response) => response.data as IDataSourcesListResponse)
    .then((dataSourcesData) => dataSourcesData.dataSources.map((ds) => dataSourceDeserialize(ds, companies)))
    .then((result) => {
      return dispatch(reportDataSourcesReceive(result));
    })
    .catch((error) => {
      dispatch(reportDataSourcesReceiveError(error));
      showErrorNotification(error)(dispatch);
    });
};

export const fetchReportTemplates = (filter: { companyId?: number } = {}, logger: Logger = logdown('reports:data:actions:fetchReportTemplates')) => (
  dispatch: Dispatch<any>,
  getState: any,
) => {
  const state: State = getState();
  const companies = state.companiesListing.companies;
  const { companyId } = filter;
  dispatch(reportTemplatesRequest(filter));
  axios({
    method: 'get',
    url: `${config.services.dacqs.api.url}/template/list`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {
      companyIds: [companyId],
      from: new Date(0).toISOString(),
    },
  })
    .then((response) => response.data as ITemplatesListResponse)
    .then((templatesData) => templatesData.templates.map((t) => templateDeserialize(t, companies)))
    .then((templates) => {
      return dispatch(reportTemplatesReceive(templates));
    })
    .catch((error) => {
      dispatch(reportTemplatesReceiveError(error));
      showErrorNotification(error)(dispatch);
    });
};

export const saveReport = (report: Report, newReport = false, logger: Logger = logdown('reports:data:actions:saveReport')) => (
  dispatch: Dispatch<any>,
  getState: any,
) => {
  const serializedReport = reportSerialize(report);
  logger.log(`REPORT SAVE ${JSON.stringify(serializedReport, null, 2)}`);
  const state: State = getState();
  const {
    user: { profile },
  } = state;
  logger.log(`saveReport => ${JSON.stringify(serializedReport, undefined, 4)}`);
  dispatch(reportStartSaving());
  const endpointIdSuffix = newReport ? '' : `/${report.id}`;
  let data: any = {};
  profile.role.contains(UserRole.ADMIN)
    ? (data = serializedReport)
    : (data = {
        name: serializedReport.name,
        parametersInit: serializedReport.parametersInit,
        scheduleSettings: serializedReport.scheduleSettings,
      });
  axios({
    method: newReport ? 'post' : 'put',
    url: `${config.services.dacqs.api.url}/report${endpointIdSuffix}?companyId=${report.company.map((c) => c.id).orUndefined()}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data,
  })
    .then((response) => response.data as IReportResponse)
    .then((savedReport) => {
      return Promise.all([
        // todo : parametrize the call by 'dataSourceIds' to only request required
        axios({
          method: 'get',
          url: `${config.services.dacqs.api.url}/datasource/list`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          params: {
            from: new Date(0).toISOString(),
          },
        }),
        // todo : parametrize the call by 'templatesIds' to only request required
        axios({
          method: 'get',
          url: `${config.services.dacqs.api.url}/template/list`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          params: {
            from: new Date(0).toISOString(),
          },
        }),
      ]).then((results) => {
        return {
          savedReport,
          dataSourcesData: results[0].data as IDataSourcesListResponse,
          templatesData: results[1].data as ITemplatesListResponse,
        };
      });
    })
    .then(({ savedReport, dataSourcesData, templatesData }) => {
      const companies = state.companiesListing.companies;
      const report = reportDeserialize(
        savedReport,
        companies,
        dataSourcesData.dataSources.map((ds) => dataSourceDeserialize(ds, companies)),
        templatesData.templates.map((t) => templateDeserialize(t, companies)),
      );
      dispatch(reportDoneSaving(report));
      dispatch(reportCompleteEditing(Maybe.Some(report)));
    })
    .catch((error) => {
      dispatch(reportFailSaving(error));
      showErrorNotification(error)(dispatch);
    });
};

export const deleteReport = (reportId: string, logger: Logger = logdown('reports:data:actions:deleteReport')) => (
  dispatch: Dispatch<any>,
  getState: any,
) => {
  logger.log(`reportId => ${reportId}`);
  dispatch(reportStartDeleting(reportId));
  axios({
    method: 'delete',
    url: `${config.services.dacqs.api.url}/report/${reportId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(() => {
      dispatch(reportDoneDeleting(reportId));
    })
    .catch((error) => {
      dispatch(reportFailDeleting(reportId, Maybe.Some(error)));
      showErrorNotification(error)(dispatch);
    });
};

export const updateReportListingFilter = (
  filter: Maybe<ReportsListFilter>,
  page: number,
  sizePerPage: number,
  logger: Logger = logdown('reports:data:actions:updateReportListingFilter'),
) => (dispatch: Dispatch<any>, getState: any) => {
  logger.log(`filter => ${JSON.stringify(filter.orUndefined())}`);
  dispatch(reportListingUpdateFilter(filter, page, sizePerPage));
};

export const updateReportListingPages = (
  page: number,
  sizePerPage: number,
  logger: Logger = logdown('reports:data:actions:updateReportListingPages'),
) => (dispatch: Dispatch<any>, getState: any) => {
  const state: State = getState();
  dispatch(
    reportListingUpdateFilter(
      Maybe.fromUndefined(state.reportsListing.filter.getOrElse({ created: None(), updated: None(), lastRendered: None() })),
      page,
      sizePerPage,
    ),
  );
};

export const updateReportListingSort = (sort: Maybe<ReportsListSort>, logger: Logger = logdown('reports:data:actions:updateReportListingSort')) => (
  dispatch: Dispatch<any>,
  getState: any,
) => {
  logger.log(`sort => ${JSON.stringify(sort.orUndefined())}`);
  dispatch(reportListingSortUpdate(sort));
};

export const resetReportsState = () => (dispatch: Dispatch<any>) => {
  dispatch(reportsReset());
};
