export enum UserRole {
  COMMON_USER = 'common_user',
  ADMIN = 'admin',
}

export const userRoleFromString = (userRole: string): UserRole => {
  switch (userRole) {
    case 'common_user':
      return UserRole.COMMON_USER;
    case 'admin':
      return UserRole.ADMIN;
    default:
      throw new Error(`'${userRole}' must be one of [${Object.values(UserRole)}]`);
  }
};
