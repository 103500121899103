import { None, Some } from 'monet';

import { IN_PROGRESS_INITIAL_STATE, PAGINATION_INITIAL_STATE } from '../../redux/constants';
import { Report } from '../entities';

import {
  ReportDataSourcesListingState,
  ReportDeletingState,
  ReportEditingState,
  ReportRenderingState,
  ReportSavingState,
  ReportsListingState,
  ReportTemplatesListingState,
  RenderedReportsHistoryState,
  ReportLiveLinkGenerationState,
  ReportInteractiveRenderingState,
} from './entities';

const report: Report = {
  id: '',
  name: '',
  dataSource: None(),
  template: None(),
  company: None(),
  transformation: None(),
  scheduleSettings: None(),
  parameters: Some([]),
  lastRendered: None(),
  updated: None(),
  created: new Date(),
};

const reportDataSourcesListingState: ReportDataSourcesListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  dataSources: None(),
};

const reportDeletingState: ReportDeletingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  reportId: None(),
};

const reportEditingState: ReportEditingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  isEnableScheduling: false,
  isTransformationCollapsed: false,
  report: None(),
};

const reportRenderingState: ReportRenderingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  reportId: None(),
};

const reportInteractiveRenderingState: ReportInteractiveRenderingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  report: None(),
  parameters: None(),
};

const reportSavingState: ReportSavingState = {
  ...IN_PROGRESS_INITIAL_STATE,
};

const reportLiveLinkGenerationState: ReportLiveLinkGenerationState = {
  ...IN_PROGRESS_INITIAL_STATE,
  reportId: None(),
};

const reportsListingState: ReportsListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  reports: None(),
  filter: None(),
  sort: None(),
};

const reportTemplatesListingState: ReportTemplatesListingState = {
  ...IN_PROGRESS_INITIAL_STATE,
  templates: None(),
};

const renderedReportsHistoryState: RenderedReportsHistoryState = {
  ...IN_PROGRESS_INITIAL_STATE,
  ...PAGINATION_INITIAL_STATE,
  report: None(),
  renderedReports: None(),
  filter: None(),
  sort: None(),
};

export const INITIAL_REPORTS_STATE = {
  report,
  reportDataSourcesListingState,
  reportDeletingState,
  reportEditingState,
  reportRenderingState,
  reportInteractiveRenderingState,
  reportSavingState,
  reportLiveLinkGenerationState,
  reportsListingState,
  reportTemplatesListingState,
  renderedReportsHistoryState,
};
