import { Maybe } from 'monet';
import { Reducer } from 'redux';

import { DataSource } from '../entities';

import {
  ActionType,
  DataSourceDeletingAction,
  DataSourceEditingAction,
  DataSourceParameterEditingAction,
  DataSourceSavingAction,
  DataSourcesFetchAction,
} from './actions';
import { DATASOURCE_INITIAL_STATE } from './constants';
import {
  DataSourceDeletingState,
  DataSourceEditingState,
  DataSourceParameterEditingState,
  DataSourceSavingState,
  DataSourcesListingState,
} from './entities';

const emptyDataSource: DataSource = {
  id: '',
  query: '',
  name: '',
  company: Maybe.None(),
  parameters: Maybe.None(),
  updated: Maybe.None(),
  created: new Date(),
};

export const dataSourcesListingReducer: Reducer<DataSourcesListingState, DataSourcesFetchAction> = (
  state = DATASOURCE_INITIAL_STATE.dataSourcesListingState,
  action,
) => {
  switch (action.type) {
    case ActionType.REQUEST_DATA_SOURCES:
      return {
        ...state,
        isInProgress: true,
        dataSources: Maybe.None(),
        total: 0,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_DATA_SOURCES:
      return {
        ...state,
        isInProgress: false,
        dataSources: action.dataSources.length ? Maybe.Some(action.dataSources) : Maybe.None(),
        page: action.page,
        total: action.total,
        error: Maybe.None(),
      };
    case ActionType.RECEIVE_DATA_SOURCES_ERROR:
      return {
        ...state,
        isInProgress: false,
        dataSources: Maybe.None(),
        page: 0,
        total: 0,
        sort: Maybe.None(),
        error: Maybe.Some(action.error),
      };
    case ActionType.UPDATE_DATASOURCE_LISTING_FILTER:
      return {
        ...state,
        filter: action.filter,
        page: action.page,
        sizePerPage: action.sizePerPage,
      };
    case ActionType.UPDATE_DATASOURCE_SORT_LIST:
      return {
        ...state,
        sort: action.sort,
      };
    case ActionType.RESET_DATA_SOURCES:
      return DATASOURCE_INITIAL_STATE.dataSourcesListingState;
    default:
      return state;
  }
};

export const dataSourceEditingReducer: Reducer<DataSourceEditingState, DataSourceEditingAction> = (
  state = DATASOURCE_INITIAL_STATE.dataSourceEditingState,
  action,
) => {
  switch (action.type) {
    case ActionType.START_DATA_SOURCE_EDITING:
      return {
        ...state,
        isInProgress: true,
        dataSource: action.dataSource.orElse(Maybe.Some(emptyDataSource)),
        error: Maybe.None(),
      };
    case ActionType.EDIT_DATA_SOURCE_NAME:
      return {
        ...state,
        dataSource: state.dataSource.map((t) => ({ ...t, name: action.name })),
      };
    case ActionType.EDIT_DATA_SOURCE_COMPANY:
      return {
        ...state,
        dataSource: state.dataSource.map((dataSource) => ({ ...dataSource, company: action.company })),
      };
    case ActionType.EDIT_DATA_SOURCE_QUERY:
      return {
        ...state,
        dataSource: state.dataSource.map((dataSource) => ({ ...dataSource, query: action.query })),
      };
    case ActionType.ADD_DATA_SOURCE_PARAMETER:
      return {
        ...state,
        dataSource: state.dataSource.map((dataSource) => ({
          ...dataSource,
          parameters: dataSource.parameters
            .orElse(Maybe.Some([]))
            .map((parameters) => (!parameters.find((p) => p.name === action.parameter.name) ? [...parameters, action.parameter] : parameters)),
        })),
      };
    case ActionType.DELETE_DATA_SOURCE_PARAMETER:
      return {
        ...state,
        dataSource: state.dataSource.map((dataSource) => ({
          ...dataSource,
          parameters: dataSource.parameters.map((parameters) => parameters.filter((p) => p.name !== action.name)),
        })),
      };
    case ActionType.COMPLETE_DATA_SOURCE_EDITING:
      return {
        ...state,
        isInProgress: false,
        dataSource: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.RESET_DATA_SOURCES:
      return DATASOURCE_INITIAL_STATE.dataSourceEditingState;
    default:
      return state;
  }
};

export const dataSourceSavingReducer: Reducer<DataSourceSavingState, DataSourceSavingAction> = (
  state = DATASOURCE_INITIAL_STATE.dataSourceSavingState,
  action,
) => {
  switch (action.type) {
    case ActionType.START_DATA_SOURCE_EDITING:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.None(),
      };
    case ActionType.START_DATA_SOURCE_SAVING:
      return {
        ...state,
        isInProgress: true,
        error: Maybe.None(),
      };
    case ActionType.DONE_DATA_SOURCE_SAVING:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.None(),
      };
    case ActionType.FAIL_DATA_SOURCE_SAVING:
      return {
        ...state,
        isInProgress: false,
        error: Maybe.Some(action.error),
      };
    case ActionType.RESET_DATA_SOURCES:
      return DATASOURCE_INITIAL_STATE.dataSourceSavingState;
    default:
      return state;
  }
};

export const dataSourceDeletingReducer: Reducer<DataSourceDeletingState, DataSourceDeletingAction> = (
  state = DATASOURCE_INITIAL_STATE.dataSourceDeletingState,
  action,
) => {
  switch (action.type) {
    case ActionType.START_DATA_SOURCE_DELETING:
      return {
        ...state,
        isInProgress: true,
        dataSourceId: Maybe.Some(action.dataSourceId),
        error: Maybe.None(),
      };
    case ActionType.DONE_DATA_SOURCE_DELETING:
      return {
        ...state,
        isInProgress: false,
        dataSourceId: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.FAIL_DATA_SOURCE_DELETING:
      return {
        ...state,
        isInProgress: false,
        dataSourceId: Maybe.None(),
        error: Maybe.Some(action.error),
      };
    case ActionType.RESET_DATA_SOURCES:
      return DATASOURCE_INITIAL_STATE.dataSourceDeletingState;
    default:
      return state;
  }
};

export const dataSourceParameterEditingReducer: Reducer<DataSourceParameterEditingState, DataSourceParameterEditingAction> = (
  state = DATASOURCE_INITIAL_STATE.dataSourceParameterEditingState,
  action,
) => {
  switch (action.type) {
    case ActionType.EDIT_DATA_SOURCE_PARAMETER_NAME:
      return {
        ...state,
        isInProgress: true,
        parameter: state.parameter.orElse(DATASOURCE_INITIAL_STATE.dataSourceParameter).map((parameter) => ({ ...parameter, name: action.name })),
      };
    case ActionType.EDIT_DATA_SOURCE_PARAMETER_TYPE:
      return {
        ...state,
        isInProgress: true,
        parameter: state.parameter.orElse(DATASOURCE_INITIAL_STATE.dataSourceParameter).map((parameter) => ({ ...parameter, type: action.pType })),
      };
    case ActionType.EDIT_DATA_SOURCE_PARAMETER_IS_OPTIONAL:
      return {
        ...state,
        isInProgress: true,
        parameter: state.parameter
          .orElse(DATASOURCE_INITIAL_STATE.dataSourceParameter)
          .map((parameter) => ({ ...parameter, isOptional: action.isOptional })),
      };
    case ActionType.ADD_DATA_SOURCE_PARAMETER:
      return {
        ...state,
        isInProgress: false,
        parameter: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.COMPLETE_DATA_SOURCE_EDITING:
      return {
        ...state,
        isInProgress: false,
        parameter: Maybe.None(),
        error: Maybe.None(),
      };
    case ActionType.RESET_DATA_SOURCES:
      return DATASOURCE_INITIAL_STATE.dataSourceParameterEditingState;
    default:
      return state;
  }
};
