import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { returntypeof } from 'react-redux-typescript';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Spinner,
  FormText,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { bindActionCreators } from 'redux';

import { State } from '../../reducers';

import { loginUser } from './data/actions';

let toggleHowToGetAccessModal: () => void;

const HowToGetAccessModal = () => {
  const [isOpen, setOpen] = useState(false);

  toggleHowToGetAccessModal = () => setOpen((prevState: boolean) => !prevState);

  return (
    <Modal className="full-screen-modal" contentClassName="h-100 w-100" isOpen={isOpen} toggle={toggleHowToGetAccessModal}>
      <ModalHeader>User Guide: How to get access?</ModalHeader>
      <ModalBody>
        <iframe src="/user_guide_login.pdf" width="100%" height="100%" frameBorder="0"></iframe>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggleHowToGetAccessModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

class LoginPanel extends React.Component<Props> {
  protected howToGetAccessTooltipRef = React.createRef<UncontrolledTooltip>();

  componentDidMount() {
    if (this.howToGetAccessTooltipRef.current) {
      this.howToGetAccessTooltipRef.current.setState({ isOpen: true });
    }
    setTimeout(() => {
      if (this.howToGetAccessTooltipRef.current) {
        this.howToGetAccessTooltipRef.current.setState({ isOpen: false });
      }
    }, 3000);
  }

  render() {
    return (
      <div>
        <Card>
          <CardHeader className="text-center">
            Welcome to
            <br />
            <strong>Reporting System</strong>
          </CardHeader>
          <CardBody>
            <Form>
              <Button id="login-button" color="primary" onClick={() => this.props.loginUser()}>
                Login with InCommand
              </Button>
              <FormText className="text-center">
                <a id="how-to-get-access" href="#" onClick={(e) => toggleHowToGetAccessModal()}>
                  How do I get an access?
                </a>
                <UncontrolledTooltip ref={this.howToGetAccessTooltipRef} placement="bottom" target={'how-to-get-access'}>
                  Click here for a guide explaining how to get an access to the Reporting System
                </UncontrolledTooltip>{' '}
              </FormText>{' '}
            </Form>
          </CardBody>
        </Card>
        <HowToGetAccessModal />
      </div>
    );
  }
}

class Component extends React.Component<Props, {}> {
  public render() {
    return (
      <div className="page-center main-panel-bkg-color">
        {this.props.user.isLoggingIn ? <Spinner style={{ width: '4rem', height: '4rem' }} color="primary" /> : <LoginPanel {...this.props} />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loginUser,
    },
    dispatch,
  );

const mapStateToProps = (state: State) => ({
  user: state.user,
});

const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

type Props = typeof stateProps & typeof dispatchProps;

// @ts-ignore
export default connect<typeof stateProps, typeof dispatchProps, any>(mapStateToProps, mapDispatchToProps)(Component);
