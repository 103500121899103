import logdown, { Logger } from 'logdown';
import { Maybe } from 'monet';
import { Dispatch } from 'react';

import config from '../../../../config';
import { showInfoNotification } from '../../../notification';
import { Report, RenderedReport } from '../../entities';
import { closeRenderedReportViewer, openRenderedReportViewer } from '../../redux/actions/renderedReportViewer.actions';

export const openRenderReportViewer = (
  renderedReport: RenderedReport,
  report: Report,
  logger = logdown('reports:data:actions:openRenderReportViewer'),
) => (dispatch: Dispatch<any>) => {
  // logger.log(`renderedReport => ${JSON.stringify(renderedReport)}; report => ${JSON.stringify(report)}`);
  dispatch(openRenderedReportViewer(renderedReport, report));
};

export const closeRenderReportViewer = () => (dispatch: Dispatch<any>) => {
  dispatch(closeRenderedReportViewer());
};

export const openRenderReportViewerJson = (
  renderedReport: RenderedReport,
  report: Report,
  logger = logdown('reports:data:actions:openRenderReportViewerPlain'),
) => (dispatch: Dispatch<any>) => {
  window.open(renderedReport.permalinks.default, '_blank');
};

export const openRenderReportViewerCsv = (
  renderedReport: RenderedReport,
  report: Report,
  logger = logdown('reports:data:actions:openRenderReportViewerPlain'),
) => (dispatch: Dispatch<any>) => {
  window.open(renderedReport.permalinks.default, '_blank');
};
