import logdown from 'logdown';
import moment from 'moment';
import { Maybe } from 'monet';

import { dataSourceParameterSerialize, dataSourceParameterDeserialize } from '../../../datasources/data/serdes';
import {
  reportParameterFuncDeserialize,
  reportParameterValueDeserialize,
  ReportParameter,
  Report,
  ReportDeliverType,
  ReportDeliverViaEmail,
  RenderedReport,
  RenderedReportsHistoryFilter,
} from '../../entities';

/**
 * Entities SerDes
 */

const logger = logdown('REPORT STORE');

export const reportParameterSerialize = (p: ReportParameter): any => ({
  name: p.name,
  initFn: p.initFn.orUndefined(),
  initValue: p.initValue.orUndefined(),
});

export const reportParameterDeserialize = (rawP: any): ReportParameter => ({
  name: rawP.name,
  initFn: Maybe.fromUndefined(rawP.initFn).map(reportParameterFuncDeserialize({ dataSources: [] })),
  initValue: Maybe.fromUndefined(rawP.initValue).map(reportParameterValueDeserialize),
});

export const reportSerialize = (report: Report): any => ({
  ...report,
  dataSource: report.dataSource
    .map((ds) => ({
      ...ds,
      parameters: ds.parameters.orJust([]).map(dataSourceParameterSerialize),
    }))
    .orUndefined(),
  template: report.template.orUndefined(),
  company: report.company.orUndefined(),
  transformation: report.transformation.orUndefined(),
  parameters: report.parameters
    .map((parameters) =>
      parameters.map((p) => ({
        name: p.name,
        initFn: p.initFn.orUndefined(),
        initValue: p.initValue.orUndefined(),
      })),
    )
    .orUndefined(),
  scheduleSettings: report.scheduleSettings.orUndefined(),
  lastRendered: report.lastRendered.map((lastRendered) => lastRendered.toISOString()).orUndefined(),
  updated: report.updated.map((updated) => updated.toISOString()).orUndefined(),
  created: report.created.toISOString(),
});

export const reportDeserialize = (rawReport: any): Report => ({
  ...rawReport,
  dataSource: Maybe.fromUndefined({
    ...rawReport.dataSource,
    parameters: Maybe.fromUndefined(rawReport.dataSource.parameters.map(dataSourceParameterDeserialize)),
  }),
  template: Maybe.fromUndefined(rawReport.template),
  company: Maybe.fromUndefined(rawReport.company),
  transformation: Maybe.fromUndefined(rawReport.transformation),
  parameters: Maybe.fromUndefined(rawReport.parameters).map((parameters) =>
    parameters.map((p: any) => ({
      name: p.name,
      initFn: Maybe.fromUndefined(p.initFn).map(reportParameterFuncDeserialize({ dataSources: [] })),
      initValue: Maybe.fromUndefined(p.initValue).map(reportParameterValueDeserialize),
    })),
  ),
  scheduleSettings: Maybe.fromUndefined(rawReport.scheduleSettings).map((scheduleSettings) => ({
    schedule: {
      recurring: {
        cron: scheduleSettings.schedule.recurring.cron,
      },
    },
    deliver: scheduleSettings.deliver.map((d: any) => {
      if (d.deliverType === ReportDeliverType.EMAIL.toString()) {
        return {
          deliverType: ReportDeliverType.EMAIL,
          email: (d as { deliverType: ReportDeliverType; email: string }).email,
        } as ReportDeliverViaEmail;
      }
      throw new Error('Only EMAIL delivery type is supported');
    }),
  })),
  lastRendered: Maybe.fromUndefined(rawReport.lastRendered).map((lastRendered) => moment(lastRendered).toDate()),
  updated: Maybe.fromUndefined(rawReport.updated).map((updated) => moment(updated).toDate()),
  created: moment(rawReport.created).toDate(),
});

export const renderedReportSerialize = (rr: RenderedReport): any => ({
  ...rr,
  created: rr.created.toISOString(),
});

export const renderedReportDeserialize = (rawRr: any): RenderedReport => ({
  ...rawRr,
  created: moment(rawRr.created).toDate(),
});

export const renderedReportsHistoryFilterSerialize = (filter: RenderedReportsHistoryFilter): any => ({
  key: filter.key,
  created: filter.created,
});

export const renderedReportsHistoryFilterDeserialize = (rawFilter: any): RenderedReportsHistoryFilter => ({
  key: rawFilter.key,
  created: rawFilter.created,
});
