import logdown, { Logger } from 'logdown';
import { Maybe } from 'monet';
import { Transform, createTransform } from 'redux-persist';

import { RenderedReportViewerState } from '../entities';

import { reportSerialize, reportDeserialize } from './serdes';

export const renderedReportViewerTransform = (
  logger: Logger = logdown('redux-persist:transform:renderedReportHistoryTransform'),
): Transform<RenderedReportViewerState, any> =>
  createTransform(
    (subState, key, state) => {
      const result = {
        ...subState,
        renderedReport: subState.renderedReport.orUndefined(),
        report: subState.report.map(reportSerialize).orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    (state, key, rawState) => {
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        renderedReport: Maybe.fromUndefined(state.renderedReport),
        report: Maybe.fromUndefined(state.report).map(reportDeserialize),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
    },
    { whitelist: ['renderedReportViewer'] },
  );
