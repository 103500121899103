import logdown, { Logger } from 'logdown';
import { Maybe } from 'monet';
import { Transform, createTransform } from 'redux-persist';

import {
  ReportEditingState,
  ReportSavingState,
  ReportDataSourcesListingState,
  ReportTemplatesListingState,
  ReportRenderingState,
  ReportDeletingState,
} from '../entities';

import { reportSerialize, reportDeserialize } from './serdes';

export const createReportEditTransform = (logger: Logger = logdown('redux-persist:transform:reportEditing')): Transform<ReportEditingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        report: subState.report.map(reportSerialize).orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        report: Maybe.fromUndefined(state.report).map(reportDeserialize),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['reportEditing'] },
  );

export const createReportSavingTransform = (logger: Logger = logdown('redux-persist:transform:reportSaving')): Transform<ReportSavingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
    },
    // define which reducers this transform gets called for.
    { whitelist: ['reportSaving'] },
  );

export const createReportDataSourcesListingTransform = (
  logger: Logger = logdown('redux-persist:transform:createReportDataSourcesListing'),
): Transform<ReportDataSourcesListingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        dataSources: subState.dataSources.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        dataSources: Maybe.fromUndefined(state.dataSources),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['reportDataSourcesListing'] },
  );

export const createReportTemplatesListingTransform = (
  logger: Logger = logdown('redux-persist:transform:createReportTemplatesListing'),
): Transform<ReportTemplatesListingState, any> =>
  createTransform(
    // transform state on its way to being serialized and persisted.
    (subState, key, state) => {
      const result = {
        ...subState,
        templates: subState.templates.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    // transform state being rehydrated
    (state, key, rawState) => {
      // convert mySet back to a Set.
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        templates: Maybe.fromUndefined(state.templates),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
      // logger.log(`outboundState after transform: ${JSON.stringify(outboundState, undefined, 4)}`);
      // return res;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['reportTemplatesListing'] },
  );

export const createReportRenderingTransform = (
  logger: Logger = logdown('redux-persist:transform:reportRendering'),
): Transform<ReportRenderingState, any> =>
  createTransform(
    (subState, key, state) => {
      const result = {
        ...subState,
        reportId: subState.reportId.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      logger.log(`serialize: ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    (state, key, rawState) => {
      logger.log(`deserialize: ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        reportId: Maybe.fromUndefined(state.reportId),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
    },
    { whitelist: ['reportRendering'] },
  );

export const createReportDeletingTransform = (
  logger: Logger = logdown('redux-persist:transform:reportDeleting'),
): Transform<ReportDeletingState, any> =>
  createTransform(
    (subState, key, state) => {
      const result = {
        ...subState,
        reportId: subState.reportId.orUndefined(),
        error: subState.error.map((error) => error.message).orUndefined(),
      };
      // logger.log(`serialized (${key.toString()}): ${JSON.stringify(result, undefined, 4)}`);
      return result;
    },
    (state, key, rawState) => {
      // logger.log(`deserialize (${key.toString()}): ${JSON.stringify(state, undefined, 4)}`);
      return {
        ...state,
        isInProgress: false,
        reportId: Maybe.fromUndefined(state.reportId),
        error: Maybe.fromUndefined(state.error).map((message) => new Error(message)),
      };
    },
    { whitelist: ['reportDeleting'] },
  );
