import { AnyAction } from 'redux';

import { Action, ErrorAction } from '../../redux/actions';

import { Company, UserProfile, ServiceVersion, ServicesAvailable } from './entities';

/**
 * Action types
 */

export enum ActionType {
  USER_LOGIN_ATTEMPT = 'USER_LOGIN_ATTEMPT',
  USER_LOGIN_ATTEMPT_COMPLETE = 'USER_LOGIN_ATTEMPT_COMPLETE',
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',

  REQUEST_PROFILE = 'REQUEST_PROFILE',
  RECEIVE_PROFILE_ERROR = 'RECEIVE_PROFILE_ERROR',
  RECEIVE_PROFILE = 'RECEIVE_PROFILE',

  REQUEST_COMPANIES = 'REQUEST_COMPANIES',
  RECEIVE_COMPANIES_ERROR = 'RECEIVE_COMPANIES_ERROR',
  RECEIVE_COMPANIES = 'RECEIVE_COMPANIES',

  REQUEST_SERVICE_VERSION = 'REQUEST_SERVICE_VERSION',
  RECEIVE_SERVICE_VERSION_ERROR = 'RECEIVE_SERVICE_VERSION_ERROR',
  RECEIVE_SERVICE_VERSION = 'RECEIVE_SERVICE_VERSION',

  RESET_USER = 'RESET_USER',
}

/**
 * Profile updates
 */

type RequestProfileAction = Action<ActionType>;

interface ReceiveProfileAction extends Action<ActionType> {
  profile: UserProfile;
}

type ReceiveProfileErrorAction = ErrorAction<ActionType>;

export type ProfileActionObject = AnyAction & RequestProfileAction & ReceiveProfileErrorAction & ReceiveProfileAction;

export const requestProfile = (): RequestProfileAction => ({
  type: ActionType.REQUEST_PROFILE,
});

export const receiveProfile = (profile: UserProfile): ReceiveProfileAction => ({
  type: ActionType.RECEIVE_PROFILE,
  profile,
});

export const receiveProfileError = (error: Error): ReceiveProfileErrorAction => ({
  type: ActionType.RECEIVE_PROFILE_ERROR,
  error,
});

/**
 * Profile companies
 */

type RequestCompaniesAction = Action<ActionType>;

interface ReceiveCompaniesAction extends Action<ActionType> {
  companies: Company[];
}

type ReceiveCompaniesErrorAction = ErrorAction<ActionType>;

export type CompaniesFetchActionObject = AnyAction & RequestCompaniesAction & ReceiveCompaniesErrorAction & ReceiveCompaniesAction;

export const requestCompanies = (): RequestCompaniesAction => ({
  type: ActionType.REQUEST_COMPANIES,
});

export const receiveCompanies = (companies: Company[]): ReceiveCompaniesAction => ({
  type: ActionType.RECEIVE_COMPANIES,
  companies,
});

export const receiveCompaniesError = (error: Error): ReceiveCompaniesErrorAction => ({
  type: ActionType.RECEIVE_COMPANIES_ERROR,
  error,
});

/**
 * User login
 */

export const userLoginAttempt = (): AnyAction => ({
  type: ActionType.USER_LOGIN_ATTEMPT,
});

export const userLoginAttemptComplete = (): AnyAction => ({
  type: ActionType.USER_LOGIN_ATTEMPT_COMPLETE,
});

export const userLoggedIn = (): AnyAction => ({
  type: ActionType.USER_LOGGED_IN,
});

export const userLoggedOut = (): AnyAction => ({
  type: ActionType.USER_LOGGED_OUT,
});

export const userReset = (): AnyAction => ({
  type: ActionType.RESET_USER,
});
