import { IDataSourcesListRequestQuery, IDataSourceRequest, IDataSourceResponse } from '@serverfarm/pointer-cod-api';
import { deserializeDataSourceParameterType } from '@serverfarm/rs-commons';
import moment from 'moment';
import { Maybe, None, Some } from 'monet';

import { Company } from '../../user';
import { DataSource, DataSourceParameter, dataSourceParameterOptionDeserialize, DataSourcesListFilter, DataSourcesListSort } from '../entities';

export const dataSourceListingFilterSerialize = (
  filter: DataSourcesListFilter = { created: None(), updated: None() },
  page: number,
  sizePerPage: number,
  sort: Maybe<DataSourcesListSort>,
): IDataSourcesListRequestQuery => {
  const dsFilter: IDataSourcesListRequestQuery = {
    from: new Date(0).toISOString(),
    name: filter.name,
    companyIds: filter.companies,
    page: page + 1,
    limit: sizePerPage,
    sort: sort.map((s) => `${s.field}:${s.order}`).orUndefined(),
  };
  if (filter.created && filter.created.isSome()) {
    dsFilter.from = filter.created.map((created) => created.from.toISOString()).orUndefined();
    dsFilter.to = filter.created.map((created) => created.to.toISOString()).orUndefined();
  }
  if (filter.updated && filter.updated.isSome()) {
    dsFilter.fromUpdated = filter.updated.map((updated) => updated.from.toISOString()).orUndefined();
    dsFilter.toUpdated = filter.updated.map((updated) => updated.to.toISOString()).orUndefined();
  }
  return dsFilter;
};

export const dataSourceParameterSerialize = (p: DataSourceParameter): any => ({
  name: p.name,
  type: p.type.toString(),
  isOptional: p.isOptional,
  default: p.default.orUndefined(),
  options: p.options.map((options) => options.map((option) => option.toString())).orUndefined(),
});

export const dataSourceParameterDeserialize = (p: any): DataSourceParameter => ({
  name: p.name,
  type: deserializeDataSourceParameterType(p.type),
  isOptional: p.isOptional,
  default: Maybe.fromUndefined(p.default),
  options: Maybe.fromUndefined(p.options).map((options) => options.map(dataSourceParameterOptionDeserialize)),
});

export const dataSourceSerialize = (ds: DataSource): IDataSourceRequest => ({
  name: ds.name,
  query: ds.query,
  parameters: ds.parameters.map((parameters) => parameters.map(dataSourceParameterSerialize)).orUndefined(),
});

export const dataSourceDeserialize = (dsr: IDataSourceResponse, companies: Maybe<Company[]>): DataSource => {
  const company = companies.flatMap((companies) => Maybe.fromUndefined(companies.find((c) => c.id === dsr.companyId)));
  if (company.isNone()) {
    throw new Error(`DataSource's company id (${dsr.companyId}) is not available to current user`);
  }
  return {
    id: dsr.id,
    name: dsr.name,
    company,
    query: dsr.query,
    parameters: Maybe.fromUndefined(dsr.parameters).map((parameters) => parameters.map(dataSourceParameterDeserialize)),
    updated: Maybe.fromUndefined(dsr.updated).map((updated) => moment(updated).toDate()),
    created: moment(dsr.created).toDate(),
  };
};
