import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export interface TzDropdownProps {
  id: string;
  onTzChange: (tz?: string) => void;
  value?: string;
}

export const TzDropdown = (props: TzDropdownProps): any => {
  const [tz, setTz] = useState(props.value);
  useEffect(() => {
    props.onTzChange(tz);
  }, [tz]);
  return (
    <Typeahead
      multiple={false}
      data-testid={`timezone-dropdown-${props.id}`}
      id={`timezone-dropdown-${props.id}`}
      onChange={(selected) => {
        setTz(selected[0]);
      }}
      options={moment.tz.names()}
      emptyLabel="No Timezone found"
      placeholder="Select a Timezone"
      defaultSelected={tz ? [tz] : []}
    />
  );
};
