import logdown from 'logdown';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default is localStorage
import thunkMiddleware from 'redux-thunk';

import {
  createDataSourcesListingTransform,
  createDataSourceEditingTransform,
  createDataSourceSavingTransform,
  createDataSourceDeletingTransform,
  createDataSourceParameterEditingTransform,
} from './components/datasources';
import {
  createReportsListTransform,
  createReportEditTransform,
  createReportSavingTransform,
  createReportRenderingTransform,
  createReportDeletingTransform,
  createReportDataSourcesListingTransform,
  createReportTemplatesListingTransform,
  renderedReportsHistoryTransform,
  renderedReportViewerTransform,
} from './components/reports';
import {
  createTemplatesListTransform,
  createTemplateSavingTransform,
  createTemplateEditTransform,
  createTemplateDeletingTransform,
} from './components/templates';
import { createUserTransform, companiesListingTransform } from './components/user';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    createUserTransform(),
    companiesListingTransform(),
    createDataSourcesListingTransform(),
    createDataSourceEditingTransform(),
    createDataSourceSavingTransform(),
    createDataSourceDeletingTransform(),
    createDataSourceParameterEditingTransform(),
    createTemplatesListTransform(),
    createTemplateSavingTransform(),
    createTemplateEditTransform(),
    createTemplateDeletingTransform(),
    createReportsListTransform(),
    createReportEditTransform(),
    createReportSavingTransform(),
    createReportRenderingTransform(),
    createReportDeletingTransform(),
    createReportDataSourcesListingTransform(),
    createReportTemplatesListingTransform(),
    renderedReportsHistoryTransform(),
    renderedReportViewerTransform(),
  ],
  blacklist: [
    'reportsListing',
    'reportSaving',
    'reportDeleting',
    'reportDataSourcesListing',
    'reportTemplatesListing',
    'reportRendering',

    'dataSourcesListing',
    'dataSourceSaving',
    'dataSourceDeleting',

    'templatesListing',
    'templateSaving',
    'templateDeleting',

    'renderedReportsHistory',
    'renderedReportViewer',

    'notifications',

    'reportInteractiveRendering',

    'routes',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggerMiddleware = createLogger({
  logger: logdown('redux', { markdown: false }),
});

export const createReduxStore = () => createStore(persistedReducer, applyMiddleware(thunkMiddleware, loggerMiddleware));

const store = createReduxStore();

export default store;
