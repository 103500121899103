import { Dispatch } from 'redux';

// import { State } from '../../../reducers';
// import { dataSourceListingFilterToDataSourceFilter, DataSourcesListingState, fetchDataSources } from '../../datasources';
// import { fetchReports, ReportsListingState } from '../../reports';
// import { reportListFilterSerialize } from '../../reports/data/serdes';
// import { fetchTemplates, templateListingFilterToDataSourceFilter, TemplatesListingState } from '../../templates';
import { navBarToggle, navBarToggleDropDown, navBarUpdateTitle, routesSetActive, sideBarToggleIsOpen } from '../redux/actions';
import { RouteName } from '../routes';

/**
 * Routes related functionality
 */
export const setActiveRoute = (name: RouteName) => (dispatch: Dispatch<any>, getState: any) => {
  dispatch(routesSetActive(name));
  dispatch(navBarUpdateTitle(name));
  // const state: State = getState();
  // if (name === RouteName.DATA_SOURCES) {
  //   const dsFilter = dataSourceListingFilterToDataSourceFilter(state.dataSourcesListing.filter.getOrElse({}));
  //   if (!dsFilter.from && !dsFilter.to && !dsFilter.name && !dsFilter.companyId) {
  //     fetchDataSources()(dispatch, getState);
  //   }
  // }
  // if (name === RouteName.TEMPLATES) {
  //   const templateFilter = templateListingFilterToDataSourceFilter(state.templatesListing.filter);
  //   if (!templateFilter.from && !templateFilter.to && !templateFilter.name && !templateFilter.companyId) {
  //     fetchTemplates()(dispatch, getState);
  //   }
  // }
  // if (name === RouteName.REPORTS) {
  //   const filter = reportListFilterSerialize(state.reportsListing.filter.getOrElse({}));
  //   if (!filter.from && !filter.to && !filter.name && !filter.companyId && !filter.dataSourceName && !filter.templateName) {
  //     fetchReports()(dispatch, getState);
  //   }
  // }
};

/**
 * Navigation bar routes
 */
export const toggleSideBarIsOpen = (isOpen?: boolean) => (dispatch: Dispatch<any>) => {
  dispatch(sideBarToggleIsOpen(isOpen));
};

export const setNavBarTitle = (title: string) => (dispatch: Dispatch<any>) => {
  dispatch(navBarUpdateTitle(title));
};

export const toggleNavBarDropDown = () => (dispatch: Dispatch<any>) => {
  dispatch(navBarToggleDropDown());
};

export const toggleNavBar = (isOpen?: boolean) => (dispatch: Dispatch<any>) => {
  dispatch(navBarToggle(isOpen));
};
