import { Maybe } from 'monet';

import { Action, ErrorAction } from '../../../redux/actions';
import { ReportParameterInitFnType, ReportParameterInitValueType, Report, PermaLink } from '../../entities';

import { ResetReportsAction } from './reports.actions';

export enum ReportRenderingActionType {
  START_REPORT_RENDERING = 'START_REPORT_RENDERING',
  DONE_REPORT_RENDERING = 'DONE_REPORT_RENDERING',
  FAIL_REPORT_RENDERING = 'FAIL_REPORT_RENDERING',

  START_REPORT_DEFERRED_RENDERING = 'START_REPORT_DEFERRED_RENDERING',
  DONE_REPORT_DEFERRED_RENDERING = 'DONE_REPORT_DEFERRED_RENDERING',
  FAIL_REPORT_DEFERRED_RENDERING = 'FAIL_REPORT_DEFERRED_RENDERING',

  START_REPORT_RENDERING_WITH_OPTIONS = 'START_REPORT_RENDERING_WITH_OPTIONS',
  DONE_REPORT_RENDERING_WITH_OPTIONS = 'DONE_REPORT_RENDERING_WITH_OPTIONS',
  FAIL_REPORT_RENDERING_WITH_OPTIONS = 'FAIL_REPORT_RENDERING_WITH_OPTIONS',
  REPORT_RENDERING_WITH_OPTIONS_EDIT_REPORT_PARAMETER_FUNC = 'REPORT_RENDERING_WITH_OPTIONS_EDIT_REPORT_PARAMETER_FUNC',
  REPORT_RENDERING_WITH_OPTIONS_EDIT_REPORT_PARAMETER_VALUE = 'REPORT_RENDERING_WITH_OPTIONS_EDIT_REPORT_PARAMETER_VALUE',
  COMPLETE_REPORT_RENDERING_WITH_OPTIONS = 'COMPLETE_REPORT_RENDERING_WITH_OPTIONS',

  START_REPORT_LIVE_LINK_GENERATION = 'START_REPORT_LIVE_LINK_GENERATION',
  DONE_REPORT_LIVE_LINK_GENERATION = 'DONE_REPORT_LIVE_LINK_GENERATION',
  FAIL_REPORT_LIVE_LINK_GENERATION = 'FAIL_REPORT_LIVE_LINK_GENERATION',
}

/**
 * Actions
 */

/**
 * Report Rendering
 */

interface ReportStartRenderingAction extends Action<ReportRenderingActionType> {
  reportId: string;
}

interface ReportDoneRenderingAction extends Action<ReportRenderingActionType> {
  reportId: string;
  permalinks: PermaLink;
}

type ReportFailRenderingAction = ErrorAction<ReportRenderingActionType>;

export type ReportRenderingAction = (ReportStartRenderingAction & ReportDoneRenderingAction & ReportFailRenderingAction) | ResetReportsAction;

/**
 * Report Deferred Rendering
 */

interface ReportStartDeferredRenderingAction extends Action<ReportRenderingActionType> {
  reportId: string;
  email: string;
}

type ReportDoneDeferredRenderingAction = Action<ReportRenderingActionType>;

type ReportFailDeferredRenderingAction = ErrorAction<ReportRenderingActionType>;

export type ReportDeferredRenderingAction =
  | (ReportStartDeferredRenderingAction & ReportDoneDeferredRenderingAction & ReportFailDeferredRenderingAction)
  | ResetReportsAction;

/**
 * Report Interactive Rendering
 */

interface ReportStartInteractiveRenderingAction extends Action<ReportRenderingActionType> {
  report: Report;
}

interface ReportDoneInteractiveRenderingAction extends Action<ReportRenderingActionType> {
  report: Report;
  permalinks: PermaLink;
}

type ReportFailInteractiveRenderingAction = ErrorAction<ReportRenderingActionType>;

interface ReportInteractiveRenderingParameterEditFuncAction extends Action<ReportRenderingActionType> {
  name: string;
  initFn: Maybe<ReportParameterInitFnType>;
}

interface ReportInteractiveRenderingParameterEditValueAction extends Action<ReportRenderingActionType> {
  name: string;
  initValue: Maybe<ReportParameterInitValueType>;
}

type ReportInteractiveRenderingCompleteAction = Action<ReportRenderingActionType>;

export type ReportInteractiveRenderingAction =
  | (ReportStartInteractiveRenderingAction &
      ReportDoneInteractiveRenderingAction &
      ReportFailInteractiveRenderingAction &
      ReportInteractiveRenderingParameterEditFuncAction &
      ReportInteractiveRenderingParameterEditValueAction &
      ReportInteractiveRenderingCompleteAction)
  | ResetReportsAction;

/**
 * Report Live Link Generation
 */

interface ReportLiveLinkStartGenerationAction extends Action<ReportRenderingActionType> {
  reportId: string;
}

interface ReportLiveLinkDoneGenerationAction extends Action<ReportRenderingActionType> {
  reportId: string;
}

type ReportLiveLinkFailGenerationAction = ErrorAction<ReportRenderingActionType>;

export type ReportLiveLinkGenerationAction =
  | (ReportLiveLinkStartGenerationAction & ReportLiveLinkDoneGenerationAction & ReportLiveLinkFailGenerationAction)
  | ResetReportsAction;

/**
 * Action creators
 */

/**
 * Report rendering
 */

export const reportStartRendering = (reportId: string): ReportStartRenderingAction => ({
  type: ReportRenderingActionType.START_REPORT_RENDERING,
  reportId,
});

export const reportDoneRendering = (reportId: string, permalinks: PermaLink): ReportDoneRenderingAction => ({
  type: ReportRenderingActionType.DONE_REPORT_RENDERING,
  reportId,
  permalinks,
});

export const reportFailRendering = (error: Error): ReportFailRenderingAction => ({
  type: ReportRenderingActionType.FAIL_REPORT_RENDERING,
  error,
});

/**
 * Deferred Rendering
 */

export const reportStartDeferredRendering = (reportId: string, email: string): ReportStartDeferredRenderingAction => ({
  type: ReportRenderingActionType.START_REPORT_DEFERRED_RENDERING,
  reportId,
  email,
});

export const reportDoneDeferredRendering = (): ReportDoneDeferredRenderingAction => ({
  type: ReportRenderingActionType.DONE_REPORT_DEFERRED_RENDERING,
});

export const reportFailDeferredRendering = (error: Error): ReportFailDeferredRenderingAction => ({
  type: ReportRenderingActionType.FAIL_REPORT_DEFERRED_RENDERING,
  error,
});

/**
 * Report Interactive Rendering
 */

export const reportStartInteractiveRendering = (report: Report): ReportStartInteractiveRenderingAction => ({
  type: ReportRenderingActionType.START_REPORT_RENDERING_WITH_OPTIONS,
  report,
});

export const reportDoneInteractiveRendering = (report: Report, permalinks: PermaLink): ReportDoneInteractiveRenderingAction => ({
  type: ReportRenderingActionType.DONE_REPORT_RENDERING_WITH_OPTIONS,
  report,
  permalinks,
});

export const reportFailInteractiveRendering = (error: Error): ReportFailInteractiveRenderingAction => ({
  type: ReportRenderingActionType.FAIL_REPORT_RENDERING_WITH_OPTIONS,
  error,
});

export const reportInteractiveRenderingParameterEditFunc = (
  name: string,
  initFn: Maybe<ReportParameterInitFnType>,
): ReportInteractiveRenderingParameterEditFuncAction => ({
  type: ReportRenderingActionType.REPORT_RENDERING_WITH_OPTIONS_EDIT_REPORT_PARAMETER_FUNC,
  name,
  initFn,
});

export const reportInteractiveRenderingParameterEditValue = (
  name: string,
  initValue: Maybe<ReportParameterInitValueType>,
): ReportInteractiveRenderingParameterEditValueAction => ({
  type: ReportRenderingActionType.REPORT_RENDERING_WITH_OPTIONS_EDIT_REPORT_PARAMETER_VALUE,
  name,
  initValue,
});

export const reportInteractiveRenderingComplete = (): ReportInteractiveRenderingCompleteAction => ({
  type: ReportRenderingActionType.COMPLETE_REPORT_RENDERING_WITH_OPTIONS,
});

/**
 * Report live link generation
 */

export const reportLiveLinkStartGeneration = (reportId: string): ReportLiveLinkStartGenerationAction => ({
  type: ReportRenderingActionType.START_REPORT_LIVE_LINK_GENERATION,
  reportId,
});

export const reportLiveLinkDoneGeneration = (reportId: string): ReportLiveLinkDoneGenerationAction => ({
  type: ReportRenderingActionType.DONE_REPORT_LIVE_LINK_GENERATION,
  reportId,
});

export const reportLiveLinkFailGeneration = (error: Error): ReportLiveLinkFailGenerationAction => ({
  type: ReportRenderingActionType.FAIL_REPORT_LIVE_LINK_GENERATION,
  error,
});
